import { Create, SimpleForm, TextInput, useGetList, SelectInput, ReferenceInput } from "react-admin";
import { useState } from "react";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const SupplierVasCreate = () => {
  const [vasEdit, setVasEdit] = useState();
  const fields = useGetList("models/supplier-vas-pricing")?.data?.[0]?.fields;
  const vasTypeOptions = fields?.vas_type?.enum?.map((item) => ({
    id: item,
    name: item,
  }));

  const packagingTypeOptions = fields?.packaging_type?.enum?.map((item) => ({
    id: item,
    name: item,
  }));

  const packagingMaterialOptions = fields?.packaging_material?.enum?.map((item) => ({
    id: item,
    name: item,
  }));
  const labellingTypeOptions = fields?.labelling_type?.enum?.map((item) => ({
    id: item,
    name: item,
  }));

  const supplier = useGetList("suppliers", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'name', order: 'ASC' }
  })?.data?.map((item) => ({
    id: item.id,
    name: item.name,
  }));


  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Supplier Vas",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">new</p>,
        type: "link",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Create mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          {supplier?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="supplier_id"
              label="Supplier"
              choices={supplier}
              required={true}
            />
          )}

          {vasTypeOptions?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="vas_type"
              label="VAS Type"
              required
              onChange={(e) => setVasEdit(e.target.value)}
              choices={vasTypeOptions}
            />
          )}

          {vasEdit == "packaging" && (
            <>
              <SelectInput
                className="w-[100%] lg:w-[50%]"
                source="packaging_type"
                label="Packaging Type"
                choices={packagingTypeOptions}
              />
              {/* <SelectInput
                className="w-[100%] lg:w-[50%]"
                source="packaging_material"
                label="Packaging Material"
                choices={packagingMaterialOptions}
              /> */}
            </>
          )}

          {vasEdit == "material" && (
            <>
              {packagingMaterialOptions?.length > 0 && (
                <SelectInput
                  className="w-[100%] lg:w-[50%]"
                  source="packaging_material"
                  label="Material Type"
                  choices={packagingMaterialOptions}
                />
              )}
            </>
          )}

          {vasEdit == "labelling" && (
            <>
              {labellingTypeOptions?.length > 0 && (
                <SelectInput
                  className="w-[100%] lg:w-[50%]"
                  source="labelling_type"
                  label="Labelling Type"
                  choices={labellingTypeOptions}
                />
              )}
            </>
          )}

          <TextInput className="w-[100%] lg:w-[50%]" source="rate_per_unit" label="Rate Per Unit" />
        </SimpleForm>
      </Create>
    </div>
  );
};
