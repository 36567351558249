import { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useGetOne,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const PaymentTermsEdit = () => {
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Payment Terms",
        type: "text",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          <TextInput className="w-[100%] lg:w-[50%]" source="name" label="Name" />

          <TextInput className="w-[100%] lg:w-[50%]" source="advance_payment_percent" label="Payment Percent" />
        </SimpleForm>
      </Edit>
    </div>
  );
};
