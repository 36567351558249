import * as React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  EmailField,
  List,
  ShowButton,
  EditButton,
  Pagination,
  useRecordContext,
  ArrayField,
  useGetOne,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useLocation, useSearchParams } from "react-router-dom";

const SupplierProductPricingList = () => {
  const [searchParams] = useSearchParams();

  const BreadcrumbContext = () => {
    const supplierProductPricingListId = JSON.parse(searchParams.get("filter"))?.id;

    const { data: supplierProductPricing } = useGetOne(`supplier-products`, {
      id: supplierProductPricingListId,
    });

    let links = [
      {
        name: "Supplier products",
        type: "text",
      },
      {
        name: `${supplierProductPricing?.supplier?.name}, (${supplierProductPricing?.variant?.name})`,
        type: "text",
      },
      {
        name: "Supplier product pricing",
        type: "text",
      },
      {
        name: "Lists",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <List hasCreate={false} title={<BreadcrumbContext />}>
      <Datagrid bulkActionButtons={false}>
        {/* <ArrayField source="supplier-product.results" label="Variants">
          <Datagrid bulkActionButtons={false}> */}
        <TextField source="inco_terms" label="INCO terms" />
        <TextField source="port.name" label="Port" />
        <TextField source="status" label="Status" />
        <TextField source="rate_per_unit" label="Rate Per Unit" />
        <DateField source="valid_from" label="Valid From" />
        <DateField source="valid_till" label="Valid Till" />
        <EditButton label="Edit" />
        {/* <ShowButton label="Show" /> */}
        {/* </Datagrid>
        </ArrayField> */}
      </Datagrid>
    </List>
  );
};

export default SupplierProductPricingList;
