import React from "react";
import { firestore } from "../login/firebaseConfig";
import { useTime } from "../hooks/getTime";
import ChatCard from "./ChatCard";
import { useGetChatrooms } from "./useChat";

const ChatroomList = ({ userId }) => {
  const { chatrooms } = useGetChatrooms(firestore, {
    qName: "ops.id",
    qValue: userId,
  });

  return (
    <div className="w-full h-full bg-white p-5">
      <div className="w-full space-y-4">
        {chatrooms?.map((chatroom, index) => (
          <ChatCard chatroom={chatroom} key={index} />
        ))}
      </div>
    </div>
  );
};

export default ChatroomList;
