import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  BooleanField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  DateInput,
  DateField,
  useRecordContext,
  CreateButton,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const PaymentTermsList = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Payment Terms",
        type: "text",
      },
      {
        name: "All",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <div className="w-full h-full">
      <List title={<BreadcrumbContext actions={<CreateButton />} />}>
        <Datagrid>
          <TextField source="name" label="Name" sortable={false} />
          <TextField source="advance_payment_percent" label="Payment Percent" sortable={false} />
          <EditButton label="Edit" />
        </Datagrid>
      </List>
    </div>
  );
};

export default PaymentTermsList;
