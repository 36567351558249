import * as React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  EmailField,
  List,
  ShowButton,
  EditButton,
  Pagination,
  useRecordContext,
  ArrayField,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const supplierFilters = [
  <ReferenceInput source="supplier_id" reference="suppliers" alwaysOn>
    <SelectInput className="w-[280px]" label="Supplier Search" />
  </ReferenceInput>,
];

const SupplierVasList = () => {
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Supplier Vas",
        type: "text",
      },
      {
        name: "Lists",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  const VasType = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
      <>
        {record.vas_type == "packaging" ? (
          <>
            <TextField source="packaging_type" label="packaging" />
            <TextField source="packaging_material" label="VAS Type" />
          </>
        ) : (
          <TextField source="labelling_type" label="VAS Type" />
        )}
      </>
    );
  };
  return (
    <List filters={supplierFilters} title={<BreadcrumbContext />}>
      <Datagrid bulkActionButtons={false}>
        {/* <ArrayField source="supplier-product.results" label="Variants">
          <Datagrid bulkActionButtons={false}> */}
        <TextField source="supplier.name" label="Supplier" />
        <TextField source="vas_type" label="VAS Type" />
        <TextField source="packaging_type" label="Packaging Type" />
        <TextField source="packaging_material" label="Packaging Material" />
        <TextField source="labelling_type" label="Labelling Type" />
        <TextField source="rate_per_unit" label="Rate Per Unit" />
        <EditButton label="Edit" />
        <ShowButton label="Show" />
        {/* </Datagrid>
        </ArrayField> */}
      </Datagrid>
    </List>
  );
};

export default SupplierVasList;
