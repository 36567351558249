import { useState } from "react";
import { useGetList } from "react-admin";
import { getBase64 } from "../../api/imageToBase64";
import fetch_with_auth from "../../utils/fetch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import ClipLoader from "react-spinners/ClipLoader";


const InvoiceDocumentPopup = ({ documentDetails, pop, notify, refresh, invoiceDocumentModalClose }) => {
  const lotsModel = useGetList("models/lots").data?.[0]?.fields;
  const [loading, setLoading] = useState(false);
  let document_types = lotsModel?.schedule?.fields?.documents?.fields?.document_type?.enum.map((type, index) => ({
    id: index,
    name: type,
  }));

  const [type, setType] = useState("");
  const [file, setFile] = useState();

  const handleSubmit = async (e) => {
    if (type && file) {
      const fileObj = await getBase64(file);

      if (!fileObj) {
        return;
      }
      const fileBuffer = {
        content_type: file.type,
        filename: file.name,
        data: { $binary: fileObj?.split(";base64,")[1], $type: "00" },
      };

      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/invoices/${documentDetails?.lot_id}/schedule/${documentDetails?.schedule_id}/documents`;
        const response = await fetch_with_auth(url, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            document_type: type,
            attachments: fileBuffer,
          }),
        });
        if (response.ok) {
          //   refresh();
          notify(`Submitted successfully`, { type: "success" });
          invoiceDocumentModalClose();
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      notify("Select all fields", { type: "error" });
    }
  };

  return (
    <Dialog
      open={pop}
      onClose={invoiceDocumentModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="relative"
    >
      {loading ? (
        <div className="absolute flex justify-center items-center  inset-0 w-full h-full">
          <ClipLoader color="black" size={150} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        ""
      )}
      <DialogContent className="w-[500px] space-y-6">
        <select
          onChange={(e) => setType(e.target.value)}
          className="w-full border border-gray-300 px-2 py-1"
          placeholder="Document Type"
        >
          <option>Select</option>
          {document_types?.map((ele, index) => (
            <option selected={ele.name == type} key={index} value={ele?.name}>
              {ele?.name}
            </option>
          ))}
        </select>
        <input type="file" placeholder="Enter File" onChange={(e) => setFile(e.target.files[0])} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InvoiceDocumentPopup;
