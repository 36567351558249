import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  DateInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Button,
  ReferenceInput,
  //SelectInput,
  useGetList,
  BooleanInput,
  SelectArrayInput,
  required,
  useRecordContext,
  useGetOne,
  useRefresh,
  useNotify,
  FunctionField,
  ArrayField,
  Datagrid,
} from "react-admin";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import SelectInput from "./SelectInput";
import ClipLoader from "react-spinners/ClipLoader";
import fetch_with_auth from "../../utils/fetch";

let inco_terms = [
  { id: "FOB", name: "FOB" },
  { id: "CIF", name: "CIF" },
  { id: "CFR", name: "CFR" },
];

const RowField = ({ element, index, formData, spinning }) => {
  const PORT_MAPPER = item => { return { "id": item.id, "name": item.name + "-" + item.country } }

  const [formValues, setFormValues] = formData;
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(element?.total_price);
  const [supplierProducts, setSupplierProducts] = useState([]);
  const { data: suppliers } = useGetList("suppliers", { pagination: { perPage: 200 }, sort: {field: "name", order: "asc"} });
  const { data: payment_terms } = useGetList("payment-terms");
  const { data: ports_swell } = useGetList("ports")
  const ports = ports_swell?.map(PORT_MAPPER)
  const { data: cfr_ports_swell } = useGetList("ports", {
    filter: { url: "country[$ne]=IN" },
  })
  const cfr_ports = cfr_ports_swell?.map(PORT_MAPPER)
  const refresh = useRefresh();
  const notify = useNotify();

  const [vas, setVas] = useState({
    packaging: [],
    material: [],
    labelling: [],
  });

  useEffect(() => {
    if (element && element.supplier_id) {
      async function fetchSupplierProducts() {
        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/supplier-products/results/${element.supplier_id}`
        );
        const { data } = await response.json();
        setSupplierProducts(data?.results?.length > 0 ? data?.results : []);
      }
      fetchSupplierProducts();
    }
  }, [element.supplier_id]);

  const variants_to_options = supplierProducts.map((x) => {
    return {
      id: x?.variant_id,
      name: `${x.variant?.parent?.name} (${x?.variant?.name})`,
    };
  });

  useEffect(() => {
    let newFormValues = {};
    if (element.variant_id !== "") {
      let supplierProductsByVariant = supplierProducts?.find((ele) => ele.variant_id === element.variant_id);

      let packaging = supplierProductsByVariant?.available_vas?.results
        ?.filter((x) => x.vas_type === "packaging")
        .map((ele) => {
          return {
            id: ele?.id,
            rate_per_unit: ele.rate_per_unit,
            name: ele?.packaging_type,
          };
        });

      let material = supplierProductsByVariant?.available_vas?.results
        ?.filter((x) => x.vas_type === "material")
        .map((ele) => {
          return {
            id: ele?.id,
            rate_per_unit: ele.rate_per_unit,
            name: ele?.packaging_material,
          };
        });

      let labelling = supplierProductsByVariant?.available_vas?.results
        ?.filter((x) => x.vas_type === "labelling")
        .map((ele) => {
          return {
            id: ele?.id,
            rate_per_unit: ele.rate_per_unit,
            name: ele?.labelling_type,
          };
        });

      setVas({
        ...vas,
        ["packaging"]: packaging,
        ["material"]: material,
        ["labelling"]: labelling,
      });

      newFormValues = [...formValues];
      newFormValues[index]["product_id"] = supplierProductsByVariant?.variant?.parent?.id;

      newFormValues[index]["margin"] = supplierProductsByVariant?.margin_in_percent;
      newFormValues[index]["pricing_unit"] = supplierProductsByVariant?.variant?.parent?.pricing_unit;
      newFormValues[index]["currency"] = supplierProductsByVariant?.variant?.parent?.currency;
      newFormValues[index]["prices"] = supplierProductsByVariant?.prices?.results;

      if (element.port_id != "") {
        let matching_fob_price = supplierProductsByVariant?.prices?.results?.find((x) => x.port_id == element.port_id && x.status == "active");
        newFormValues[index]["price"] = matching_fob_price?.rate_per_unit;
        newFormValues[index]["applied_supplier_product_pricing_id"] = matching_fob_price?.id;
      }

      setFormValues(newFormValues);
    }
  }, [element.variant_id]);

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleChange = (i, e) => {
    
    if (e.target.name == "price") {
      console.log("handle change ", e.target.value)
      setTotalPrice(parseFloat(e.target.value));
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = parseFloat(e.target.value);
      setFormValues(newFormValues);
    } else {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }
  };
  
  const saveBagItem = async () => {
    var disabled = element.buyer_id == "" || element.supplier_id == ""
              || element.inco_terms == "" || element.port_id == "" || element.packaging == ""
              || element.labelling == "" || element.material == "" || element.margin == "" || element.payment_terms_id == ""
      || element.quantity == ""
    if (disabled) { window.alert("Mandatory field(s) empty");  return }
    
    if (element.buyer_id !== "") {
      let allVas = [];

      const packaging = vas.packaging?.find((ele) => ele.id === element.packaging);
      const material = vas.material?.find((ele) => ele.id === element.material);

      const labelling = vas.labelling?.find((ele) => ele.id === element.labelling);

      allVas.push(
        packaging !== undefined
          ? {
              vas_name: packaging.name,
              vas_price: packaging?.rate_per_unit,
              vas_type: "packaging",
            }
          : {}
      );
      allVas.push(
        material !== undefined
          ? {
              vas_name: material.name,
              vas_price: material?.rate_per_unit,
              vas_type: "material",
            }
          : {}
      );
      allVas.push(
        labelling !== undefined
          ? {
              vas_name: labelling.name,
              vas_price: labelling?.rate_per_unit,
              vas_type: "labelling",
            }
          : {}
      );

      try {
        setLoading(true);

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            applied_vas: allVas,
            buyer_id: element.buyer_id,
            applied_supplier_product_pricing_id: element.applied_supplier_product_pricing_id,
            quantity: parseFloat(element.quantity),
            inco_terms: element.inco_terms,
            dest_port_id: element.destination_port_id,
            port_id: element.port_id,
            rate: totalPrice,
            payment_terms_id: element.payment_terms_id,
          }),
        };

        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/checkout/cart_items`,
          requestOptions
        );
        const { data: bag_item } = await response.json();
        if (bag_item && bag_item.id) {
          let newFormValues = [...formValues];
          newFormValues[index]["bag_item_id"] = bag_item?.id;
          setFormValues(newFormValues);
        }

        if (response.ok) {
          notify("Saved Successfully", { type: "success" });
          refresh();
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const available_fobs = (element) => {
    let prices = element.prices?.filter((p) => p.status == "active");
    let fobs = prices
      ?.map((p) => {
        return p.port?.name + ":" + p.rate_per_unit;
      })
      .join("\n");
    return fobs;
  };

  const deleteBagItem = async (index, bag_item_id) => {
    try {
      setLoading(true);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      const response = await fetch_with_auth(
        `${process.env.REACT_APP_ONWO_BACKEND_URL}/checkout/${bag_item_id}`,
        requestOptions
      );
      if (response.ok) {
        let newFormValues = [...formValues];
        newFormValues.splice(index, 1);
        setFormValues(newFormValues);

        setLoading(false);
        notify("Deleted Successfully", { type: "success" });
        refresh();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const packaging = vas.packaging?.find((ele) => ele.id === element.packaging);
    const material = vas.material?.find((ele) => ele.id === element.material);
    const labelling = vas.labelling?.find((ele) => ele.id === element.labelling);

    const p = element.packaging ? packaging?.rate_per_unit : 0;
    const m = element.material ? material?.rate_per_unit : 0;
    const l = element.labelling ? labelling?.rate_per_unit : 0;

    let total_vas = p + m + l;

    let newFormValues = [...formValues];
    if (element.port_id != "") {
      let supplierProductsByVariant = supplierProducts?.find((ele) => ele.variant_id === element.variant_id);
      let matching_fob_price = supplierProductsByVariant?.prices?.results?.find((x) => x.port_id == element.port_id && x.status == "active");
      newFormValues[index]["price"] = matching_fob_price?.rate_per_unit;
      newFormValues[index]["applied_supplier_product_pricing_id"] = matching_fob_price?.id;

      let freight_rate = element.freight_rate ? element.freight_rate : 0;
      let price = matching_fob_price?.rate_per_unit;
      let margin = element.margin ? element.margin : 0;

      let total_price =
        element.inco_terms === "FOB"
          ? (parseFloat(price) + total_vas) * (1 + parseFloat(margin) / 100)
          : (parseFloat(price) + total_vas) * (1 + parseFloat(margin) / 100) + parseFloat(freight_rate);
      total_price = Math.round(total_price * 1000) / 1000
      newFormValues[index]["total_price"] = total_price;
      setTotalPrice(total_price);
    }
    
    setFormValues(newFormValues);
  }, [element.packaging, element.material, element.labelling, element.freight_rate, element.margin, element.port_id]);

  return (
    <div className="w-full h-16 flex flex-row items-center space-x-6" key={index}>
      {loading ? (
        <div className="absolute flex justify-center items-center  inset-0 w-screen h-screen">
          <ClipLoader color="black" size={150} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <>
          <div className="">
            <SelectInput
              value={element.supplier_id}
              label="Supplier"
              name="supplier_id"
              choices={suppliers}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div className="">
            <SelectInput
              value={element.variant_id}
              label="Variant"
              className="w-[100%] lg:w-[200px]"
              name="variant_id"
              choices={variants_to_options}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div className="">
            <SelectInput
              value={element.inco_terms}
              className="w-[100%] lg:w-[150px]"
              label="Inco Terms"
              name="inco_terms"
              choices={inco_terms}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div className="text-sm">Available: {available_fobs(element)}</div>
          <div className="">
            <SelectInput
              value={element.port_id}
              className="w-[100%] lg:w-[150px]"
              name="port_id"
              label="Source Port"
              choices={ports}
              validate={required()}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className="">
            <SelectInput
              value={element.destination_port_id}
              className="w-[100%] lg:w-[150px]"
              name="destination_port_id"
              label="Destination Port"
              choices={cfr_ports}
              disabled={!["CFR","CIF"].includes(element.inco_terms)}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className="">
            <SelectInput
              value={element.packaging}
              label="Packaging"
              className="w-[100%] lg:w-[150px]"
              name="packaging"
              choices={vas.packaging}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div className="">
            <SelectInput
              value={element.material}
              label="Material"
              name="material"
              className="w-[100%] lg:w-[150px]"
              choices={vas.material}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div className="">
            <SelectInput
              value={element.labelling}
              label="Labeling"
              className="w-[100%] lg:w-[150px]"
              name="labelling"
              choices={vas.labelling}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div>
            <TextField
              label="Freight Rate"
              disabled={!["CFR","CIF"].includes(element.inco_terms)}
              variant="filled"
              className="w-[100%] lg:w-[150px]"
              name="freight_rate"
              type="number"
              value={element?.freight_rate}
              onChange={(e) => handleChange(index, e)}
            />
          </div>

          <div>
            <TextField
              label="Margin Percentage"
              variant="filled"
              className="w-[100%] lg:w-[250px]"
              name="margin"
              type="number"
              value={element?.margin}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
        </>
      )}

      <div className="">
        <SelectInput
          value={element.payment_terms_id}
          className="w-[100%] lg:w-[250px]"
          name="payment_terms_id"
          label="Payment Terms"
          choices={payment_terms}
          validate={required()}
          onChange={(e) => handleChange(index, e)}
        />
      </div>

      <div className="flex flex-col space-y-1">
        <TextField
          label={`Qty ${element?.pricing_unit ? element?.pricing_unit?.toUpperCase() : ""}`}
          variant="filled"
          name="quantity"
          type="number"
          className="w-[100%] lg:w-[150px]"
          value={element?.quantity}
          validate={required()}
          onChange={(e) => handleChange(index, e)}
        />
      </div>

      <div className="flex flex-col space-y-1">
        <TextField
          label={`Price ${element?.currency ? element?.currency : ""}`}
          className="w-[100%] lg:w-[150px]"
          name="price"
          type="number"
          value={totalPrice}
          onChange={(e) => handleChange(index, e)}
        />
      </div>

      <div className="flex items-center space-x-6 mt-2">
        {element && element.buyer_id && element.bag_item_id === "" && (
          <button
            type="button"
            className={`${element.buyer_id === " " ? "bg-gray-400" : "bg-blue-500"}   text-white rounded px-4 py-1`}
            onClick={() => saveBagItem(index)}
          >
            Save
          </button>
        )}

        {element && element.buyer_id && element.bag_item_id !== "" && (
          <button
            type="button"
            className="bg-red-500 text-white rounded px-4 py-1"
            onClick={() => deleteBagItem(index, element.bag_item_id)}
          >
            Delete
          </button>
        )}

        {index ? (
          <button
            type="button"
            className="bg-red-500 text-white rounded px-4 py-1"
            onClick={() => removeFormFields(index)}
          >
            Remove
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default RowField;
