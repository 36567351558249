import * as React from "react";
import {
  Datagrid,
  TextField,
  EmailField,
  List,
  CreateButton,
  ShowButton,
  EditButton,
  Pagination,
  useRecordContext,
  ArrayField,
  TextInput,
  ReferenceInput,
  SelectInput,
  DeleteButton,
  FunctionField,
  WrapperField,
  NumberField,
  ReferenceManyField,
  useRedirect,
  BooleanField,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const filters = [
  <ReferenceInput source="supplier_id" reference="suppliers" sort={{ field: 'name', order: 'ASC'}} alwaysOn>
    <SelectInput className="w-[280px]" label="Supplier Search" />
  </ReferenceInput>,
];

const AddPriceButton = () => {
  const record = useRecordContext();
  if (!record) return null;
  const redirect = useRedirect();
  const handleClick = () => {
    redirect("create", "supplier-product-pricing", undefined, undefined, {
      supplier_products_id: record && record.id,
    });
  };
  return (
    <button onClick={handleClick} className="drop-shadow-md bg-cyan-600 m-4 px-4 py-1 text-white text-xxs">
      Add /Edit Price
    </button>
  );
};

const SupplierProductList = () => {
  const vas_value = (r) => {
    switch (r.vas_type) {
      case "packaging":
        return "packaging: " + r.packaging_type;
      case "material":
        return "material: " + r.packaging_material;
      case "labelling":
        return "label:" + r.labelling_type;
      default:
        return "";
    }
  };

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Supplier Products",
        type: "text",
      },
      {
        name: "Lists",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <List filters={filters} title={<BreadcrumbContext />} actions={<CreateButton />}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          className="col-span-2"
          label="Product >> Variant"
          render={(record) => `${record.variant?.parent?.name} >> ${record.variant?.name}`}
        />
        <ArrayField source="available_vas" label="Available VAS">
          <Datagrid bulkActionButtons={false} className="col-span-2">
            <FunctionField render={(r) => vas_value(r)} label="VAS" />
            <TextField source="rate_per_unit" label="USD/Unit" />
          </Datagrid>
        </ArrayField>
        <ReferenceManyField label="Listings" reference="supplier-product-pricing" target="supplier_products_id" filter={{status:"active"}}>
          <Datagrid bulkActionButtons={false} className="col-span-2 border-2">
            <NumberField source="rate_per_unit" label="USD" />
            <TextField source="port.name" label="FOB Port" />
          </Datagrid>
        </ReferenceManyField>

        <TextField label="Default Payment Terms" source="payment_terms.name" className="col-span-1" />
        <NumberField label="ONWO Margin %" source="margin_in_percent" className="align-top col-span-1" />
        <WrapperField label="Actions" className="col-span-1">
          <ul>
            <li>
              <AddPriceButton />
            </li>
            <li>
              <EditButton label="Edit" />
            </li>
            <li>
              <ShowButton label="Show" className="text-xxs" />
            </li>
            <li>
              <DeleteButton label="Delete" />
            </li>
          </ul>
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default SupplierProductList;
