import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useRecordContext,
  ReferenceInput,
  useGetRecordId,
  useGetOne,
  SelectArrayInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useEffect, useState } from "react";
import fetch_with_auth from "../utils/fetch";

export const SupplierProductEdit = () => {
  const recordId = useGetRecordId();
  const record = useGetOne("supplier-products", {
    id: recordId,
  })?.data;

  const suppStatus = useGetList("models/supplier-products")?.data?.[0]?.fields?.status?.enum?.map((item) => ({
    id: item,
    name: item,
  }));

  const SupplierVasMultiSelect = () => {
    const record = useRecordContext();
    if (!record) return null;
    const getName = (item) => {
      if (item.vas_type === "packaging") return "packaging : " + item.packaging_type + " (" + item.rate_per_unit + "$)";
      if (item.vas_type === "labelling") return "labelling : " + item.labelling_type + " (" + item.rate_per_unit + "$)";
      if (item.vas_type === "material") return "Material: " + item.packaging_material + " (" + item.rate_per_unit + "$)";
    };
    const vas = useGetList(`supplier-vas-pricing`, {
      filter: { supplier_id: record?.supplier_id },
    })?.data?.map((item) => ({ id: item.id, name: getName(item) }));

    return <SelectArrayInput source="available_vas_ids" choices={vas} />;
  };

  const supplier = useGetList("suppliers", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'name', order: 'ASC' }
  })?.data?.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const [optionChoice, setOptionChoice] = useState([]);

  const onItemChangeHandler = function (value) {
    if (value) {
      fetch_with_auth(`${process.env.REACT_APP_ONWO_BACKEND_URL}/products/${value}`)
        .then((res) => res.json())
        .then((json) => {
          setOptionChoice([
            ...optionChoice,
            {
              id: value,
              options: json?.data?.variants?.results?.map((item) => ({
                id: item?.id,
                name: item?.name,
              })),
            },
          ]);
        });
      setItemSelected(value);
    }
  };

  const [itemSelected, setItemSelected] = useState("");

  useEffect(() => {
    onItemChangeHandler(record?.variant?.parent_id);
  }, []);

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Supplier Products",
        type: "text",
      },
      {
        name: `${record.supplier.name}, (${record.variant.name})`,
        type: "text",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  const getOptionsArray = (id) => {
    if (id === "") return [];
    return optionChoice.find((ele) => ele?.id === id)?.options;
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          {suppStatus?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="status"
              label="Supplier Product Status"
              choices={suppStatus}
            />
          )}

          {supplier?.length > 0 && (
            <SelectInput className="w-[100%] lg:w-[50%]" source="supplier_id" label="Supplier" choices={supplier} />
          )}

          <ReferenceInput source="payment_terms_id" reference="payment-terms" perPage={1000} label="Payment Terms">
            <SelectInput className="w-[100%] lg:w-[50%]" />
          </ReferenceInput>

          <ReferenceInput source="variant.parent_id" reference="products" perPage={1000} label="product">
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              label="product"
              onChange={(e) => onItemChangeHandler(e.target.value)}
            />
          </ReferenceInput>

          <SelectInput
            className="w-[100%] lg:w-[50%]"
            source="variant_id"
            label="Variant"
            choices={getOptionsArray(itemSelected)?.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })}
          />

          <ArrayInput label="Days commitment" source="days_commitment">
            <SimpleFormIterator inline>
              <NumberInput source="days_to_production" helperText={false} required />
              <NumberInput source="days_to_packaging" helperText={false} required />
              <NumberInput source="days_to_reach_port" helperText={false} required />
              <NumberInput source="slack_days" helperText={false} required />
            </SimpleFormIterator>
          </ArrayInput>

          {/* 
          <TextInput
            className="w-[100%] lg:w-[50%]"
            source="rate_per_unit"
            label="Rate Per unit"
          /> */}
          <TextInput className="w-[100%] lg:w-[50%]" source="margin_in_percent" label="Margin in Percent" />
          <TextInput className="w-[100%] lg:w-[50%]" source="price_validity_in_days" label="Price Validity in Days" />
          <SupplierVasMultiSelect />
        </SimpleForm>
      </Edit>
    </div>
  );
};
