import React from "react";
import { useTime } from "../hooks/getTime";

const ChatRight = ({ message }) => {
  const { timeFormatter } = useTime();
  return (
    <div className="flex justify-end mb-2">
      <div className="rounded-lg p-2 space-y-2 bg-[#CCE6DE]">
        <p className="text-sm text-teal font-bold">You</p>
        <p className="text-sm text-[#244D4D]">{message.msg}</p>
        <p className="text-right text-xs text-grey-dark">{timeFormatter(message.createdAt)}</p>
      </div>
    </div>
  );
};

export default ChatRight;
