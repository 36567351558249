import {
  Create,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useRecordContext,
  DateInput,
  useGetOne,
  ReferenceInput,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  required,
  Button,
  useNotify,
  useRefresh,
  useRedirect,
  Loading,
  ListContextProvider,
  List,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useLocation } from "react-router-dom";
import { useTime } from "../hooks/getTime";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import moment from "moment";

export const SupplierProductPricingCreate = () => {
  const ports = useGetList("ports", {
    filter: { query: "from_port" },
  })?.data?.map((item) => ({
    id: item?.id,
    name: item.name + "-" + item.country,
  }));

  const {
    state: { supplier_products_id },
  } = useLocation();

  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();

  const onSuccess = (data) => {
    notify(`Prices created. Redirecting to the price view...`);
    navigate({
      pathname: "/supplier-product-pricing",
      search: `filter=${JSON.stringify({id: data?.supplier_products_id})}`})
    refresh()
  };

  const ExistingPrices = () => {
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(1);
    const perPage = 10;
    const sort = { field: "id", order: "ASC" };

    var as_date = (field) => { return moment(field).format("YYYY-MM-DD") }
    const { data, total, isLoading, error } = useGetList("supplier-product-pricing", {
      filter: { supplier_products_id: supplier_products_id, status: "active" },
    });
    if (isLoading) {
      return <Loading />;
    }
    if (error) {
      return <p>ERROR</p>;
    }
    
    return (
      <div className="bg-white m-2 p-2">
        <span className="text-l text-bold mb-4">Existing FOB Rates (USD)</span>
          {data.map(d => 
            (
            <div className="text-slate-600">{d.port.name} - {d.rate_per_unit}, Validity: {as_date(d.valid_from)} - {as_date(d.valid_till)}</div>
            )
          )}
         
        
      </div>
    );
  };

  const BreadcrumbContext = () => {
    const { shortDateTimeFormatter } = useTime();
    const { data: supplierProducts } = useGetOne(`supplier-products`, {
      id: supplier_products_id,
    });
    let links = [
      {
        name: "Supplier Products",
        type: "text",
      },
      {
        name: `${supplierProducts?.supplier?.name}, (${supplierProducts?.variant?.name})`,
        type: "text",
      },
      {
        name: "Supplier Products Pricing",
        type: "text",
      },
      {
        name: "new",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      {<ExistingPrices />}

      <Create mutationMode="pessimistic" mutationOptions={{ onSuccess }} title={<BreadcrumbContext />}>
        <SimpleForm>
          <TextInput
            className="w-[100%] lg:w-[50%]"
            source="supplier_products_id"
            label="Supplier Product Id"
            defaultValue={supplier_products_id}
            disabled={supplier_products_id != undefined}
          />
          <ArrayInput source="prices" validate={required()}>
            <SimpleFormIterator inline addButton={<Button sx={{ fontSize: "0.8rem" }}> Add Price </Button>}>
              <SelectInput source="port_id" label="Port" choices={ports} required />
              <TextInput source="rate_per_unit" label="Rate Per unit" required />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Create>
    </div>
  );
};
