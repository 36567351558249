import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  EditButton,
  BooleanField,
  TextInput,
  ReferenceField,
  useRecordContext,
  DateField,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const orderFilters = [
  <TextInput className="w-[280px]" source="number" label="Search By Order Number" alwaysOn />,
  <ReferenceInput source="supplier_id" reference="suppliers" alwaysOn>
    <SelectInput className="w-[280px]" label="Supplier Search" />
  </ReferenceInput>,
];

const OrderList = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Buyer Orders",
        type: "text",
      },

      {
        name: "All",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <List filters={orderFilters} title={<BreadcrumbContext />}>
      <Datagrid bulkActionButtons={false}>
        <BooleanField source="content.test" label="Test Order" />
        <DateField source="date_created" label="Created" />
        <TextField source="number" label="order number" />
        <TextField source="grand_total" />
        <ReferenceField label="Buyer" source="account_id" reference="buyers" link="show" sortable={false} />
        <ShowButton label="View" />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
