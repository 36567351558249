import {
  Create,
  NumberInput,
  SelectInput,
  TextInput,
  SimpleForm,
  DateInput,
  BooleanInput,
  useGetList,
  required,
  useRecordContext,
  ReferenceInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
export const CallbackCreate = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Callback Request",
        type: "text",
      },
      {
        name: "Create",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <div className="w-full">
      <Create title={<BreadcrumbContext />}>
        <SimpleForm>
          <TextInput className="w-[100%] lg:w-[50%]" source="name" />
          <TextInput className="w-[100%] lg:w-[50%]" source="slot" />
          <TextInput className="w-[100%] lg:w-[50%]" source="email" validate={required()} />
          <NumberInput className="w-[100%] lg:w-[50%]" source="phone" />
          <TextInput className="w-[100%] lg:w-[50%]" source="country" />
          {/* <ReferenceInput source="country" reference="countries" perPage={1000} >
                    <SelectInput />
                 </ReferenceInput> */}
          <DateInput className="w-[100%] lg:w-[50%]" source="date_created" defaultValue={new Date()} />
          <ReferenceInput source="expert" reference="product-experts">
            <SelectInput className="w-[100%] lg:w-[50%]" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </div>
  );
};
