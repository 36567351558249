import { useCallback, useEffect, useState } from "react";
import { Edit, SimpleForm, TextInput, Create } from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const PaymentTermsCreate = () => {
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Payment Terms",
        type: "text",
      },
      {
        name: "Create",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Create title={<BreadcrumbContext />}>
        <SimpleForm>
          <TextInput className="w-[100%] lg:w-[50%]" source="name" label="Name" />

          <TextInput className="w-[100%] lg:w-[50%]" source="advance_payment_percent" label="Payment Percent" />
        </SimpleForm>
      </Create>
    </div>
  );
};
