import * as React from "react";
import {
  Datagrid,
  TextField,
  EmailField,
  List,
  EditButton,
  ShowButton,
  ReferenceField,
  DateField,
  useRecordContext,
  Loading,
  useGetList,
  SelectInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
const CallbackReqList = () => {

  let option_mapper = (item) => ({
    id: item,
    name: item,
  });

  const { data, total, isLoading, error } = useGetList("models/callback");

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const status = data?.[0]?.fields?.status.enum.map(option_mapper);
  
  const filters = [
    <SelectInput label="Status" choices={status} source="status" alwaysOn />
  ];


  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Callback-Requests",
        type: "text",
      },

      {
        name: "Lists",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <List title={<BreadcrumbContext />} filters={filters}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="name" sortable={false} />
        <TextField source="status" sortable={false} />
        <TextField source="slots" sortable={false} />
        <EmailField source="email" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="country" sortable={false} />
        <DateField source="date_created" showTime sortable={false} />
        <TextField source="expert" sortable={false} />
        {/* <ReferenceField source="expert" reference="product-experts" sortable={false}/> */}
      </Datagrid>
    </List>
  );
};

export default CallbackReqList;
