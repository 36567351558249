import {
  BooleanInput,
  Edit,
  FileInput,
  FileField,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  Button,
  ImageInput,
  ImageField,
  useGetList,
  SelectInput,
  useRecordContext,
  DateInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const SupplierEdit = () => {
  const supplierFields = useGetList("models/suppliers").data?.[0]?.fields;

  //

  const certification_names = supplierFields?.content?.fields?.certifications?.fields?.name?.enum || [];

  const certification_names_map = certification_names.map((certification_name) => ({
    id: certification_name,
    name: certification_name,
  }));

  const BreadcrumbContext = () => {
    const record = useRecordContext();

    let links = [
      {
        name: "Suppliers",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.name}</p>,
        type: "link",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
        <div className="grid grid-cols-3 gap-4 w-full">
            <BooleanInput className="" source="is_registration_complete" label="Registration Complete?" />
            <BooleanInput className="" source="content.is_approved" label="Approved" />
              <BooleanInput className="" source="use_physical_signature" label="Use Physical Signature" />
        </div>
          <div className="grid grid-cols-3 gap-4 w-full">
          <TextInput className="" source="gst" label="GST" />
          <TextInput className="" source="ie_code" label="IE Code" />
          <TextInput className="" source="igst_ref" label="IGST Ref:" />
          <TextInput className="" source="pan" label="PAN" />
          <TextInput className="" source="fssai_license" label="FSSAI License" />
          <TextInput className="" source="lut_arn" label="LUT ARN" />
          <DateInput className="" source="lut_arn_date" label="LUT ARN Date" />
          <TextInput className="" source="spice_board_registration_no" label="Spice Board Registration" />
            <TextInput className="" source="spice_board_certificate_no" label="Spice Board Certification" />
          </div>
          <div className="grid grid-cols-2 gap-4 w-full" >
            <span className="col-span-2">Billing</span>
            <div className="grid grid-cols-2 gap-4 w-full">
              <TextInput className="" source="billing.name" label="Name" />
              <TextInput className="" source="billing.address1" label="Address1" />
              <TextInput className="" source="billing.address2" label="Address2" />
              <TextInput className="" source="billing.city" label="City" />
              <TextInput className="" source="billing.state" label="State" />
              <TextInput className="" source="billing.zip" label="Zip" />
              <TextInput className="" source="billing.country" label="Country" helperText="2 character code" />
            </div>
            <div>
              <span className="col-span-2">Shipping</span>
              <div className="grid grid-cols-2 gap-4 w-full">
              <TextInput className="" source="shipping.name" label="Name" />
              <TextInput className="" source="shipping.address1" label="Address1" />
              <TextInput className="" source="shipping.address2" label="Address2" />
              <TextInput className="" source="shipping.city" label="City" />
              <TextInput className="" source="shipping.state" label="State" />
              <TextInput className="" source="shipping.zip" label="Zip" />
              <TextInput className="" source="shipping.country" label="Country" helperText="2 character code" />
              </div>
            </div>
            </div>
          <ArrayInput className="" source="bank_accounts">
            <SimpleFormIterator
              inline
              className="w-[100%] lg:w-[50%]"
              disableRemove
              disableReordering
              addButton={<Button sx={{ fontSize: "0.8rem" }}>Add bank account</Button>}
            >
              <TextInput className="w-[100%] lg:w-[50%]" source="bank_name" />
              <TextInput className="w-[100%] lg:w-[50%]" source="account_number" />
              <TextInput className="w-[100%] lg:w-[50%]" source="account_name" />
              <TextInput className="w-[100%] lg:w-[50%]" source="ifsc" />
              <TextInput className="w-[100%] lg:w-[50%]" source="address" multiline={true} />
              <TextInput className="w-[100%] lg:w-[50%]" source="swift" />

              <FileInput source="bank_proof" className="border border-gray-400 rounded p-2">
                <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput className="w-[100%] lg:w-[50%]" source="factories">
            <SimpleFormIterator
              inline
              className="w-[100%] lg:w-[50%]"
              disableRemove
              disableReordering
              addButton={<Button sx={{ fontSize: "0.8rem" }}>Add Factory</Button>}
            >
              <TextInput className="w-[100%] lg:w-[50%]" source="name" />
              <TextInput className="w-[100%] lg:w-[50%]" source="address_line_1" multiline={true} />
              <TextInput className="w-[100%] lg:w-[50%]" source="city" />
              <TextInput className="w-[100%] lg:w-[50%]" source="pincode" />
              <TextInput className="w-[100%] lg:w-[50%]" source="country" />
              <TextInput className="w-[100%] lg:w-[50%]" source="fssai_id" />

              <FileInput source="fssai_document" className="border border-gray-400 rounded p-2">
                <FileField className="w-[100%] lg:w-[50%]" source="src" title="title" target="_blank" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>

          {/* <ArrayInput
            className="w-[100%] lg:w-[50%]"
            source="content.certifications"
          >
            <SimpleFormIterator
              inline
              disableRemove
              disableReordering
              className="w-[100%] lg:w-[50%]"
              addButton={
                <Button sx={{ fontSize: "0.8rem" }}>Add Certification</Button>
              }
            >
              <SelectInput
                className="w-[100%] lg:w-[50%]"
                source="name"
                choices={certification_names_map}
                defaultValue=""
              />
              <FileInput source="documents" label="Files" multiple>
                <FileField
                  className="w-[100%] lg:w-[50%]"
                  source="src"
                  title="title"
                  target="_blank"
                />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput> */}

          <div className="grid grid-cols-2 gap-4 my-8">
            <FileInput
              source="content.proof_of_business"
              label="Proof of Business"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="content.letter_of_authorization"
              label="Letter of Authorization"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="content.brn_certificate"
              label="VAT/GST/TIN/BRN Certificate"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="content.business_registration_certificate"
              label="Business Registration Certificate"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="vendor_gst_document"
              label="Vendor GST Document"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput source="vendor_pan_card" label="Vendor PAN Card" className="border border-gray-400 rounded p-2">
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <ImageInput source="logo" label="Logo" className="border border-gray-400 rounded p-2">
              <ImageField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </ImageInput>
            <ImageInput source="signature" label="Signature" className="border border-gray-400 rounded p-2">
              <ImageField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </ImageInput>
          </div>
        </SimpleForm>
      </Edit>
    </div>
  );
};
