import {
  ArrayInput,
  Button,
  DateInput,
  Edit,
  FileField,
  FileInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { useLocation } from "react-router-dom";

export const ItemEdit = () => {
  const {
    state: { orderId },
  } = useLocation();

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = (data) => {
    notify(`Changes saved for item ${data.product_name}`);
    redirect("show", "items", data.id, {}, { orderId: orderId });
    refresh();
  };

  return (
    <div className="w-full">
      <Edit
        mutationMode="pessimistic"
        queryOptions={{ meta: { orderId: orderId } }}
        mutationOptions={{ meta: { orderId: orderId }, onSuccess }}
      >
        <SimpleForm>
          <ArrayInput source="actions">
            <SimpleFormIterator inline bulkActionButtons={false} disableReordering disableRemove>
              <TextInput source="name" disabled />
              <DateInput source="date" />
              <DateInput source="start_date" />
              <DateInput source="end_date" />
              <ArrayInput source="documents">
                <SimpleFormIterator
                  inline
                  disableRemove
                  disableReordering
                  addButton={<Button sx={{ fontSize: "0.8rem" }}>+ Add Documents</Button>}
                >
                  <TextInput label="Document Name" source="name" />
                  <TextInput label="Document Number" source="number" />
                  <FileInput source="asset" label="File">
                    <FileField source="src" title="title" target="_blank" />
                  </FileInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </div>
  );
};
