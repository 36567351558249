import { useState } from "react";
import { useGetList, useNotify, useRecordContext, useRefresh } from "react-admin";
import fetch_with_auth from "../../utils/fetch";
import moment from "moment";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";

const TrackModal = ({ track, trackModalClose,trackLinkId,itemDocumentModalOpen,setLoading }) => {
  const [schedule, setSchedule] = useState();
  const refresh = useRefresh();
  const notify = useNotify();
  const [openTrack, setOpenTrack] = track;

  const bagItemModel = useGetList("models/bag-item").data?.at(0)?.fields;

  let itemScheduleStatus = bagItemModel?.schedule?.fields?.status?.enum?.map((type, index) => ({
    id: index,
    name: type,
  }));

  const context = useRecordContext();

  const item = context.items?.find((x) => x?.metadata.bag_item?.id === trackLinkId);
  let schedules = item?.metadata?.bag_item?.schedule;
  let bag_item_id = item?.metadata?.bag_item?.id;

  const onHandleChange = (value, name) => {
    setSchedule({ ...schedule, [name]: value });
  };

  const save = async (payload) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/bag-items/${bag_item_id}`;

      const response = await fetch_with_auth(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...schedule, stage: payload.stage }),
      });

      if (response.ok) {
        refresh();
        notify(`Saved successfully`, { type: "success" });
        // trackModalClose();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: 1200,
        },
      }}
      open={openTrack}
      onClose={trackModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TableContainer className="w-[100%] lg:w-[50%] p-4" component={Paper}>
        <p className="font-bold text-black underline">Manage Status </p>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <span className="font-semibold">Stage</span>
              </TableCell>
              <TableCell>
                <span className="font-semibold">Started on</span>
              </TableCell>
              <TableCell>
                <span className="font-semibold">Completed on</span>
              </TableCell>
              <TableCell>
                <span className="font-semibold">Expected on</span>
              </TableCell>
              <TableCell>
                <span className="font-semibold">Status</span>
              </TableCell>
              <TableCell>
                <span className="font-semibold">Document</span>
              </TableCell>
              <TableCell>
                <span className="font-semibold"></span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules?.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell className="capitalize">{item.stage.replaceAll("_", " ")}</TableCell>

                  <TableCell>
                    <input
                      type="date"
                      defaultValue={item?.started_on !== undefined ? moment(item?.started_on).format("YYYY-MM-DD") : ""}
                      //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                      onChange={(e) => onHandleChange(e.target.value, "started_on")}
                    />
                  </TableCell>

                  <TableCell>
                    <input
                      type="date"
                      defaultValue={
                        item?.completed_on !== undefined ? moment(item?.completed_on).format("YYYY-MM-DD") : ""
                      }
                      onChange={(e) => onHandleChange(e.target.value, "completed_on")}
                    />
                  </TableCell>

                  <TableCell>
                    <input
                      type="date"
                      defaultValue={
                        item?.expected_on !== undefined ? moment(item?.expected_on).format("YYYY-MM-DD") : ""
                      }
                      onChange={(e) => onHandleChange(e.target.value, "expected_on")}
                    />
                  </TableCell>

                  <TableCell>
                    <select
                      onChange={(e) => onHandleChange(e.target.value, "status")}
                      className="w-[110px] border border-gray-300 p-1"
                    >
                      {itemScheduleStatus?.map((ele, index) => (
                        <option selected={ele.name == item?.status} key={index} value={ele.name}>
                          {ele?.name}
                        </option>
                      ))}
                    </select>
                  </TableCell>

                  <TableCell>
                    <button
                      className="text-blue-500 w-full"
                      onClick={() =>
                        itemDocumentModalOpen({
                          bag_item_id: bag_item_id,
                          schedule_id: item?.id,
                        })
                      }
                    >
                      Add
                    </button>
                  </TableCell>

                  <TableCell>
                    <button
                      className="border bg-blue-500 active:bg-blue-700 rounded text-white py-1 px-2"
                      onClick={() =>
                        save({
                          stage: item.stage,
                          schedule_id: item?.id,
                        })
                      }
                    >
                      Save
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};
export default TrackModal;
