import Button from "@mui/material/Button";
import React from "react";
import { useState } from "react";
import { useGetList, useNotify, useRefresh } from "react-admin";
import fetch_with_auth from "../../utils/fetch";
import moment from "moment";

const CustomsInvoiceEdit = ({ id, lot, proforma = false }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [data, setData] = useState({ inco_terms: lot?.inco_terms });
  const onHandleChange = (value, name, type) => {
    var val = value
    if (type == "number") {
      val = parseFloat(value)
    } else if (type == "date") {
      val = moment(value).toJSON()
    }
    setData({ ...data, [name]: val });
  };
  const fields = [
    {
      title: "port_of_discharge",
      defaultValue: lot?.port_of_discharge || "",
      type: "text",
    },
    {
      title: "port_of_loading",
      defaultValue: lot?.port_of_loading || "",
      type: "text",
    },
    {
      title: "shipping_bill_no",
      defaultValue: lot?.shipping_bill_no || "",
      type: "text",
      hidden: proforma,
    },
    {
      title: "insurance_no",
      defaultValue: lot?.insurance_no || "",
      type: "text",
      hidden: proforma,
    },
    {
      title: "bl_no",
      defaultValue: lot?.bl_no || "",
      type: "text",
      hidden: proforma,
    },
    {
      title: "container_nos",
      defaultValue: lot?.container_nos || "",
      type: "text",
      hidden: proforma,
    },
    {
      title: "bl_date",
      defaultValue: lot?.bl_date ? new Date(lot.bl_date).toISOString().slice(0, 10) : "",
      type: "date",
      hidden: proforma,
    },
    {
      title: "terms_of_delivery",
      defaultValue: lot?.terms_of_delivery || "",
      type: "text",
      hidden: proforma,
    },
    {
      title: "vessel",
      defaultValue: lot?.vessel || "",
      type: "text",
      hidden: proforma,
    },
    {
      title: "country_of_origin_of_goods",
      defaultValue: lot?.country_of_origin_of_goods || "",
      type: "text",
    },
    {
      title: "country_of_final_destination",
      defaultValue: lot?.country_of_final_destination || "",
      type: "text",
    },
    {
      title: "shipping_bill_date",
      defaultValue: lot?.shipping_bill_date ? new Date(lot.shipping_bill_date).toISOString().slice(0, 10) : "",
      type: "date",
      hidden: proforma,
    },
    {
      title: "gross_weight_tonnes",
      defaultValue: lot?.gross_weight_tonnes,
      type: "number",
      hidden: proforma,
    },
    {
      title: "net_weight_tonnes",
      defaultValue: lot?.net_weight_tonnes,
      type: "number",
      hidden: proforma,
    },
    {
      title: "number_of_packages",
      defaultValue: lot?.number_of_packages,
      type: "number",
      hidden: proforma,
    },
    {
      title: "packaging_details",
      defaultValue: lot?.packaging_details,
      type: "textarea",
      hidden: proforma,
    },
    
    {
      title: "fob_value",
      defaultValue: lot?.fob_value,
      type: "number",
      hidden: proforma,
    },
    {
      title: "insurance_value",
      defaultValue: lot?.insurance_value,
      type: "number",
      hidden: proforma,
    },
    {
      title: "freight_value",
      defaultValue: lot?.freight_value,
      type: "number",
      hidden: proforma,
    },
    {
      title: "cif_value",
      defaultValue: lot?.cif_value,
      type: "number",
      hidden: proforma
    }
  ];
  const saveLot = async () => {
    try {
      const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/lots/${lot.id}`;

      const response = await fetch_with_auth(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...data }),
      });
      if (response.ok) {
        notify(`Saved successfully`, { type: "success" });
        refresh();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      console.log(error);
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
    } finally {
      //   setLoading(false);
    }
  };
  return (
    <div className="mt-4 border border-gray-500 rounded p-5">
      <p className="capitalize font-bold mb-2">Lot Details</p>
      <div className="grid gap-x-5 grid-cols-3">
        {fields?.filter((i) => !i.hidden).map((i) => (
            <div className="p-2" key={i.title}>
            <label className="pb-1 capitalize font-light text-xs">{i.title?.replaceAll("_", " ")}</label>
            {i.type == "textarea" ? (<>
              <textarea name={i.title}
                rows="3"
                cols="20"
                className="block border p-1 m-0 text-sm"
                onChange={(e) => {
                  onHandleChange(e.target.value, i.title, i.type);
                }}
              >{i.defaultValue}</textarea>
            
            
              </>) : 
              (<input
                name={i.title}
                type={i.type}
                {...i}
                className="block border p-1 m-0 text-sm"
                onChange={(e) => {
                  onHandleChange(e.target.value, i.title, i.type);
                }}
              />)}
            </div>
          ))}
      </div>
      <div className="flex justify-end">
        <Button variant="contained" onClick={saveLot}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CustomsInvoiceEdit;
