var c = ["REACT_APP_FIREBASE_CONFIG", "REACT_APP_ONWO_BACKEND_URL"];

const check = () => {
  c.forEach((i) => {
    if (!process.env[i]) {
      throw new Error("Environment variable " + i + " is not set. Exiting");
    } else {
      console.log("Found " + i);
    }
  });
};

module.exports = { check };
