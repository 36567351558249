import { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useGetOne,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const CallbackEdit = () => {
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Callback",
        type: "text",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          <SelectInput
            className="w-[100%] lg:w-[50%]"
            source="status"
            label="status"
            choices={[
              { id: "new", name: "New" },
              { id: "called", name: "Called" },
              { id: "unreachable", name: "Not Reachable" },
            ]}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};
