import { Typography } from "@material-ui/core";
import printJS from "print-js"

import {
  Button,
  DateField,
  Labeled,
  Loading,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetOne,
  useRecordContext,
} from "react-admin";



export const SampleShow = () => {
  const Aside = () => {
    let record = useRecordContext();
    if (record == null) return null;

    const { data, isLoading, error } = useGetOne("products", { id: record.product_id });
    if (isLoading) {
      return <Loading />;
    }
    if (error) {
      return <p>ERROR</p>;
    }
    let ignore = ["vertical", "pieces_per_carton", "unit", "default"];
    const props = Object.keys(data.attributes)
      .filter((x) => ignore.indexOf(x) == -1 && data.attributes[x])
      .map((r) => r + " : " + data.attributes[r])
      .slice(0, 6);

    return (
      <div style={{ width: 300, margin: "2px" }}>
        <div id="aside" className="grid grid-cols-4 bg-white p-4 content-start">
          <div className="col-span-1 align-middle">
            <img src="./onwo_logo.png" />
          </div>
          <div className="col-span-3 text-right text-xs mb-8 text-slate-500 align-middle">
            Onwo Trade India Private Limited
          </div>

          <div className="text-slate-500 text-m col-span-4">Commercial Product Sample</div>
          <div className="text-m text-slate-700">{data.name}</div>
          <div className="text-sm underline text-slate-500 pt-2 col-span-4">Properties:</div>
          <div className="text-sm text-slate-700 col-span-4">
            {props.map((p) => (
              <div>{p}</div>
            ))}
          </div>
          <div className="mt-5 flex items-start col-span-4">
            <div className="border-grey-500 border-4 col-span-2">
              <img src="https://api.qrserver.com/v1/create-qr-code/?data=HelloWorld&amp;size=100x100" />
            </div>
            <div className="ml-4 col-span-2 text-left">
              <div className="text-m text-slate-500 text-sm mb-2">Contact Us</div>
              <div className="text-sm">hello@onwo.trade</div>
              <div className="text-sm">+91 96115 63752</div>
              <div className="text-sm">www.onwo.trade</div>
            </div>
          </div>
        </div>
        <div className="text-black flex justify-start lg:justify-end mt-5">
          <Button
            // disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={() => {
              printJS({
                printable: 'aside',
                type: 'html',
                targetStyles: ['*'],
                header: ''
             })

              // var divContents = document.getElementById("aside").innerHTML;
              // var a = window.open("", "", "height=500, width=700");
              // a.document.write("<html>");
              // a.document.write('<head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">')
              // a.document.write(`<script src="https://cdn.tailwindcss.com"></script></head>`)
              // a.document.write("<body m-2>");
              // a.document.write(divContents);
              // a.document.write("</body></html>");
              // a.document.close();
              // a.print();
            }}
            sx={{
              padding: "6px 8px 4px 8px",
              fontSize: { xs: 14, md: 14 },
            }}
          >
            Print Label
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Show aside={<Aside />}>
      <SimpleShowLayout>
        <div className="grid grid-cols-3 gap-4">
          <Labeled label="ID">
            <TextField source="id" label="ID" />
          </Labeled>
          <ReferenceField source="product_id" reference="products">
            <Labeled label="Product">
              <TextField source="name" label="Product" />
            </Labeled>
          </ReferenceField>
          <ReferenceField source="variant_id" reference="products:variants" />

          <Labeled label="Send To">
            <TextField source="buyer_address" label="Send To" />
          </Labeled>
          <Labeled label="Packet Size">
            <TextField source="packet_size" label="packet_size" />
          </Labeled>
          <Labeled label="#samples">
            <NumberField source="count" label="#samples" />
          </Labeled>
          <Labeled label="Notes">
            <TextField source="internal_notes" label="Notes" />
          </Labeled>

          <Labeled label="Requested Bu">
            <TextField source="requested_by" label="Requested By" />
          </Labeled>
          <Labeled label="Sourced By">
            <TextField source="sourcing_owner" label="Sourced By" />
          </Labeled>
          <Labeled label="Created">
            <DateField source="date_created" label="Created" />
          </Labeled>
          <Labeled label="State">
            <TextField source="state" label="Status" />
          </Labeled>
          <Labeled label="AWB">
            <TextField source="awb" label="AWB" />
          </Labeled>
          <Labeled label="Dispatched On">
            <DateField source="dispatched_on" label="Dispatched On" />
          </Labeled>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
