import { withStyles } from "@material-ui/core/styles";
import * as React from "react";
import {
  Button,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  BooleanInput,
  FileInput,
  ReferenceInput,
  SelectInput,
  useGetList,
  FileField,
  required,
  SelectArrayInput,
  useNotify,
  useRefresh,
  useRedirect,
  useRecordContext,
} from "react-admin";
import { useLocation } from "react-router-dom";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const styles = {
  label: {
    fontSize: "29px",
  },
  textField: {
    fontSize: 50, //??? Doesnt work
  },
  resize: {
    fontSize: 50,
  },
};

const OrderEditComp = ({ classes }) => {
  const ordersFields = useGetList("models/orders").data?.[0].fields;
  const productFields = useGetList("models/products").data?.[0].fields;

  const statuses = ordersFields?.order_schedule?.fields?.status?.enum || [];
  const sub_statuses = ordersFields?.order_schedule?.fields?.sub_status?.enum || [];
  const activity_statuses = ordersFields?.order_schedule?.fields?.activity_status?.enum || [];
  const payment_types = ordersFields?.payment_schedule?.fields?.type?.enum || [];
  const document_names = ordersFields?.documents?.fields?.type?.enum || [];
  const statuses_notify_on = ordersFields?.observers?.fields?.notify_on?.fields?.status?.enum || [];

  const pricing_unit = productFields?.pricing_unit?.enum || [];

  const status_map = statuses.map((status) => ({ id: status, name: status }));
  const sub_status_map = sub_statuses.map((sub_status) => ({
    id: sub_status,
    name: sub_status,
  }));
  const activity_status_map = activity_statuses.map((activity_status) => ({
    id: activity_status,
    name: activity_status,
  }));
  const payment_types_map = payment_types.map((payment_type) => ({
    id: payment_type,
    name: payment_type,
  }));
  const document_names_map = document_names.map((document_name) => ({
    id: document_name,
    name: document_name,
  }));
  const statuses_notify_on_map = statuses_notify_on.map((status_notify_on) => ({
    id: status_notify_on,
    name: status_notify_on,
  }));

  const pricing_unit_map = pricing_unit.map((x) => ({ id: x, name: x }));

  const {
    state: { record },
  } = useLocation();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const onSuccess = (data) => {
    notify(`Changes saved for order number ${data.number}`);
    redirect("show", "orders", data.id);
    refresh();
  };

  const BreadcrumbContext = () => {
    const record = useRecordContext();

    let links = [
      {
        name: "Orders",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.number}</p>,
        type: "link",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  const flag = record?.changeOrderStatus === true || false;
  if (flag) {
    return (
      <div className="w-[81vw] lg:w-full">
        <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} title={<BreadcrumbContext />}>
          <SimpleForm>
            <TextInput className="w-[100%] lg:w-[50%]" disabled label="Order number" source="number" />
            <DateInput
              className="w-[100%] lg:w-[50%]"
              label="Completed Date"
              validate={required()}
              source="status_change.completed_on"
            />
            {record?.statusChangeButton === "Payment Received" && (
              <FileInput
                label="Payment Receipt"
                validate={required()}
                source="status_change.payment_receipt.file"
                className="border border-gray-400 rounded p-2"
              >
                <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
              </FileInput>
            )}
            {record?.statusChangeButton === "Surrender BL" && (
              <BooleanInput
                className="w-[100%] lg:w-[50%]"
                defaultValue={true}
                disabled
                source="status_change.is_bl_surrendered"
                sx={{ visibility: "hidden" }}
              />
            )}
            {record?.statusChangeButton === "Pre Shipment QC" && (
              <BooleanInput
                className="w-[100%] lg:w-[50%]"
                defaultValue={true}
                disabled
                source="status_change.is_pre_shipment_qc_done"
                sx={{ visibility: "hidden" }}
              />
            )}
            {record?.statusChangeButton === "BL Generated" && (
              <FileInput
                label="Bill of Lading"
                validate={required()}
                defaultValue
                source="status_change.bl_doc.file"
                className="border border-gray-400 rounded p-2"
              >
                <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
              </FileInput>
            )}
            {record?.statusChangeButton === "PO Received" && (
              <FileInput
                label="Purchase Order"
                validate={required()}
                source="status_change.po.file"
                className="border border-gray-400 rounded p-2"
              >
                <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
              </FileInput>
            )}

            {(record?.statusChangeButton === "Quality Checked" || record?.statusChangeButton === "Pre Shipment QC") && (
              <FileInput
                label="Inspection Documents"
                multiple
                validate={required()}
                source="status_change.inspection.files"
                className="border border-gray-400 rounded p-2"
              >
                <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
              </FileInput>
            )}

            <TextInput
              className="w-[100%] lg:w-[50%]"
              hidden
              disabled
              defaultValue={record.nextStatus}
              source="status_change.next_status"
              sx={{ visibility: "hidden" }}
            />
            <TextInput
              className="w-[100%] lg:w-[50%]"
              hidden
              disabled
              defaultValue={record.nextSubStatus}
              source="status_change.next_substatus"
              sx={{ visibility: "hidden" }}
            />
          </SimpleForm>
        </Edit>
      </div>
    );
  } else {
    return (
      <Edit mutationMode="pessimistic" mutationOptions={{ onSuccess }} title={<BreadcrumbContext />}>
        <BreadcrumbContext />
        <SimpleForm>
          <TextInput disabled label="Order number" source="number" />
          <ArrayInput className="w-[100%] lg:w-[50%]" source="items">
            <SimpleFormIterator
              inline
              disableRemove
              disableReordering
              addButton={<Button sx={{ fontSize: "0.8rem" }}>+ Add Items</Button>}
            >
              <ReferenceInput source="product_id" reference="products" perPage={1000}>
                <SelectInput />
              </ReferenceInput>
              <TextInput source="pricing_unit" disabled />
              <NumberInput source="quantity" />
              <NumberInput source="price" label="Price in USD" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="documents">
            <SimpleFormIterator
              inline
              disableRemove
              disableReordering
              addButton={<Button sx={{ fontSize: "0.8rem" }}>+ Add Documents</Button>}
            >
              <SelectInput source="type" choices={document_names_map} defaultValue="" className="w-[100%] lg:w-[50%]" />
              <FileInput source="asset" label="Files" multiple className="border border-gray-400 rounded p-2">
                <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
          {/* <ArrayInput source="observers">
            <SimpleFormIterator
              inline
              disableRemove
              disableReordering
              addButton={<Button sx={{ fontSize: "0.8rem" }}>+ Add Observers</Button>}
            >
              <TextInput source="name" />
              <NumberInput source="whatsapp" />
              <TextInput source="email" />
              <ArrayInput source="notify_on">
                <SimpleFormIterator
                  inline
                  disableRemove
                  disableReordering
                  addButton={<Button sx={{ fontSize: "0.8rem" }}>Add notify_on</Button>}
                >
                  <SelectArrayInput
                    className="w-[100%] lg:w-[50%]"
                    source="status"
                    choices={statuses_notify_on_map}
                    defaultValue=""
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput> */}
        </SimpleForm>
      </Edit>
    );
  }
};

export const OrderEdit = withStyles(styles)(OrderEditComp);
