import React from "react";
import { useRecordContext, useNotify, useRefresh } from "react-admin";
import fetch_with_auth from "../../utils/fetch";

const TabSelection = ({ children, tab, setTab, data: x }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const context = useRecordContext();
  let inco_terms = x?.inco_terms;
  let port_name = x?.port_name;
  let port_id = x?.port_id;
  let dest_port = x?.dest_port_name
  let dest_port_id = x?.dest_port_id
  let tabTypes = x?.tabTypes;

  const createInvoice = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items_ids: [],
          supplier_order_id: context?.id,
          buyer_order_id: context?.parent_order_id,
          inco_terms: inco_terms,
          port_id: port_id,
          dest_port_id: dest_port_id
        }),
      };

      const response = await fetch_with_auth(`${process.env.REACT_APP_ONWO_BACKEND_URL}/lots`, requestOptions);
      const data = await response.json();
    } catch (error) {
      notify(error, { type: "error" });
    } finally {
      notify(`Invoice created successfully`, { type: "success" });
      refresh();
    }
  };

  const finalizeInvoice = async () => {
    var ids = x?.customs_invoices?.map(i => i.id)
    var id = ids.splice(0, 1)[0]
    const all_ids = ids.map (id => `id=${id}`).join("&");

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    try {
      const response = await fetch_with_auth(`${process.env.REACT_APP_ONWO_BACKEND_URL}/lots/${id}/finalize?${all_ids}`, requestOptions);
      const data = await response.json();
    } catch (error) {
      notify(error, { type: "error" });
    } finally {
      notify(`Invoices finalized successfully`, { type: "success" });
      refresh();
    }
  };
  
  return (
    <div className="border border-gray-300 p-2 rounded">
      <div className="flex items-center justify-between p-2">
        <div className="flex space-x-2">
          <p className="font-bold text-black">{inco_terms}</p>
          <p className="font-bold text-black">{port_name}</p>
          <p className="font-bold text-black"> {">"} {dest_port}</p>
        </div>
        <div className="space-x-4">
          <button
            disabled={!context?.customs_invoices?.some((ele) => ele.status === "draft")}
            onClick={createInvoice}
            className={`${!context?.customs_invoices?.some((ele) => ele.status === "draft") ? "bg-gray-300" : "bg-blue-400"
            }  text-white px-3 py-1 rounded`}
          >
            New Customs Invoice
          </button>
          <button
            disabled={!context?.customs_invoices?.some((ele) => ele.status === "draft")}
            onClick={finalizeInvoice}
            className={`${!context?.customs_invoices?.some((ele) => ele.status === "draft") ? "bg-gray-300" : "bg-blue-400"
            }  text-white px-3 py-1 rounded`}
          >
            Finalize All Customs Invoices
          </button>
        </div>
      </div>
      <div className="flex rounded p-2 space-x-4 divide-black">
        {tabTypes?.map((x, index) => {
          let tabSelect = x.includes(tab[port_name]);

          return (
            <button
              key={index}
              type="button"
              onClick={() => {
                setTab({ ...tab, [port_name]: x });
                refresh();
                localStorage.setItem("supplierOrder", JSON.stringify({ ...tab, [port_name]: x }));
              }}
              className={`${tabSelect ? "bg-gray-200 text-black" : "bg-white text-gray-600"} rounded py-1 px-2`}
            >
              {x}
            </button>
          );
        })}
      </div>
      <div className="mt-5">{children}</div>
    </div>
  );
};

export default TabSelection;
