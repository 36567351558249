import {
  ArrayField,
  BooleanField,
  useGetOne,
  Datagrid,
  ReferenceField,
  DateField,
  EmailField,
  FileField,
  ImageField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { LabelWrapper, LabelArrWrapper } from "../component/Wrapper";
import SupplierInviteButton from "../button/SupplierInviteButton";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const RequestForQuoteShow = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Request For Price",
        type: "text",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <Show title={<BreadcrumbContext />}>
      <SimpleShowLayout>
        <div className="space-y-6">
          <div className="grid grid-cols-3 gap-4">
            <LabelWrapper source="buyer_id" label="Buyer">
              <ReferenceField source="buyer_id" reference="buyers" label="Buyer" />
            </LabelWrapper>

            <LabelWrapper source="supplier_id" label="Supplier Name">
              <ReferenceField source="supplier_id" reference="suppliers" label="Supplier Name" />
            </LabelWrapper>

            <LabelWrapper source="product" label="Product Name">
              <TextField source="product" label="Product Name" />
            </LabelWrapper>

            <LabelWrapper source="inco_terms" label="Inco Terms">
              <TextField source="inco_terms" label="Inco Terms" />
            </LabelWrapper>

            <LabelWrapper source="port_id" label="Port">
              <ReferenceField source="port_id" reference="ports" label="Port" />
            </LabelWrapper>

            <LabelWrapper source="variant" label="Variant Name">
              <TextField source="variant" label="Variant Name" />
            </LabelWrapper>

            <LabelWrapper source="negotiation_details.status" label="Status">
              <TextField source="negotiation_details.status" label="Status" />
            </LabelWrapper>

            <LabelWrapper source="negotiation_details.original_price" label="Original price">
              <TextField source="negotiation_details.original_price" label="Original price" />
            </LabelWrapper>
            <LabelWrapper source="applied_supplier_product_pricing.rate_per_unit" label="Supplier price">
              <TextField source="applied_supplier_product_pricing.rate_per_unit" label="Supplier price" />
            </LabelWrapper>

            <LabelWrapper source="negotiation_details.target_price" label="Target price">
              <TextField source="negotiation_details.target_price" label="Target price" />
            </LabelWrapper>

            <LabelWrapper source="date_created" label="Date Created">
              <DateField source="date_created" showTime />
            </LabelWrapper>

            <LabelWrapper source="date_updated" label="Date Updated">
              <DateField source="date_updated" showTime />
            </LabelWrapper>
          </div>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
