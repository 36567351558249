import fetch_with_auth from "../../utils/fetch";

export const finalizeInvoice = async (id, more_ids, notify, refresh) => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
  };
  try {
    const response = await fetch_with_auth(
      `${process.env.REACT_APP_ONWO_BACKEND_URL}/lots/${id}/finalize?${more_ids}`,
      requestOptions
    );
    const data = await response.json();
  } catch (error) {
    notify(error, { type: "error" });
  } finally {
    notify(`Invoices finalized successfully`, { type: "success" });
    refresh();
  }
};
