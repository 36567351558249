import { useState } from "react";
import fetch_with_auth from "../../utils/fetch";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from "@material-ui/core";
import moment from "moment";
import CustomsInvoiceEdit from "./CustomsInvoiceEdit";

const ProformaDatagrid = ({
  pi,
  customs_invoices,
  order_id,
  setLoading,
  lotDocumentModalOpen,
  trackModalOpen,
  setTrackLinkId,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [payment, setPayment] = useState({});

  const onHandleChange = (value, name) => {
    setPayment({ ...payment, [name]: value });
  };
  const save = async (payload) => {
    if (payment?.is_received === true) {
      if (payment && !!payment?.amount_paid) {
        try {
          setLoading(true);
          const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...payment }),
          };

          const response = await fetch_with_auth(
            `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${order_id}/proforma_invoices/${payload?.proforma_id}/payments/${payload?.payment_id}`,
            requestOptions
          );
          if (response.ok) {
            setLoading(false);
            notify("Saved Successfully", { type: "success" });
            refresh();
          } else {
            throw new Error(
              JSON.stringify({
                code: response.status,
                message: response.statusText,
              })
            );
          }
        } catch (error) {
          notify(`Backend error. ${JSON.stringify(error.message)}`, {
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      } else {
        notify("Enter the amount paid", { type: "error" });
      }
    } else {
      try {
        setLoading(true);
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...payment }),
        };

        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${order_id}/proforma_invoices/${payload?.proforma_id}/payments/${payload?.payment_id}`,
          requestOptions
        );
        if (response.ok) {
          refresh();

          notify("Saved Successfully", { type: "success" });
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const doc_url = pi?.documents?.length > 0 ? pi?.documents?.[0]?.asset?.[0]?.url : ""; //need to work

  const [selectedInvoice, setSelectedInvoice] = useState();

  const handleChange = async (e, bag_item_id) => {
    let previousSelected = selectedInvoice;
    let newSelected = e.target.value;

    try {
      setLoading(true);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          from_lot_id: previousSelected,
          to_lot_id: newSelected,
        }),
      };
      const response = await fetch_with_auth(
        `${process.env.REACT_APP_ONWO_BACKEND_URL}/lots/${bag_item_id}/move`,
        requestOptions
      );
      if (response.ok) {
        setLoading(false);
        refresh();
        notify(`Invoice moved successfully`, { type: "success" });
        setSelectedInvoice(newSelected);
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedInvoice(customs_invoices?.[0]?.id);
  }, [customs_invoices]);

  const createProformaPayment = async (invoice_id) => {
    try {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      const response = await fetch_with_auth(
        `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${order_id}/proforma_invoices/${invoice_id}/payments`,
        requestOptions
      );

      if (response.ok) {
        setLoading(false);
        notify("Saved Successfully", { type: "success" });
        refresh();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <TableContainer className="w-[100%] lg:w-[50%] mb-4 p-6" component={Paper}>
        <div className="flex justify-end items-center">
          <a href={doc_url} target="_blank" className="text-blue-500">
            Download Invoice
          </a>
        </div>

        <div className="space-y-3 my-2">
          <div className="my-4">
            <div className="mt-4 border border-gray-500 rounded py-2">
              <p className="font-bold text-black ml-4">Item</p>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="font-semibold">Name</span>
                    </TableCell>
                    {/* <TableCell>
                        <span className="font-semibold">Custom Invoice</span>
                      </TableCell> */}
                    <TableCell>
                      <span className="font-semibold">Quantity</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Price</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Payment Terms</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Status</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Variant Details</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Track trace</span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {pi?.items?.map((item, index) => {
                  return (
                    <TableBody key={index}>
                      <TableRow
                        key={pi.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>
                          {item?.applied_supplier_product_pricing?.supplier_products?.variant?.parent?.name}
                        </TableCell>
                        {/* <TableCell>
                            <select
                              value={selectedInvoice?.id}
                              onChange={(e) => handleChange(e, item.id)}
                              className="w-[90px] border border-gray-300 p-1"
                            >
                              {customs_invoices?.map((ele) => {
                                return (
                                  <option selected={ele?.id === selectedInvoice?.id} key={ele?.id} value={ele?.id}>
                                    {ele?.invoice_number}
                                  </option>
                                );
                              })}
                            </select>
                          </TableCell> */}

                        <TableCell>
                          <span className="capitalize">
                            {item?.quantity}{" "}
                            {item?.applied_supplier_product_pricing?.supplier_products?.variant?.parent?.pricing_unit}
                          </span>
                        </TableCell>
                        <TableCell>
                          {item?.applied_supplier_product_pricing?.supplier_products?.variant?.currency}{" "}
                          {item?.final_price}
                        </TableCell>
                        <TableCell>{item?.final_payment_terms?.name}</TableCell>
                        <TableCell>{item?.schedule?.find((x) => x?.status === "not_started")?.status}</TableCell>

                        <TableCell>
                          <div className="">
                            {item?.applied_supplier_product_pricing?.supplier_products?.variant?.name}
                          </div>
                        </TableCell>

                        <TableCell>
                          <button
                            className="text-blue-500 w-full"
                            onClick={(e) => {
                              trackModalOpen(true);
                              setTrackLinkId(item.id);
                            }}
                          >
                            Edit
                          </button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </div>
            <CustomsInvoiceEdit id={order_id} lot={pi} proforma={true} />
            <div className="mt-4 border border-gray-500 rounded py-2">
              <div className="flex justify-between px-4">
                <p className="font-bold text-black">Payment Schedule</p>
                <button
                  disabled={pi?.is_supplier_order_accepted}
                  className={`${
                    pi?.is_supplier_order_accepted ? "bg-gray-300" : "bg-blue-500 active:bg-blue-700"
                  } border rounded text-white py-1 px-2`}
                  onClick={() => createProformaPayment(pi.id)}
                >
                  Create Payment
                </button>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="font-semibold">Amount Due</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Due by</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Is Due</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Is Customer Paid</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Date Paid</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Is Received</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Amount Received</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Date Received</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Receipt</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Evidence</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold"></span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {pi?.payment_schedule?.map((schedule, index) => {
                  return (
                    <TableBody key={index}>
                      <TableRow
                        key={pi.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>
                          <input
                            type="number"
                            defaultValue={schedule?.amount}
                            className="focus:outline-none border border-gray-200 p-1 w-[100px]"
                            onChange={(e) => onHandleChange(e.target.value, "amount")}
                          />
                        </TableCell>

                        <TableCell>
                          <input
                            type="date"
                            defaultValue={
                              schedule?.due_by !== undefined ? moment(schedule?.due_by).format("YYYY-MM-DD") : false
                            }
                            //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                            onChange={(e) => onHandleChange(e.target.value, "due_by")}
                          />
                        </TableCell>

                        <TableCell>
                          <Checkbox
                            {...label}
                            defaultChecked={schedule?.is_payable}
                            onChange={(e) => onHandleChange(e.target.checked, "is_payable")}
                          />
                        </TableCell>

                        <TableCell>
                          <Checkbox
                            {...label}
                            defaultChecked={schedule?.is_customer_paid}
                            onChange={(e) => onHandleChange(e.target.checked, "is_customer_paid")}
                          />
                        </TableCell>

                        <TableCell>
                          <input
                            type="date"
                            defaultValue={
                              schedule?.payment_date !== undefined
                                ? moment(schedule?.payment_date).format("YYYY-MM-DD")
                                : ""
                            }
                            //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                            onChange={(e) => onHandleChange(e.target.value, "payment_date")}
                          />
                        </TableCell>

                        <TableCell>
                          <Checkbox
                            {...label}
                            defaultChecked={schedule?.is_received}
                            onChange={(e) => onHandleChange(e.target.checked, "is_received")}
                          />
                        </TableCell>

                        <TableCell>
                          <input
                            type="number"
                            // disabled={!schedule?.is_received}
                            defaultValue={schedule?.amount_paid}
                            className="focus:outline-none border border-gray-200 p-1 w-[100px]"
                            onChange={(e) => onHandleChange(e.target.value, "amount_paid")}
                          />
                        </TableCell>

                        <TableCell>
                          <input
                            type="date"
                            defaultValue={
                              schedule?.customer_payment_date !== undefined
                                ? moment(schedule?.customer_payment_date).format("YYYY-MM-DD")
                                : ""
                            }
                            //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                            onChange={(e) => onHandleChange(e.target.value, "customer_payment_date")}
                          />
                        </TableCell>

                        <TableCell>
                          <button
                            className="text-blue-500 w-full"
                            onClick={() =>
                              lotDocumentModalOpen({
                                payment_id: schedule?.id,
                                invoice_id: pi.id,
                                name: "receipt",
                              })
                            }
                          >
                            {payment?.receipt || schedule?.receipt
                              ? payment?.receipt?.filename || schedule?.receipt?.filename
                              : "Add"}
                          </button>
                        </TableCell>

                        <TableCell>
                          <button
                            className="text-blue-500 w-full"
                            onClick={() =>
                              lotDocumentModalOpen({
                                payment_id: schedule?.id,
                                invoice_id: pi.id,
                                name: "transaction_evidence",
                              })
                            }
                          >
                            {payment?.transaction_evidence || schedule?.transaction_evidence
                              ? payment?.transaction_evidence?.filename || schedule?.transaction_evidence?.filename
                              : "Add"}
                          </button>
                        </TableCell>

                        <TableCell>
                          <button
                            className="border bg-blue-500 active:bg-blue-700 rounded text-white py-1 px-2"
                            onClick={() => {
                              const error = {
                                "Is Customer Paid": payment?.is_customer_paid || schedule?.is_customer_paid,
                                "Is Received": payment?.is_received || schedule?.is_received,
                                "Amount Received": payment?.amount_paid || schedule?.amount_paid,
                                "Date Received": payment?.customer_payment_date || schedule?.customer_payment_date,
                              };

                              const findError = Object.keys(error).find((i) => !error[i]);
                              if (findError) {
                                notify(`Please fill ${findError}`, { type: "error" });
                                return false;
                              }
                              save({
                                payment_id: schedule?.id,
                                proforma_id: pi.id,
                              });
                            }}
                          >
                            Save
                          </button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </div>
          </div>
        </div>
      </TableContainer>
    </>
  );
};
export default ProformaDatagrid;
