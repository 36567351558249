import {
  ArrayField,
  BooleanField,
  useGetOne,
  Datagrid,
  ReferenceField,
  DateField,
  EmailField,
  FileField,
  ImageField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import SupplierInviteButton from "../button/SupplierInviteButton";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const RequestForPriceShow = () => {
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Request For Price",
        type: "text",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <Show title={<BreadcrumbContext />}>
      <SimpleShowLayout>
        <ReferenceField source="buyer_id" reference="buyers" label="Buyer" />
        <TextField source="supplier" label="Supplier Name" />
        <TextField source="product" label="Product Name" />

        <TextField source="inco_terms" label="Inco Terms" />
        <ReferenceField source="port_id" reference="ports" label="Port" />

        <TextField source="variant" label="Variant Name" />
        <TextField source="status" label="Status" />
        <DateField source="date_created" showTime />
        <DateField source="date_updated" showTime />
      </SimpleShowLayout>
    </Show>
  );
};
