import React, { useState, useCallback } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  DateInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Button,
  ReferenceInput,
  SelectInput,
  useGetList,
  BooleanInput,
  SelectArrayInput,
  required,
  useCreate,
  useRedirect,
  useNotify,
  useRecordContext,
} from "react-admin";
import BasicBreadcrumbs from "../../component/BasicBreadcrumbs";
import DynamicFields from "./DynamicFields";

const OrderCreate = () => {
  const [create, { isLoading, error }] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const ordersFields = useGetList("models/orders").data?.[0]?.fields;
  const statuses_notify_on = ordersFields?.observers?.fields?.notify_on?.fields?.status?.enum || [];
  const statuses_notify_on_map = statuses_notify_on.map((status_notify_on) => ({
    id: status_notify_on,
    name: status_notify_on,
  }));

  const [formValues, setFormValues] = useState([
    {
      supplier_id: "",
      freight_rate: "",
      product_id: "",
      margin: "",
      variant_id: "",
      packaging: "",
      material: "",
      labelling: "",
      inco_terms: "",
      port_id: "",
      bag_item_id: "",
      payment_terms_id: "",
      total_price: "",
      applied_supplier_product_pricing_id: "",
      price: "",
      quantity: "",
      pricing_unit: "",
      currency: "",
    },
  ]);

  const submit = useCallback(
    async (values) => {
      const payload = {
        is_test_order: values.content.test,
        observers: values.observers,
        enable_notifications: values.content.enable_notifications,
        order_creation_date: values.order_date,
        bag_item_ids: formValues.map((x) => x.bag_item_id),
      };

      try {
        await create(`buyers/${values.account_id}/orders`, { data: payload }, { returnPromise: true });
      } catch (error) {
        notify(error, { type: "error" });
      } finally {
        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        redirect("list", "orders");
      }
    },
    [create, notify, redirect, formValues]
  );

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Orders",
        type: "text",
      },
      {
        name: "Create",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <div className="w-full">
      <Create title={<BreadcrumbContext />}>
        <SimpleForm onSubmit={submit}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <>
                  <div className="w-full  flex-row lg:flex lg:space-x-6 lg:items-left ">
                    <ReferenceInput source="account_id" reference="buyers" perPage={1000}>
                      <SelectInput className="w-[100%] lg:w-[175px]" label="Buyer" validate={required()} />
                    </ReferenceInput>

                    <DateInput className="w-[100%] lg:w-[150px]" source="order_date" defaultValue={new Date()} />

                    <BooleanInput
                      className="w-[100%] lg:w-[220px]"
                      source="content.enable_notifications"
                      label="Enable order notifications"
                      defaultChecked={true}
                      defaultValue={true}
                    />

                    <BooleanInput className="w-[100%] lg:w-[200px] " source="content.test" label="Is Test Order" />
                  </div>

                  <div>
                    <p>Add Item</p>
                    <DynamicFields formData={[formValues, setFormValues]} buyer_id={formData?.account_id} />
                  </div>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default OrderCreate;
