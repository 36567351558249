import {
  BooleanField,
  DateField,
  EmailField,
  FileField,
  FunctionField,
  ImageField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import BuyerInviteButton from "../button/BuyerInviteButton";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { LabelArrWrapper, LabelWrapper } from "../component/Wrapper";

export const BuyerShow = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    //
    let links = [
      {
        name: "Buyers",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[80px] lg:w-[100%]">{record?.name}</p>,
        type: "link",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <Show title={<BreadcrumbContext />}>
      <SimpleShowLayout>
        <BuyerInviteButton />

        <div className="space-y-6">
          <div className="grid grid-cols-3 gap-4">
            <LabelWrapper source="content.is_approved" label="Approved">
              <BooleanField className="flex font-inter" source="content.is_approved" label="Buyer Approved?" />
            </LabelWrapper>

            <LabelWrapper source="name" label="name">
              <TextField className="flex font-inter" source="name" />
            </LabelWrapper>

            <LabelWrapper source="email" label="Email">
              <EmailField className="flex font-inter" source="email" />
            </LabelWrapper>

            <LabelWrapper source="phone" label="Phone">
              <NumberField className="flex font-inter" source="phone" />
            </LabelWrapper>

            <LabelWrapper source="whats_app" label="Whats app">
              <TextField className="flex font-inter" source="whats_app" />
            </LabelWrapper>

            <LabelWrapper source="currency" label="Currency">
              <TextField className="flex font-inter" source="currency" />
            </LabelWrapper>

            <LabelWrapper source="content.cin" label="CIN">
              <TextField className="flex font-inter" source="content.cin" />
            </LabelWrapper>

            <LabelWrapper source="date_created" label="Date Created">
              <DateField className="flex font-inter" source="date_created" />
            </LabelWrapper>

            <LabelWrapper source="logo.url" label="Logo">
              <ImageField
                className="flex font-inter"
                title="logo.filename"
                source="logo.url"
                label="Logo"
                target="_blank"
              />
            </LabelWrapper>
            <LabelWrapper label="Billing Address" source="billing">
              <FunctionField
                label="Address"
                source="billing"
                render={(record) =>
                  `${record.billing.address1}\n${record.billing.address2}\n${record.billing.country}`
                }
              />
            </LabelWrapper>

            <LabelWrapper label="Bank" source="bank_accounts">
              <FunctionField
                label="Bank"
                source="bank_accounts"
                render={(record) =>
                  `${record.bank_accounts?.[0]?.bank_name} ${record.bank_accounts?.[0]?.account_number}`
                }
              />
            </LabelWrapper>

            <LabelWrapper source="content.proof_of_business.url" label="Proof of Business">
              <FileField
                className="flex font-inter"
                title="content.proof_of_business.filename"
                source="content.proof_of_business.url"
                label="Proof of Business"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="content.letter_of_authorization.url" label="Letter of Authorization">
              <FileField
                className="flex font-inter"
                title="content.letter_of_authorization.filename"
                source="content.letter_of_authorization.url"
                label="Letter of Authorization"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="content.brn_certificate.url" label="VAT/GST/TIN/BRN Certificate">
              <FileField
                className="flex font-inter"
                title="content.brn_certificate.filename"
                source="content.brn_certificate.url"
                label="VAT/GST/TIN/BRN Certificate"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper
              source="content.business_registration_certificate.url"
              label="Business Registration Certificate"
            >
              <FileField
                className="flex font-inter"
                title="content.business_registration_certificate.filename"
                source="content.business_registration_certificate.url"
                label="Business Registration Certificate"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="vendor_gst_document.url" label="Vendor GST Document">
              <FileField
                className="flex font-inter"
                title="vendor_gst_document.filename"
                source="vendor_gst_document.url"
                label="Vendor GST Document"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="vendor_pan_card.url" label="Vendor PAN Card">
              <FileField
                className="flex font-inter"
                title="vendor_pan_card.filename"
                source="vendor_pan_card.url"
                label="Vendor PAN Card"
                target="_blank"
              />
            </LabelWrapper>
          </div>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
