import {
  Create,
  NumberInput,
  SelectInput,
  TextInput,
  SimpleForm,
  DateInput,
  BooleanInput,
  useGetList,
  required,
  useRecordContext,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
export const BuyerCreate = () => {
  const currency = [
    { id: "USD", name: "USD" },
    { id: "INR", name: "INR" },
    { id: "AED", name: "AED" },
  ];

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Buyers",
        type: "text",
      },
      {
        name: "Create",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <div className="w-full">
      <Create title={<BreadcrumbContext />}>
        <SimpleForm>
          <TextInput className="w-[100%] lg:w-[50%]" source="type" defaultValue="Business" disabled />
          <TextInput className="w-[100%] lg:w-[50%]" source="name" label="Business Name" validate={required()} />
          <TextInput className="w-[100%] lg:w-[50%]" source="email" validate={required()} />
          <NumberInput className="w-[100%] lg:w-[50%]" source="phone"  />
          <NumberInput className="w-[100%] lg:w-[50%]" source="whats_app" />
          <SelectInput className="w-[100%] lg:w-[50%]" source="currency" choices={currency} defaultValue="USD" />
          <TextInput className="w-[100%] lg:w-[50%]" source="content.cin" label="Company Identification Number" />
        </SimpleForm>
      </Create>
    </div>
  );
};
