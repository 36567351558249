import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import Button from "@mui/material/Button";
import fetch_with_auth from "../../utils/fetch";

const SplitItem = ({
  setLoading,
  item_id,
  current_lot,
  invoiceNumber = "",
  quantity = 0,
  openDialog,
  closDialog,
  lots = [],
}) => {
  const [data, setData] = useState({ quantity });
  const notify = useNotify();
  const refresh = useRefresh();
  const splitLot = async (payload) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/lots/${payload.from_lot_id}/bag-item/${payload?.lot_id}/split`;
      const response = await fetch_with_auth(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          to_lot_id: payload.to_lot_id,
          quantity: payload.quantity,
        }),
      });
      if (response.ok) {
        setLoading(false);
        notify(`Splitted and Moved successfully`, { type: "success" });
        refresh();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={openDialog}
      onClose={closDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <h2 className="px-5 pt-4 font-semibold text-lg">Split / move item from Customs Invoice: {invoiceNumber}</h2>
      <DialogContent className="w-[500px] space-y-6">
        <div>
          <label>Select Invoice</label>
          <select
            onChange={(e) => setData({ to_lot_id: e.target.value })}
            className="w-full border border-gray-300 px-2 py-1"
            placeholder="Document Type"
          >
            <option>Select</option>
            {lots?.map((ele, index) => (
              <option key={index} value={ele?.id}>
                {ele?.invoice_number}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Quantity</label>
          <input
            type="number"
            defaultValue={quantity}
            max={quantity}
            min={0}
            // value={data?.quantity}
            className="block border p-1 m-0"
            placeholder="Enter Quantity"
            onChange={(e) => {
              if (+e.target.value > quantity) {
                return null;
              }
              setData((prev) => ({ ...prev, quantity: e.target.value }));
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            splitLot({
              lot_id: item_id,
              from_lot_id: current_lot,
              to_lot_id: data?.to_lot_id,
              quantity: data?.quantity,
            })
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SplitItem;
