import {
  ArrayField,
  BooleanField,
  useGetOne,
  Datagrid,
  ReferenceField,
  DateField,
  EmailField,
  FileField,
  ImageField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import SupplierInviteButton from "../button/SupplierInviteButton";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const PaymentTermsShow = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Payment Terms",
        type: "text",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <Show title={<BreadcrumbContext />}>
      <SimpleShowLayout>
        <TextField source="name" label="Name" />
        <TextField source="advance_payment_percent" label="Payment Percent" />
      </SimpleShowLayout>
    </Show>
  );
};
