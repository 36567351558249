import { useCallback, useEffect, useState } from "react";
import Fab from "@mui/material/Fab";
import Popover from "@mui/material/Popover";
import ChatMessagePage from "../chat/ChatMessagePage";
import { firestore } from "../login/firebaseConfig";
import moment from "moment";

import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useGetOne,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
  useGetIdentity,
  useDataProvider,
  useNotify,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useGetUnreadMessage } from "../chat/useChat";
import fetch_with_auth from "../utils/fetch";

const status = [
  { id: "new", name: "New" },
  { id: "expired", name: "Expired" },
  { id: "fulfilled", name: "Fulfilled" },
];

export const RequestForQuoteEdit = () => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState();
  const notify = useNotify();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Request For Quote",
        type: "text",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  const RateComponent = ({ formData }) => {
    const record = useRecordContext();
    if (!record) return null;
    const sum = (items) => {
      if (!items || items.length == 0) { return 0 }
      return items.reduce(function (a, b) {
        return a + b;
      });
    };

    const specifyPrice = async (payload) => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          supplier_final_price : +formData?.negotiation_details?.supplier_final_price,
          final_margin_in_percent: +formData?.final_margin_in_percent,
          bag_item_id : record.id          
        });

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/${payload.supplier_id}/price_match_items/${payload.price_match_id}/negotiations/${payload.negotiation_id}/specify_price`,
          requestOptions
        );
        const data = await response.json();
      } catch (error) {
        notify(error, { type: "error" });
      } finally {
        notify("Saved Successfully", { type: "success" });
      }
    };

    const notifySupplier = async (payload) => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          destination: "supplier"
        });

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/${payload.supplier_id}/price_match_items/${payload.price_match_id}/negotiations/${payload.negotiation_id}/notify_supplier`,
          requestOptions
        );
        const data = await response.json();
      } catch (error) {
        notify(error, { type: "error" });
      } finally {
        notify("Notified Successfully", { type: "success" });
      }
    };
    
    let offer_price = record?.applied_supplier_product_pricing?.rate_per_unit
    let original_price = formData?.negotiation_details?.original_price;
    let default_margin = record?.applied_supplier_product_pricing?.supplier_products?.margin_in_percent
    let margin = formData?.final_margin_in_percent ? formData?.final_margin_in_percent : default_margin
    
    switch (record.inco_terms) {
      case "FOB":
        return (
          <div className="w-full ">
            <table className="w-full order-collapse border border-slate-500 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-600">Supplier Offer Price</th>
                  <th className="border border-slate-600">Margin</th>
                  <th className="border border-slate-600">Vas</th>
                  <th className="border border-slate-600">Quote Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-700">{parseFloat(offer_price)}</td>
                  <td className="border border-slate-700">
                    {((parseFloat(offer_price) * margin) / 100).toFixed(2)}
                  </td>
                  <td className="border border-slate-700">
                    {parseFloat(sum(record?.applied_vas.map((r) => r.vas_price))).toFixed(2)}
                  </td>
                  <td className="border border-slate-700">
                    {(
                      parseFloat(offer_price) +
                      (parseFloat(offer_price) * margin) / 100 +
                      parseFloat(sum(record?.applied_vas.map((r) => r.vas_price)))
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="w-full flex justify-end mt-2 space-x-4">
              <button
                disabled={formData?.negotiation_details.supplier_final_price === undefined ? true : false}
                onClick={() =>
                  notifySupplier({
                    negotiation_id: record?.negotiation_details?.id,
                    price_match_id: record?.id,
                    supplier_id: record?.applied_supplier_product_pricing?.supplier_products?.supplier_id,
                  })
                }
                type="button"
                className={`${
                  formData?.negotiation_details.supplier_final_price === undefined
                    ? "bg-gray-200 text-white"
                    : "bg-white text-blue-500 border border-blue-500"
                }   active:border-blue-600 px-3 py-1 rounded`}
              >
                Send to Supplier
              </button>

              <button
                disabled={formData?.negotiation_details.supplier_final_price === undefined ? true : false}
                onClick={() =>
                  specifyPrice({
                    negotiation_id: record?.negotiation_details?.id,
                    price_match_id: record?.id,
                    supplier_id: record?.applied_supplier_product_pricing?.supplier_products?.supplier_id,
                  })
                }
                type="button"
                className={`px-3 py-1 ${
                  formData?.negotiation_details.supplier_final_price === undefined ? "bg-gray-200" : "bg-blue-500"
                } active:bg-blue-600 text-white rounded`}
              >
                Send Offer
              </button>
            </div>
          </div>
        );

      case "CFR":
        return (
          <div className="w-full">
            <table className="w-full order-collapse border border-slate-500 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-600">
                    "Supplier Offer Price"
                    {/* {record?.negotiation_details?.supplier_final_price ? "Offer Price" : "Original Price"} */}
                  </th>
                  <th className="border border-slate-600">Supplier Offer Price</th>
                  <th className="border border-slate-600">Margin</th>
                  <th className="border border-slate-600">Vas</th>
                  <th className="border border-slate-600">Freight Rate</th>
                  <th className="border border-slate-600">Quote Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-700">{parseFloat(offer_price)}</td>
                  <td className="border border-slate-700">
                    {((parseFloat(offer_price) * margin) / 100).toFixed(2)}
                  </td>
                  {/* need to ask */}
                  <td className="border border-slate-700">{parseFloat(record?.applied_vas_value).toFixed(2)}</td>
                  <th className="border border-slate-700">{parseFloat(record?.freight_usd)}</th>

                  <td className="border border-slate-700">
                    {(
                      parseFloat(offer_price) +
                      (parseFloat(offer_price) * margin) / 100 +
                      parseFloat(record?.applied_vas_value) +
                      4
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="w-full flex justify-end mt-2 space-x-4">
              <button
                disabled={formData?.negotiation_details.supplier_final_price === undefined ? true : false}
                className={`${
                  formData?.negotiation_details.supplier_final_price === undefined ? "bg-gray-200" : "bg-white"
                }  text-blue-500 border border-blue-500 active:border-blue-600 px-3 py-1 rounded`}
              >
                Send to Supplier
              </button>

              <button
                disabled={formData?.negotiation_details.supplier_final_price === undefined ? true : false}
                className={`px-3 py-1 ${
                  formData?.negotiation_details.supplier_final_price === undefined ? "bg-gray-200" : "bg-blue-500"
                } active:bg-blue-600 text-white rounded`}
              >
                Send Offer
              </button>
            </div>
          </div>
        );
    }
  };

  const ChatModal = () => {
    const context = useRecordContext();
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div>
          <ChatMessagePage
            chatroomId={context.negotiation_details.chat_id}
            sender={{
              id: identity?.id,
              name: identity?.fullName,
            }}
          />
        </div>
      </Popover>
    );
  };

  const FloatingButton = () => {
    const context = useRecordContext();

    const { unreadMessages } = useGetUnreadMessage(firestore, context.negotiation_details.chat_id);

    return (
      <>
        {unreadMessages > 0 && (
          <div className="bg-pink-500 w-8 h-8 -mt-4 z-[9999] text-white flex justify-center items-center rounded-full">
            {unreadMessages}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          <ChatModal />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <>
                  <div className="w-full flex justify-between">
                    <TextInput
                      className="w-[100%] lg:w-[50%]"
                      source="negotiation_details.target_price"
                      label="Target Price"
                      disabled={true}
                    />

                    <div className="flex flex-col text-center">
                      <Fab onClick={handleClick} color="secondary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                          />
                        </svg>
                      </Fab>
                      <FloatingButton />
                    </div>
                  </div>

                  <TextInput
                    className="w-[100%] lg:w-[50%]"
                    source="final_margin_in_percent"
                    label="Margin in Percent"
                    defaultValue={formData.applied_supplier_product_pricing?.supplier_products?.margin_in_percent}
                  />

                  <TextInput
                    className="w-[100%] lg:w-[50%]"
                    source="negotiation_details.supplier_final_price"
                    label="Supplier's Offer Price"
                    defaultValue={formData.applied_supplier_product_pricing?.rate_per_unit}
                  />

                  <RateComponent formData={formData} />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </div>
  );
};
