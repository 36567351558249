import moment from "moment";

export const useTime = () => {
  const timeFormatter = (time = { seconds: "", nanoseconds: "" }) => {
    const fireBaseTime = new Date(time?.seconds * 1000 + time?.nanoseconds / 1000000).toLocaleTimeString([], {
      timeStyle: "short",
    });
    return fireBaseTime;
  };

  const dateTimeFormatter = (time = { seconds: "", nanoseconds: "" }) => {
    const fireBaseDate = new Date(time?.seconds * 1000 + time?.nanoseconds / 1000000).toLocaleDateString();
    const fireBaseTime = new Date(time?.seconds * 1000 + time?.nanoseconds / 1000000).toLocaleTimeString([], {
      timeStyle: "long",
    });
    return `${fireBaseDate}, ${fireBaseTime}`;
  };

  const shortDateTimeFormatter = (date) => {
    const formattedDate = moment(date).format("DD MMM HH:mm");
    return formattedDate;
  };

  return { timeFormatter, dateTimeFormatter, shortDateTimeFormatter };
};
