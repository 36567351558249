import {
  BooleanInput,
  Edit,
  FileInput,
  ArrayInput,
  NumberInput,
  SimpleFormIterator,
  FileField,
  SimpleForm,
  ImageField,
  ImageInput,
  useRecordContext,
  TextInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const BuyerEdit = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Buyers",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.name}</p>,
        type: "link",
      },
      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          <TextInput source="name" />
          <TextInput source="content.cin" />
          <BooleanInput className="w-[100%] lg:w-[50%]" source="content.is_approved" label="Is Approved" />
          <BooleanInput className="w-[100%] lg:w-[50%]" source="content.is_registration_complete" label="Registration Complete?" />
          <div className="grid grid-cols-2 gap-4">
            <TextInput source="billing.first_name" label="First Name" />
            <TextInput source="billing.last_name" label="Last Name" />
            <TextInput source="billing.company" label="Company Name" />
            <TextInput source="billing.phone" label="Phone" />
            <TextInput source="billing.address1" label="Address Line 1" />
            <TextInput source="billing.address2" label="Address Line 2" />
            <TextInput source="billing.city" label="City" />
            <TextInput source="billing.state" label="State" />
            <TextInput source="billing.country" label="Country Code" />
          </div>
          <ArrayInput source="bank_accounts">
            <SimpleFormIterator inline>
              <TextInput source="bank_name" helperText={false} />
              <NumberInput source="account_number" helperText={false} />
              <TextInput source="swift" helperText={false} />
              <TextInput source="account_name" helperText={false} />
              <TextInput source="address" multiline helperText={false} />
            </SimpleFormIterator>
          </ArrayInput>

          <div className="grid grid-cols-2 gap-4">
            <FileInput
              source="content.proof_of_business"
              label="Proof of Business"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="content.letter_of_authorization"
              label="Letter of Authorization"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="content.brn_certificate"
              label="VAT/GST/TIN/BRN Certificate"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="content.business_registration_certificate"
              label="Business Registration Certificate"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput
              source="vendor_gst_document"
              label="Vendor GST Document"
              className="border border-gray-400 rounded p-2"
            >
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <FileInput source="vendor_pan_card" label="Vendor PAN Card" className="border border-gray-400 rounded p-2">
              <FileField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </FileInput>
            <ImageInput source="logo" label="Logo" className="border border-gray-400 rounded p-2">
              <ImageField className="w-[100%] lg:w-[50%]" source="url" title="filename" target="_blank" />
            </ImageInput>
          </div>
        </SimpleForm>
      </Edit>
    </div>
  );
};
