import React from "react";
import { useRecordContext } from "react-admin";
import BasicBreadcrumbs from "../../component/BasicBreadcrumbs";

function Breadcrumbs() {
  const record = useRecordContext();
  if (!record) return null;
  let links = [
    {
      name: "Supplier Orders",
      type: "text",
    },
    {
      name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.number}</p>,
      type: "link",
    },
    {
      name: "Show",
      type: "text",
    },
  ];
  return <BasicBreadcrumbs links={links} />;
}

export default Breadcrumbs;
