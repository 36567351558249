import * as React from "react";
import {
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  EmailField,
  List,
  ShowButton,
  EditButton,
  Pagination,
  useRecordContext,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  DateField,
  TextInput,
  WrapperField,
  FunctionField,
  ImageField,
  ReferenceField,
  BooleanInput,
  Link,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const Filters = [<TextInput source="name" label="Search" alwaysOn />];

const ProductLink = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <a href={`https://onwo.swell.store/admin/products/${record.id}`} target={`_blank`}>Edit</a>
};


export const ProductList = () => {

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Request Quote",
        type: "text",
      },
      {
        name: "Lists",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <List filters={Filters} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <ImageField source="images" src="file.url" />

        <ArrayField source="variants">
          <SingleFieldList>
            <ChipField source="id" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Attributes"
          render={(record) =>
            Object.keys(record.attributes)
              .map((x) => `${x}:${record.attributes[x]}`)
              .join("\n")
          }
        />
        <ArrayField source="options">
          <WrapperField>
            <TextField source="name"></TextField>
            <ArrayField source="values">
              <TextField source="name"></TextField>
            </ArrayField>
          </WrapperField>
        </ArrayField>
        <TextField source="pricing_unit" />
        <TextField source="category_index.sort.631ec5e99706fc00122e7f08" />
        <ProductLink/>
      </Datagrid>
    </List>
  );
};
