import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  EmailField,
  ArrayField,
  Datagrid,
  ReferenceField,
  NumberField,
  FileField,
  BooleanField,
  SingleFieldList,
  ChipField,
  EditButton,
  ReferenceArrayField,
  useRecordContext,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import SurrenderBLButton from "../button/SurrenderBLButton";
import PreShipmentQCButton from "../button/PreShipmentQCButton";
import OrderStatusButton from "../button/OrderStatusButton";
import { StringToLabelObject } from "../component/StringToLabelObject";

import ItemEditButton from "../button/ItemEditButton";
import { useLocation } from "react-router-dom";
import ItemShowButton from "../button/ItemShowButton";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LabelArrWrapper, LabelWrapper } from "../component/Wrapper";

const styles = {
  label: {
    fontSize: "29px",
  },
};

const OrderShowComp = (props) => {
  const { pathname } = useLocation();
  // TODO fix this hack
  const orderId = pathname.split("/")[2];

  const DatagridCmp = () => {
    const { items: items_list = [] } = useRecordContext();

    return (
      <>
        {items_list?.length > 0 && (
          <>
            <TableContainer className="w-[100%] lg:w-[50%] mb-4 p-6" component={Paper}>
              <p className="font-bold text-black">Items</p>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="font-semibold">Name</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Quantity</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Price</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Status</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold">Variant Details</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items_list?.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>{item.product_name}</TableCell>

                      <TableCell>
                        <span className="capitalize">
                          {item.quantity} {item?.product?.pricing_unit}
                        </span>
                      </TableCell>

                      <TableCell>
                        {item.price} {item?.product?.currency}
                      </TableCell>

                      <TableCell>{item.metadata?.bag_item?.status}</TableCell>

                      <TableCell>
                        <div className="flex items-center space-x-1">
                          {item?.options?.length > 0
                            ? item?.options.map((ele, index) => {
                                return (
                                  <p key={index}>
                                    {ele?.name}
                                    {item.options.length > 1 && index < item.options.length - 1 && <span>,</span>}
                                  </p>
                                );
                              })
                            : " None "}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  };

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    let links = [
      {
        name: "Orders",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.number}</p>,
        type: "link",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <Show title={<BreadcrumbContext />}>
      <SimpleShowLayout>
        <div className="space-y-6">
          <div className="grid grid-cols-3 gap-4">
            <LabelWrapper label="Buyer Order Id" source="number">
              <TextField className="flex font-inter font-semibold text-xl" source="number" label="Supplier Order Id" />
            </LabelWrapper>

            <LabelWrapper label="Parent Order Id" source="parent_order_id">
              <TextField
                className="flex font-inter font-semibold text-xl"
                source="parent_order_id"
                label="Parent Order Id"
              />
            </LabelWrapper>

            <LabelWrapper label="Is Test Order" source="content.test">
              <BooleanField className="flex font-inter text-[29px]" source="content.test" label="Is Test Order" />
            </LabelWrapper>

            <LabelWrapper label="Date Created" source="date_created">
              <DateField className="flex font-inter" source="date_created" />
            </LabelWrapper>

            <LabelWrapper label="Date Updated" source="date_updated">
              <DateField className="flex font-inter" source="date_updated" />
            </LabelWrapper>

            <LabelWrapper label="Order Type" source="order_type">
              <TextField className="flex font-inter" source="order_type" />
            </LabelWrapper>

            <LabelWrapper label="Inco Terms" source="inco_terms">
              <TextField className="flex font-inter" source="inco_terms" />
            </LabelWrapper>

            <LabelWrapper label="Sub Total" source="sub_total">
              <TextField className="flex font-inter" source="sub_total" />
            </LabelWrapper>

            {/* <LabelWrapper label="Sub Status" source="order_substatus">
  <TextField source="order_substatus" label="Sub Status" />
</LabelWrapper> */}

            <LabelWrapper label="Buyer" source="account_id">
              <ReferenceField
                className="flex font-inter"
                source="account_id"
                reference="buyers"
                label="Buyer"
                link="show"
              />
            </LabelWrapper>

            <LabelWrapper label="Supplier" source="supplier_id">
              <ReferenceField
                className="flex font-inter"
                source="supplier_id"
                reference="suppliers"
                label="Supplier"
                link="show"
              />
            </LabelWrapper>
          </div>

          <div className="border-y">
            <div className="space-y-3 py-2">
              <LabelWrapper label="Status" source="supplier_order_status">
                <TextField source="supplier_order_status" label="Status" />
              </LabelWrapper>

              <LabelWrapper label="Payment Terms" source="payment_terms_2_id">
                <ReferenceField
                  className="flex font-inter"
                  source="payment_terms_2_id"
                  reference="payment-terms"
                  label="Payment Terms"
                />
              </LabelWrapper>

              {/* <LabelWrapper label="Invoice Id" source="invoice_number">
                <TextField source="invoice_number" label="Invoice Id" />
              </LabelWrapper> */}
            </div>
          </div>

          <div className="space-y-6">
            <LabelArrWrapper label="Supplier Orders" source="supplier_orders">
              <ArrayField label="Supplier Orders" source="supplier_orders">
                <Datagrid bulkActionButtons={false}>
                  <ReferenceField link="show" source="id" reference="suppliers/orders" label="Order Id" />
                </Datagrid>
              </ArrayField>
            </LabelArrWrapper>

            

            <div className="my-2">
              <DatagridCmp />
            </div>
           
          </div>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};

export const OrderShow = withStyles(styles)(OrderShowComp);
