import { Button, useDataProvider, useNotify, useRecordContext } from "react-admin";
import { useMutation } from "react-query";

const ISSupplierApproved = (record) => record?.content?.is_approved === true;

const SupplierInviteButton = (props) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  const notify = useNotify();
  const supplier_id = record.id;
  const { mutate, isLoading } = useMutation(["inviteSupplier", supplier_id], () =>
    dataProvider.inviteSupplier(supplier_id)
  );
  return (
    <div className="text-black flex justify-start lg:justify-end">
      <Button
        disabled={isLoading}
        color="primary"
        variant="contained"
        onClick={() =>
          mutate(undefined, {
            onSuccess: (response) => {
              const data = JSON.parse(response.body);
              notify(`Email Invite sent successfully to Supplier`);
            },
            onError: (err) => {
              notify(`Issue in sending email invite`);
            },
          })
        }
        sx={{
          padding: "6px 8px 4px 8px",
          fontSize: { xs: 16, md: 16 },
          visibility: ISSupplierApproved(record) !== true ? "visible" : "hidden",
        }}
      >
        Email Invite
      </Button>
    </div>
  );
};

export default SupplierInviteButton;
