import * as React from "react";
import {
  FileField,
  SimpleForm,
  Edit,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  Button,
  SelectInput,
  FileInput,
  useGetList,
  BooleanInput,
  NumberInput,
  TextInput,
  email,
  required,
  maxLength,
  minLength,
  Loading,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useLocation } from "react-router-dom";

const styles = {
  label: {
    fontSize: "29px",
  },
};

const SupplierOrderComp = (props) => {

  const bagItemModel = useGetList("models/bag-item").data?.[0].fields;
  const { data:order_model, isLoading, error } = useGetList("models/orders")

  if (isLoading) { return <Loading />; }
  if (error) { return <p>ERROR</p>; }

  const export_model_enums = isLoading ? [{id: 1, name: ""}] : order_model?.[0]?.fields?.export_model?.enum?.map((item) => ({
    id: item.value,
    name: item.label,
  }));
  
  console.log("EXE", order_model)
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    //
    let links = [
      {
        name: "Orders",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.number}</p>,
        type: "link",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <Edit title={<BreadcrumbContext />}>
      <SimpleForm>
        <BooleanInput label="Enable notifications" source="content.notifications_enabled" />
        <SelectInput label="Export Model" source="export_model" choices={export_model_enums}/>
        
        <ArrayInput source="items" label="Items">
          <SimpleFormIterator inline disableReordering disableRemove>
            <TextInput source="product_name" disabled />
            <NumberInput source="quantity" label="Qty MT" />
            <NumberInput source="price" label="Price USD" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="observers" label="Observers">
          <SimpleFormIterator inline disableReordering disableRemove>
            <BooleanInput source="is_notify_party" label="Is Notify Party" />
            <TextInput source="name" label="Name" validate={ required()} />
            <NumberInput source="whatsapp" label="WhatsApp" helperText="egs 911234512345. No gaps. No +" />
            <TextInput source="email" label="Email" validate={[email(), required()]} />
            <TextInput source="address.first_name" label="First Name" />
            <TextInput source="address.last_name" label="Last Name" />
            <TextInput source="address.address1" label="Address 1" />
            <TextInput source="address.address2" label="Address 2" />
            <TextInput source="address.city" label="City" />
            <TextInput source="address.state" label="State" />
            <TextInput source="address.zip" label="Zip" />
            <TextInput source="address.country" label="Country"  helperText="2 character country code" validate={[maxLength(2), minLength(2)]}/>
          </SimpleFormIterator>
        </ArrayInput>
        <div className="mt-5">Billing Address</div>
        <div className="grid grid-cols-3 space-x-5">
        <TextInput source="billing.first_name" label="First Name" />
        <TextInput source="billing.last_name" label="Last Name" />
        <TextInput source="billing.address1" label="Address Line 1" />
        <TextInput source="billing.address2" label="Address Line 2" />
        <TextInput source="billing.city" label="City" />
        <TextInput source="billing.state" label="State" />
        <TextInput source="billing.zip" label="Zip" />
        <TextInput source="billing.country" label="Country" helperText="2 Character country code" />
          <TextInput source="billing.phone" label="Phone" />
        </div>
        <div className="mt-5">Shipping Address</div>
        <div className="grid grid-cols-3 space-x-5">
        <TextInput source="shipping.first_name" label="First Name" />
        <TextInput source="shipping.last_name" label="Last Name" />
        <TextInput source="shipping.address1" label="Address Line 1" />
        <TextInput source="shipping.address2" label="Address Line 2" />
        <TextInput source="shipping.city" label="City" />
        <TextInput source="shipping.state" label="State" />
        <TextInput source="shipping.zip" label="Zip" />
        <TextInput source="shipping.country" label="Country" helperText="2 Character country code" />
          <TextInput source="shipping.phone" label="Phone" />
          </div>
      </SimpleForm>
    </Edit>
  );
};

export const SupplierOrderEdit = withStyles(styles)(SupplierOrderComp);
