import { Typography } from "@material-ui/core";
import moment from "moment";
import {
  BulkDeleteButton,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Loading,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useUnselectAll,
  useUpdate,
  useUpdateMany,
} from "react-admin";

export const SampleList = () => {
  let option_mapper = (item) => ({
    id: item,
    name: item,
  });

  const { data, total, isLoading, error } = useGetList("models/sample");

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const state = data?.[0]?.fields?.state.enum.map(option_mapper);
  const sourcing_owner = data?.[0]?.fields?.sourcing_owner.enum.map(option_mapper);
  const requested_by = data?.[0]?.fields?.requested_by.enum.map(option_mapper);

  const filters = [
    <SelectInput label="State" choices={state} source="state" alwaysOn />,
    <SelectInput label="Requested" choices={requested_by} source="requested_by" alwaysOn />,
    <SelectInput label="Sourcing Guy" choices={sourcing_owner} source="sourcing_owner" alwaysOn />,
  ];
    
  

  return (
    <List filters={filters}>
      <Datagrid rowClick="edit">
        <FunctionField
          render={(r) =>
            (r.state != "dispatched" || r.state != "received") ? moment(new Date()).to(moment(r.date_created)) : ""
          }
          label="Days Open"
        />
        <ReferenceField source="product_id" reference="products" />
        {/* <ReferenceField source="variant_id" reference="products:variants" /> */}
        <TextField source="buyer_address" />
        <NumberField source="count" />
        <TextField source="packet_size" />
        <TextField source="state" />
        <TextField source="internal_notes" />
        <TextField source="requested_by" />
        <TextField source="sourcing_owner" />
        <DateField source="date_created" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
};
