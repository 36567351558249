import { useRecordContext } from "react-admin";

export const LabelWrapper = ({ children, label, source }) => {
  const record = useRecordContext();
  const keys = source.split(".");
  const findRecord = keys.reduce((obj, key) => (obj && obj[key] ? obj[key] : ""), record);
  return (
    <>
      {!!findRecord && (
        <div className="flex flex-col">
          <p className="font-inter font-light text-xs">{label}</p>
          {children}
        </div>
      )}
    </>
  );
};

export const LabelArrWrapper = ({ children, label, source }) => {
  const record = useRecordContext();
  if (!record) return null;
  const keys = source.split(".");
  const findRecord = keys.reduce((obj, key) => (obj && obj[key] ? obj[key] : ""), record);
  return (
    <>
      {!!findRecord && Array.isArray(findRecord) && (
        <div className="shadow-lg w-full p-6 rounded">
          <div className="flex flex-col">
            <p className="font-inter font-light text-xs">{label}</p>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
