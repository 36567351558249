import React, { useEffect, useState } from "react";
import { firestore } from "../login/firebaseConfig";
import { useChatScroll } from "./useScroll";
import { useMessage, sendMessage, useGetChatroom, updateLastTimeStamp } from "./useChat";

import ChatRight from "./ChatRight";
import ChatLeft from "./ChatLeft";

const ChatMessagePage = (props) => {
  const { chatroomId, sender } = props;
  const [input, setInput] = useState({ msg: "" });

  const { chatroom } = useGetChatroom(firestore, chatroomId);

  const { allMessages } = useMessage(firestore, chatroomId);

  const send = async () => {
    if (input.msg !== "") {
      const mymsg = {
        msg: input.msg,
        sender: {
          id: sender?.id,
          name: sender?.name,
          img: sender?.img || "",
          type: "admin",
        },
        supplierVisible: true,
        buyerVisible: true,
        updatedAt: new Date(),
        createdAt: new Date(),
      };
      sendMessage(firestore, mymsg, chatroomId);
      setInput({
        msg: "",
      });
    }
  };

  useEffect(() => {
    if (chatroom && chatroom.lastSeen) {
      const payload = {
        ...chatroom,
        lastSeen: {
          ...chatroom.lastSeen,
          admin: {
            id: sender?.id,
            time: new Date(),
          },
        },
      };
      updateLastTimeStamp(firestore, payload, chatroom?.chatroomId);
    }
  }, [chatroom]);

  const onChangeHandler = (value, name) => {
    setInput({ ...input, [name]: value });
  };

  const ref = useChatScroll(allMessages);

  return (
    <div className="w-[330px] flex flex-col justify-between bg-[#F5FAF8] rounded">
      <div ref={ref} className="px-3 py-2 min-h-[calc(100vh-10rem)] scrollbar">
        {allMessages?.map((message, index) => {
          return (
            <div key={index}>
              {message?.sender?.id === sender?.id ? (
                <ChatRight message={message} chatroomId={chatroomId} />
              ) : (
                <ChatLeft message={message} />
              )}
            </div>
          );
        })}
      </div>

      {/* input box */}

      <div className="bg-white w-full h-20 flex space-x-2.5 items-center p-4 rounded-lg">
        <input
          onChange={(e) => onChangeHandler(e.target.value, "msg")}
          value={input?.msg}
          className="w-full focus:outline-none bg-[#F7F7F7] border border-[#0000001a] font-inter font-normal text-sm text-black opacity-50 p-3"
          placeholder="Type your message..."
        />

        <div onClick={send}>
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 11.9996H6V9.99962H0V0.845618C1.48755e-05 0.758732 0.0226703 0.67335 0.0657334 0.597888C0.108797 0.522425 0.170781 0.459486 0.245577 0.415275C0.320372 0.371063 0.405398 0.347106 0.492273 0.345763C0.579148 0.34442 0.664873 0.365739 0.741 0.407618L19.203 10.5616C19.2814 10.6048 19.3468 10.6682 19.3923 10.7452C19.4378 10.8223 19.4618 10.9101 19.4618 10.9996C19.4618 11.0891 19.4378 11.177 19.3923 11.254C19.3468 11.331 19.2814 11.3945 19.203 11.4376L0.741 21.5916C0.664873 21.6335 0.579148 21.6548 0.492273 21.6535C0.405398 21.6521 0.320372 21.6282 0.245577 21.584C0.170781 21.5397 0.108797 21.4768 0.0657334 21.4013C0.0226703 21.3259 1.48755e-05 21.2405 0 21.1536V11.9996Z"
              fill="#028866"
            />
          </svg>
        </div>
      </div>

      {/* <div className="shadow-lg bg-light-grey px-4 py-3 flex items-center space-x-4">
        <input
          onChange={(e) => onChangeHandler(e.target.value, "msg")}
          value={input.msg}
          className="w-full border focus:outline-none rounded px-2 py-1"
          type="text"
        />

        <button onClick={send}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
        </button>
      </div> */}
    </div>
  );
};

export default ChatMessagePage;
