import React, { useState } from "react";
import { useGetList, useRecordContext, useNotify, useRefresh } from "react-admin";
import { getBase64 } from "../../api/imageToBase64";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import fetch_with_auth from "../../utils/fetch";

const ItemDocumentModal = ({ documentDetails, itemDocumentModalClose, itemDocumentModel }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const bagItemModel = useGetList("models/bag-item").data?.[0].fields;

  let document_types = bagItemModel?.schedule?.fields?.documents?.fields?.document_type?.enum.map((type, index) => ({
    id: index,
    name: type,
  }));

  const [type, setType] = useState("");
  const [file, setFile] = useState();

  const handleSubmit = async (e) => {
    if (type && file) {
      const fileObj = await getBase64(file);

      if (!fileObj) {
        return;
      }
      const fileBuffer = {
        content_type: file.type,
        filename: file.name,
        data: { $binary: fileObj.split(";base64,")[1], $type: "00" },
      };

      try {
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            document_type: type,
            attachments: fileBuffer,
          }),
        };
        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/bag-items/${documentDetails?.bag_item_id}/schedule/${documentDetails?.schedule_id}/documents`,
          requestOptions
        );
        const data = await response.json();
      } catch (error) {
        notify(error, { type: "error" });
      } finally {
        notify("Uploaded Successfully", { type: "success" });
        refresh();
        itemDocumentModalClose();
      }
    } else {
      notify("Select all fields", { type: "error" });
    }
  };

  return (
    <Dialog
      open={itemDocumentModel}
      onClose={itemDocumentModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="w-[500px] space-y-6">
        <select
          onChange={(e) => setType(e.target.value)}
          className="w-full border border-gray-300 px-2 py-1"
          placeholder="Document Type"
        >
          <option>Select</option>
          {document_types?.map((ele, index) => (
            <option selected={ele.name == type} key={index} value={ele?.name}>
              {ele?.name}
            </option>
          ))}
        </select>
        <input type="file" placeholder="Enter File" onChange={(e) => setFile(e.target.files[0])} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDocumentModal;
