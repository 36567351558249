import React from "react";
import { useGetIdentity } from "react-admin";
import ChatroomList from "./ChatroomList";

const Chat = () => {
  const { data: identity, isLoading } = useGetIdentity();

  if (isLoading) {
    <div>Loading ...</div>;
  }

  return <>{identity && identity.id && <ChatroomList userId={identity.id} />}</>;
};

export default Chat;
