import * as React from "react";
import {
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  EmailField,
  List,
  ShowButton,
  EditButton,
  Pagination,
  useRecordContext,
  BooleanField,
  DateField,
  BooleanInput,
  AutocompleteInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const filterToQuery = searchText => ({ search: searchText, sort: "name asc" });


const filters = [
  <ReferenceInput source="id" reference="suppliers" alwaysOn>
    <AutocompleteInput className="w-[280px]" filterToQuery={filterToQuery} />
  </ReferenceInput>,
];

const SupplierList = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Suppliers",
        type: "text",
      },
      {
        name: "All",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <List title={<BreadcrumbContext />} filters={filters}>
      <Datagrid bulkActionButtons={false}>
        <DateField source="date_updated" />
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="phone" />
        <BooleanField source="content.is_approved" label="Approved?" />
        <BooleanField source="content.is_registration_complete" label="Registration Complete?" />
        <EditButton label="Edit" />
        <ShowButton label="Show" />
      </Datagrid>
    </List>
  );
};

export default SupplierList;
