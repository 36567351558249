const getMime = require("name2mime");

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const getFileData = async (asset) => {
  return {
    data: {
      $binary: await convertFileToBase64(asset.rawFile),
      $type: "00",
    },
    content_type: getMime(asset.title).type,
    filename: asset.title,
  };
};

export default getFileData;
