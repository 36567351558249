import React, { useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  EmailField,
  ArrayField,
  Datagrid,
  ReferenceField,
  NumberField,
  FileField,
  BooleanField,
  SingleFieldList,
  ChipField,
  EditButton,
  SelectField,
  useRefresh,
  SelectInput,
  FileInput,
  useGetList,
  useRecordContext,
  WrapperField,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useState } from "react";

import moment from "moment";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { getBase64 } from "../../api/imageToBase64";
import { useNotify } from "react-admin";
import Checkbox from "@mui/material/Checkbox";
import Breadcrumbs from "./Breadcrumbs";
import { LabelArrWrapper, LabelWrapper } from "../../component/Wrapper";
import CustomInvoiceGrid from "../../component/SupplierOrderShow/CustomInvoiceGrid";
import ItemDocumentModal from "./ItemDocumentModal";
import TabSelection from "./TabSelection";
import ClipLoader from "react-spinners/ClipLoader";
import fetch_with_auth from "../../utils/fetch";
import TrackModal from "../../component/SupplierOrderShow/TrackModal";
import ProformaDatagrid from "../../component/SupplierOrderShow/PerformaDataGrid";

const styles = {
  label: {
    fontSize: "29px",
  },
};

const TABTYPES = {
  PROFORMA: "Proforma",
  CUSTOM: "Customs Invoice",
  DOCUMENTS: "Documents",
};

const OrderShowComp = (props) => {
  const { pathname } = useLocation();
  // TODO fix this hack

  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const [documentDetails, setDocumentDetails] = useState();

  const [trackLinkId, setTrackLinkId] = useState();

  const [openTrack, setOpenTrack] = useState(false);

  const trackModalOpen = () => {
    setOpenTrack(true);
  };

  const trackModalClose = () => {
    setOpenTrack(false);
  };

  const [itemDocumentModel, setItemDocumentModel] = useState(false);

  const itemDocumentModalOpen = (payload) => {
    setDocumentDetails(payload);
    setItemDocumentModel(true);
  };

  const itemDocumentModalClose = () => {
    setItemDocumentModel(false);
  };

  const [orderDocumentModel, setOrderDocumentModel] = useState(false);

  const orderDocumentModalOpen = (payload) => {
    setDocumentDetails(payload);
    setOrderDocumentModel(true);
  };

  const orderDocumentModalClose = () => {
    setOrderDocumentModel(false);
  };

  const OrderDocumentModal = ({ documentDetails }) => {
    const [file, setFile] = useState();

    const handleSubmit = async (e) => {
      if (file) {
        const fileObj = await getBase64(file);

        if (!fileObj) {
          return;
        }
        const fileBuffer = {
          content_type: file.type,
          filename: file.name,
          data: { $binary: fileObj?.split(";base64,")[1], $type: "00" },
        };

        try {
          // setLoading(true);
          const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/orders/${documentDetails?.order_id}/payments/${documentDetails?.payment_id}`;

          const response = await fetch_with_auth(url, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              [documentDetails.name]: fileBuffer,
            }),
          });
          if (response.ok) {
            refresh();
            notify("Submitted Successfully", { type: "success" });
            orderDocumentModalClose();
          } else {
            throw new Error(
              JSON.stringify({
                code: response.status,
                message: response.statusText,
              })
            );
          }
        } catch (error) {
          notify(`Backend error. ${JSON.stringify(error.message)}`, {
            type: "error",
          });
          setLoading(false);
        }
      } else {
        notify("Select all fields", { type: "error" });
      }
    };

    return (
      <Dialog
        open={orderDocumentModel}
        onClose={orderDocumentModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="w-[500px] space-y-6">
          <p className="capitalize">Upload {documentDetails?.name?.replaceAll("_", " ")}</p>
          <input type="file" placeholder="Enter File" onChange={(e) => setFile(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [salesContractModal, setSalesContractModal] = useState(false);

  const salesContractModalOpen = (payload) => {
    setDocumentDetails(payload);
    setSalesContractModal(true);
  };

  const salesContractModalClose = () => {
    setSalesContractModal(false);
  };

  const SalesContractModal = ({ documentDetails }) => {
    const context = useRecordContext();

    const [input, setInput] = useState({
      insurance: context?.sales_contract?.insurance,
      jurisdiction: context?.sales_contract?.jurisdiction,
      taxation: context?.sales_contract?.taxation,
      remarks: context?.sales_contract?.remarks,
      noc: context?.sales_contract?.noc,
      documents: context?.sales_contract?.documents,
    });

    const onHandleChange = (value, name) => {
      setInput({ ...input, [name]: value });
    };

    const handleSubmit = async (e) => {
      try {
        //setLoading(true);
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            sales_contract: {
              insurance: input?.insurance,
              jurisdiction: input?.jurisdiction,
              taxation: input?.taxation,
              documents: input?.documents,
              remarks: input?.remarks,
              noc: input?.noc,
            },
          }),
        };
        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/update_supplier_contract`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
        const generate_res = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/regenerate_supplier_contract`,
          requestOptions
        );
        if (generate_res.ok) {
          notify("Uploaded Successfully", { type: "success" });
          salesContractModalClose();
          refresh();
        } else {
          throw new Error(
            JSON.stringify({
              code: generate_res.status,
              message: generate_res.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
        setLoading(false);
      }
    };

    return (
      <Dialog
        open={salesContractModal}
        onClose={salesContractModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="w-[500px] space-y-4">
          <p className="capitalize text-bold">Update Details</p>

          <div className="w-full flex flex-col space-y-4">
            <div className="w-full">
              <label for="html">Jurisdiction</label>
              <br />
              <textarea
                className="focus:outline-none w-full border border-black p-2 h-32"
                placeholder="Enter Jurisdiction"
                value={input?.jurisdiction}
                onChange={(e) => onHandleChange(e.target.value, "jurisdiction")}
              />
            </div>
            <div className="w-full">
              <label for="html">Taxation</label>
              <br />
              <textarea
                className="focus:outline-none w-full border border-black p-2 h-32"
                placeholder="Enter Taxation"
                value={input?.taxation}
                onChange={(e) => onHandleChange(e.target.value, "taxation")}
              />
            </div>
            <div className="w-full">
              <label for="html">Documents</label>
              <br />
              <textarea
                className="focus:outline-none w-full border border-black p-2 h-60"
                placeholder="Enter Documents List"
                value={input?.documents}
                onChange={(e) => onHandleChange(e.target.value, "documents")}
              ></textarea>
            </div>
            <div className="w-full">
              <label for="html">Remarks</label>
              <br />
              <textarea
                className="focus:outline-none w-full border border-black p-2 h-32"
                placeholder="Enter Remarks"
                value={input?.remarks}
                onChange={(e) => onHandleChange(e.target.value, "remarks")}
              />
            </div>
            <div className="w-full">
              <label for="html">NOC</label>
              <br />
              <textarea
                className="focus:outline-none w-full border border-black p-2 h-56"
                placeholder="Enter NOC"
                onChange={(e) => onHandleChange(e.target.value, "noc")}
              >
                {input.noc}
              </textarea>
            </div>
            <div className="w-full">
              <label for="html">Insurance</label>
              <br />
              <textarea
                className="w-full border border-black p-2"
                placeholder="Enter Insurance"
                value={input?.insurance}
                onChange={(e) => onHandleChange(e.target.value, "insurance")}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [salesContractDocumentModal, setSalesContractDocumentModal] = useState(false);

  const salesContractDocumentModalOpen = (payload) => {
    setDocumentDetails(payload);
    setSalesContractDocumentModal(true);
  };

  const salesContractDocumentModalClose = () => {
    setSalesContractDocumentModal(false);
  };

  const SalesContractDocumentModal = ({ documentDetails }) => {
    const context = useRecordContext();
    const [file, setFile] = useState();

    const handleSubmit = async (e) => {
      if (file) {
        const fileObj = await getBase64(file);

        if (!fileObj) {
          return;
        }

        try {
          setLoading(true);
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              type: "contract",
              raw: fileObj?.split(";base64,")[1],
            }),
          };
          const response = await fetch_with_auth(
            `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/documents`,
            requestOptions
          );
          if (response.ok) {
            setLoading(true);
            refresh();
            notify("Submitted Successfully", { type: "success" });
            salesContractDocumentModalClose();
          } else {
            throw new Error(
              JSON.stringify({
                code: response.status,
                message: response.statusText,
              })
            );
          }
        } catch (error) {
          notify(`Backend error. ${JSON.stringify(error.message)}`, {
            type: "error",
          });
          setLoading(false);
        }
      } else {
        notify("Select all fields", { type: "error" });
      }
    };

    return (
      <Dialog
        open={salesContractDocumentModal}
        onClose={salesContractDocumentModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="w-[500px] space-y-6">
          <p className="capitalize">Upload Document</p>
          <input type="file" placeholder="Enter File" onChange={(e) => setFile(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const [lotDocumentModel, setLotDocumentModel] = useState(false);

  const lotDocumentModalOpen = (payload) => {
    setDocumentDetails(payload);
    setLotDocumentModel(true);
  };

  const lotDocumentModalClose = () => {
    setLotDocumentModel(false);
  };

  const LotDocumentModal = ({ documentDetails }) => {
    const [file, setFile] = useState();

    const handleSubmit = async (e) => {
      if (file) {
        const fileObj = await getBase64(file);

        if (!fileObj) {
          return;
        }
        const fileBuffer = {
          content_type: file.type,
          filename: file.name,
          data: { $binary: fileObj?.split(";base64,")[1], $type: "00" },
        };

        try {
          setLoading(true);
          const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              [documentDetails.name]: fileBuffer,
            }),
          };

          const response = await fetch_with_auth(
            `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/invoices/${documentDetails?.invoice_id}/payment_schedule/${documentDetails?.payment_id}/documents`,
            requestOptions
          );
          if (response.ok) {
            setLoading(false);
            notify("Submitted Successfully", { type: "success" });
            // refresh();
            lotDocumentModalClose();
          } else {
            throw new Error(
              JSON.stringify({
                code: response.status,
                message: response.statusText,
              })
            );
          }
        } catch (error) {
          notify(`Backend error. ${JSON.stringify(error.message)}`, {
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      } else {
        notify("Select all fields", { type: "error" });
      }
    };

    return (
      <Dialog
        open={lotDocumentModel}
        onClose={lotDocumentModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="w-[500px] space-y-6">
          <p className="capitalize">Upload {documentDetails?.name?.replaceAll("_", " ")}</p>
          <input type="file" placeholder="Enter File" onChange={(e) => setFile(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const TabComponent = ({ tab, data, id, context }) => {
    const t = tab?.split("-")[0]?.trim();
    const invoice_no = tab?.split("-")[1]?.trim();
    const order_id = id;
    
    if (data) {
      switch (t) {
        case TABTYPES.PROFORMA:
          const proforma_invoices =
            data?.proforma_invoices?.length > 0 &&
            data?.proforma_invoices?.find((x) => x.invoice_number === invoice_no);
          return (
            <ProformaDatagrid
              lotDocumentModalOpen={lotDocumentModalOpen}
              setLoading={setLoading}
              pi={proforma_invoices}
              trackModalOpen={trackModalOpen}
              customs_invoices={data.customs_invoices}
              order_id={order_id}
              setTrackLinkId={setTrackLinkId}
            />
          );

        case TABTYPES.CUSTOM:
          const custom_invoices =
            data?.customs_invoices?.length > 0 && data?.customs_invoices?.find((x) => x.invoice_number === invoice_no);
          return (
            <CustomInvoiceGrid
              setDocumentDetails={setDocumentDetails}
              loadState={[loading, setLoading]}
              invoice={custom_invoices}
            />
          );

        case TABTYPES.DOCUMENTS:
          const uniqueDocs = []
          const map = new Map()
          
          data.all_documents?.forEach(d => {
            if (!map.has(d.id)) {
              map.set(d.id, true)
              uniqueDocs.push(d)
            }
          })
          return (
            <div>
              <TableContainer className="w-[100%] lg:w-[50%] mb-4 p-6" component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className="font-light">Type</span>
                      </TableCell>
                      <TableCell>
                        <span className="font-light">Filename</span>
                      </TableCell>
                      <TableCell>
                        <span className="font-light">Link</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uniqueDocs?.map((document) => (
                      <TableRow
                        key={document.id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>
                          <p>{document.type?.toUpperCase()}</p>
                        </TableCell>
                        <TableCell>
                          <p>{document.file_name}</p>
                        </TableCell>

                        <TableCell>
                          {" "}
                          <a className="text-blue-500" href={document.url} target="_blank">
                            {document.url}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          );

        default:
          return;
      }
    }
  };

  const [poDocumentModel, setPoDocumentModel] = useState(false);

  const poDocumentModalOpen = (payload) => {
    setDocumentDetails(payload);
    setPoDocumentModel(true);
  };

  const poDocumentModalClose = () => {
    setPoDocumentModel(false);
  };

  const PoDocumentModal = ({ documentDetails }) => {
    const context = useRecordContext();
    const [file, setFile] = useState();

    const handleSubmit = async (e) => {
      if (file) {
        try {
          setLoading(true);
          const fileObj = await getBase64(file);
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              type: "purchase_order",
              raw: fileObj?.split(";base64,")[1],
            }),
          };
          const response = await fetch_with_auth(
            `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/documents`,
            requestOptions
          );
          if (response.ok) {
            setLoading(false);
            refresh();
            notify(`Submitted successfully`, { type: "success" });
            poDocumentModalClose();
          } else {
            throw new Error(
              JSON.stringify({
                code: response.status,
                message: response.statusText,
              })
            );
          }
        } catch (error) {
          notify(`Backend error. ${JSON.stringify(error.message)}`, {
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      } else {
        notify("Select all fields", { type: "error" });
      }
    };

    return (
      <Dialog
        open={poDocumentModel}
        onClose={poDocumentModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="w-[500px] space-y-6">
          <p className="capitalize">Upload PO</p>
          <input type="file" placeholder="Enter File" onChange={(e) => setFile(e.target.files[0])} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ShowComponent = () => {
    const [tab, setTab] = useState();
    const context = useRecordContext();
    let sales_contract_doc =
      context?.all_documents?.length > 0 && context?.all_documents?.find((ele) => ele.type === "contract");

    let manage_po_doc =
      context?.all_documents?.length > 0 && context?.all_documents?.find((ele) => ele.type === "purchase_order");

    const sendContract = async () => {
      try {
        setLoading(true);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: {
              order: {
                id: context.id,
              },
            },
          }),
        };
        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/events/supplier_order_finalized`,
          requestOptions
        );
        if (response.ok) {
          setLoading(false);
          refresh();
          notify(`Sent successfully`, { type: "success" });
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    const [data, setData] = useState([]);

    const groupBy = (objectArray, fn) => {
      return objectArray.reduce((acc, obj) => {
        const key = fn(obj);
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    };

    const as_invoice_groups2 = (invoices) => {
      var group = (x) => {
        return x.inco_terms + ":" + x.port?.name + ":" + x.dest_port?.name;
      };
      return groupBy(invoices, group);
    };

    const some = (order) => {
      let invoices = [];
      if (order.proforma_invoices) {
        invoices = invoices.concat(order.proforma_invoices);
      }
      if (order.customs_invoices) {
        invoices = invoices.concat(order.customs_invoices);
      }

      let groups = as_invoice_groups2(invoices);

      return Object.keys(groups).map((ig) => {
        var invoices = groups[ig];
        let inco_terms = invoices.at(0).inco_terms;
        let port_id = invoices.at(0).port?.id;
        let dest_port_id = invoices.at(0).dest_port?.id;

        let proforma_invoices = invoices.filter(
          (x) =>
            x.type == "proforma_invoice" &&
            x.inco_terms === inco_terms &&
            x.port_id === port_id &&
            x.dest_port_id === dest_port_id
        );
        let customs_invoices = invoices.filter(
          (x) =>
            x.type == "customs_invoice" &&
            x.inco_terms === inco_terms &&
            x.port_id === port_id &&
            x.dest_port_id === dest_port_id
        );
        
        let all_documents = order?.all_documents?.filter(
          (x) =>
            x.invoice?.inco_terms === inco_terms &&
            x.invoice?.port_id === port_id &&
            x.invoice?.dest_port_id === dest_port_id
        );
        
        let tabTypes = [];
        proforma_invoices?.forEach((x) => tabTypes?.push(`Proforma-${x.invoice_number}`));
        customs_invoices?.forEach((x) => tabTypes?.push(`Customs Invoice-${x.invoice_number}`));
        tabTypes.push("Documents");

        return {
          inco_terms: invoices.at(0).inco_terms,
          port_name: invoices.at(0).port?.name,
          port_id: invoices.at(0).port?.id,
          dest_port_name: invoices.at(0).dest_port?.name,
          dest_port_id: invoices.at(0).dest_port?.id,
          all_documents: all_documents,
          proforma_invoices: proforma_invoices,
          customs_invoices: customs_invoices,
          // documents: order.all_documents,
          tabTypes: tabTypes,
        };
      });
    };

    useEffect(() => {
      let types = JSON.parse(localStorage.getItem("supplierOrder"));
      if (types) {
        setTab(types);
      } else {
        if (data?.length > 0) {
          let obj = {};
          let tabs = data?.forEach((x) => {
            obj[x.port_name] = "Documents";
          });
          setTab(obj);
        }
      }
    }, [data]);

    useEffect(() => {
      const getFetch = () => {
        let data = some(context);
        setData(data);
      };

      getFetch();
    }, [context]);

    const markAccepted = async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: {
              order: {
                id: context.id,
              },
            },
          }),
        };

        setLoading(true);
        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/events/supplier_order_accepted`,
          requestOptions
        );
        if (response.ok) {
          setLoading(false);
          notify(`Sent successfully`, { type: "success" });
          refresh();
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    const [input, setInput] = useState({
      buyer_reference: "",
    });

    const onChangeHandler = (value, name) => {
      setInput({ ...input, [name]: value });
    };

    const savePo = async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ buyer_reference: input.buyer_reference }),
        };

        setLoading(true);
        const response = await fetch_with_auth(
          `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context.id}/po`,
          requestOptions
        );
        if (response.ok) {
          setLoading(false);
          notify(`Saved successfully`, { type: "success" });
          refresh();
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <TrackModal
          itemDocumentModalOpen={itemDocumentModalOpen}
          trackLinkId={trackLinkId}
          trackModalClose={trackModalClose}
          track={[openTrack, setOpenTrack]}
          setLoading={setLoading}
        />

        {/* <InvoiceDocumentModal documentDetails={documentDetails} /> */}

        <ItemDocumentModal
          itemDocumentModel={itemDocumentModel}
          itemDocumentModalClose={itemDocumentModalClose}
          documentDetails={documentDetails}
        />

        <OrderDocumentModal documentDetails={documentDetails} />

        <PoDocumentModal documentDetails={documentDetails} />

        <SalesContractModal documentDetails={documentDetails} />

        <SalesContractDocumentModal documentDetails={documentDetails} />

        <LotDocumentModal documentDetails={documentDetails} />

        <div className="space-y-6">
          <div className="grid grid-cols-3 gap-4">
            <LabelWrapper label="Supplier Order Id" source="number">
              <TextField className="flex font-inter font-light text-xl" source="number" label="Supplier Order Id" />
            </LabelWrapper>

            <LabelWrapper label="Parent Order Id" source="parent_order_id">
              <TextField
                className="flex font-inter font-light text-xl"
                source="parent_order_id"
                label="Parent Order Id"
              />
            </LabelWrapper>

            <LabelWrapper label="Is Test Order" source="content.test">
              <BooleanField className="flex font-inter text-[29px]" source="content.test" label="Is Test Order" />
            </LabelWrapper>

            <LabelWrapper label="Date Created" source="date_created">
              <DateField className="flex font-inter" source="date_created" />
            </LabelWrapper>

            <LabelWrapper label="Date Updated" source="date_updated">
              <DateField className="flex font-inter" source="date_updated" />
            </LabelWrapper>

            <LabelWrapper label="Order Type" source="order_type">
              <TextField className="flex font-inter" source="order_type" />
            </LabelWrapper>

            <LabelWrapper label="Order Total" source="sub_total">
              <TextField className="flex font-inter" source="grand_total" />
            </LabelWrapper>

            <LabelWrapper label="Buyer" source="account_id">
              <ReferenceField
                className="flex font-inter"
                source="account_id"
                reference="buyers"
                label="Buyer"
                link="show"
              />
            </LabelWrapper>

            <LabelWrapper label="Supplier" source="supplier_id">
              <ReferenceField
                className="flex font-inter"
                source="supplier_id"
                reference="suppliers"
                label="Supplier"
                link="show"
              />
            </LabelWrapper>
            <LabelWrapper label="Export Model" source="export_model">
              <TextField
                className="flex font-inter uppercase"
                source="export_model"
                label="Export model"
              />
            </LabelWrapper>
          </div>
            <div className="border-t flex flex-rows justify-start font-light">
              <div className="space-y-3 py-2 uppercase">
                <LabelWrapper label="Order Status" source="supplier_order_status">
                  <TextField source="supplier_order_status" label="Status" className="py-2 uppercase"/>
                </LabelWrapper>
              </div>
              <div className="space-y-3 py-2  pl-10">
              <p>Bill To</p>
              <div className="flex flex-col text-sm">
                <div><TextField className="" source="billing.company" /></div>
                <div><TextField className="" source="billing.name" /></div>
                <div><TextField className="" source="billing.address1" /></div>
                <div><TextField className="" source="billing.address2" /></div>
                <div><TextField className="" source="billing.city" /></div>
                <div><TextField className="" source="billing.state" /></div>
                <div><TextField className="" source="billing.zip" /></div>
                <div><TextField className="" source="billing.country" /></div>
                <div><TextField className="" source="billing.phone" /></div>
                </div>
              </div>
            <div className="space-y-3 py-2  pl-10">
              <p>Ship To</p>
              <div className="flex flex-col text-sm">
                <div><TextField className="" source="shipping.name" /></div>
                <div><TextField className="" source="shipping.company" /></div>
                <div><TextField className="" source="shipping.address1" /></div>
                <div><TextField className="" source="shipping.address2" /></div>
                <div><TextField className="" source="shipping.city" /></div>
                <div><TextField className="" source="shipping.state" /></div>
                <div><TextField className="" source="shipping.zip" /></div>
                <div><TextField className="" source="shipping.country" /></div>
              <div><TextField className="" source="shipping.phone" /></div>
              </div>
            </div>
            <div class="space-y-3 py-2  pl-10">
            <p>Observers</p>
            <ArrayField source="observers">
                <Datagrid bulkActionButtons={false}>
                   <BooleanField source="is_notify_party" />
                    <TextField source="email" />
                    <TextField source="name" />
                  <TextField source="whatsapp" />
                  
                  <WrapperField label="address">
                    <TextField source="name" />
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    <TextField source="address1" /><TextField source="address2" />
                    <TextField source="city" /> <TextField source="state" /><TextField source="country" />
                    <TextField source="phone" />
                  </WrapperField>
                  
                </Datagrid>
            </ArrayField>
           </div>
            </div>
           
            
            

          {/* Details approved */}
          {!context?.account?.content?.is_approved && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <div className="mt-2 text-red-700">
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      <li>
                        This buyer is not approved. Please set up bank accounts, billing and shipping address for this
                        buyer.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* PO upload*/}
          <div className="content-start border-t pt-4 pb-4">
            <p className="font-bold text-black">Manage PO</p>
            <div className="flex justify-between mt-3">
              <div className="space-x-8">
                {manage_po_doc?.url && (
                  <a href={manage_po_doc?.url} target="_blank" className="text-blue-500">
                    View PO
                  </a>
                )}

                <button
                  className="px-8 py-1 bg-blue-400 active:bg-blue-500 rounded text-white"
                  onClick={() =>
                    poDocumentModalOpen({
                      order_id: context.id,
                    })
                  }
                >
                  Upload PO
                </button>
              </div>

              <div className="flex space-x-8">
                <input
                  placeholder="Buyer Reference"
                  value={input?.buyer_reference}
                  onChange={(e) => onChangeHandler(e.target.value, "buyer_reference")}
                  className="px-2 py-1 focus:outline-none border border-black rounded"
                />
                <button className="px-4 py-1 bg-blue-400 active:bg-blue-500 rounded text-white" onClick={savePo}>
                  Generate PO
                </button>
              </div>
            </div>
          </div>

          {/* <div className="h-[1px] w-full bg-gray-200" /> */}
          {/* sales_contract */}
          <div className="py-5 border-y">
            <p className="font-bold text-black">Manage Sales Contract</p>
            <div className="flex justify-between mt-3">
              <div className="space-x-8">
                {sales_contract_doc?.url && (
                  <a href={sales_contract_doc?.url} target="_blank" className="text-blue-500">
                    View Contract
                  </a>
                )}
                <button
                  className="px-8 py-1 bg-blue-400 active:bg-blue-500 rounded text-white"
                  onClick={salesContractDocumentModalOpen}
                >
                  Upload Contract
                </button>
                <button
                  className="px-8 py-1 bg-blue-400 active:bg-blue-500 rounded text-white"
                  onClick={salesContractModalOpen}
                >
                  Edit
                </button>
              </div>

              <div className="px-8 py-1 bg-blue-400 active:bg-blue-500 rounded text-white">
                <button onClick={sendContract}>Send contract to seller</button>
              </div>
            </div>
          </div>

          <div className="w-full">
            <strong>Manage Invoices</strong>
          <div className="flex justify-start mt-3">
              <button className={`px-2 py-1 rounded bg-blue-400 active:bg-blue-500 text-white`} onClick={markAccepted}>
                {context?.supplier_order_status === "accepted" ? "Recreate Invoice Documents" : "Mark as accepted"}
              </button>
            </div>
          </div>
          {data.length > 0 &&
            data?.map((x, index) => {
              if (tab) {
                return (
                  <TabSelection key={index} data={x} tab={tab} setTab={setTab}>
                    <TabComponent context={context} tab={tab[x.port_name]} data={x} id={context.id} />
                  </TabSelection>
                );
              }
            })}

          <div>
            <p className="font-bold text-black">Order Related Documents</p>

            <ArrayField source="documents">
              <Datagrid bulkActionButtons={false}>
                <TextField className="flex font-inter" source="type" />
                <FileField title="filename" source="asset" src="url" target="_blank" />
              </Datagrid>
            </ArrayField>
          </div>
        </div>
      </>
    );
  };

  return (
    <Show title={<Breadcrumbs />}>
      <SimpleShowLayout>
        {loading && (
          <div className="absolute flex justify-center items-center  inset-0 w-screen h-screen">
            <ClipLoader color="black" size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        )}

        <ShowComponent />
      </SimpleShowLayout>
    </Show>
  );
};

export const SupplierOrderShow = withStyles(styles)(OrderShowComp);
