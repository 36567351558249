import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  Timestamp,
  setDoc,
  doc,
  getDoc,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  where,
  limit,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const createChatroom = async (firestore, payload) => {
  const chatroomId = uuidv4();
  const ref = doc(firestore, `chatroom/${chatroomId}`);
  await setDoc(ref, { ...payload, chatroomId, createdAt: new Date() });
  return chatroomId;
};

export const sendMessage = async (firestore, payload, chatroomId) => {
  const mymsg = { ...payload, createdAt: new Date() };
  const paymentRef = collection(firestore, `chatroom/${chatroomId}/messages`);
  await addDoc(paymentRef, mymsg);
};

export const useGetUnreadMessage = (firestore, chatroomId) => {
  const [messages, setMessages] = useState([]);
  const { chatroom } = useGetChatroom(firestore, chatroomId);

  useEffect(() => {
    if (chatroom) {
      let queryTime =
        chatroom?.lastSeen?.admin?.time?.seconds * 1000 + chatroom?.lastSeen?.admin?.time?.nanoseconds / 1000000;

      let queryDate = !!queryTime ? new Date(queryTime) : new Date();

      const collectionRef = collection(firestore, `chatroom/${chatroomId}/messages`);

      let w3 = where("createdAt", ">", queryDate);

      const q = query(collectionRef, w3);

      let unsubscribe = onSnapshot(q, (snapshot) => {
        let filtered = snapshot.docs
          .filter((ele) => ele.data()?.sender.id !== chatroom?.admin?.id)
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        setMessages(filtered);
      });

      return () => unsubscribe();
    }
  }, [chatroom]);

  return {
    unreadMessages: messages?.length,
  };
};

export const updateLastTimeStamp = async (firestore, payload, chatroomId) => {
  const mymsg = { ...payload };
  const paymentRef = doc(firestore, `chatroom/${chatroomId}`);
  await updateDoc(paymentRef, mymsg);
};

export const updateMessage = async (firestore, payload, path) => {
  const mymsg = { ...payload, updatedAt: new Date() };
  const paymentRef = doc(firestore, path);
  await setDoc(paymentRef, mymsg);
};

export const useMessage = (firestore, chatroomId) => {
  const [allMessages, setAllMessages] = useState([]);

  useEffect(() => {
    const docRef = collection(firestore, `chatroom/${chatroomId}/messages`);
    const q = query(docRef, orderBy("updatedAt", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setAllMessages(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return () => unsubscribe();
  }, [chatroomId]);

  return {
    allMessages,
  };
};

export const useGetChatrooms = (firestore, { qName, qValue }) => {
  const [chatrooms, setChatrooms] = useState([]);
  useEffect(() => {
    const docRef = collection(firestore, `chatroom`);
    // const q = query(docRef, where(qName, "==", qValue));
    const q = query(docRef);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setChatrooms(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return () => unsubscribe();
  }, []);

  return {
    chatrooms,
  };
};

export const useGetChatroom = (firestore, chatroomId) => {
  const [chatroom, setChatroom] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(firestore, `chatroom/${chatroomId}`);
      const docSnap = await getDoc(docRef);
      setChatroom(docSnap.data());
    };
    fetchData();
  }, [chatroomId]);

  return {
    chatroom,
  };
};
