import { fetchUtils } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { stringify } from "query-string";
import getFileData from "./utils";
import { data } from "autoprefixer";
const _ = require('lodash');

const httpClient = (url, options = {}) => {
  options.headers = new Headers({
    user: process.env.REACT_APP_USER || "",
    "user-access": `${process.env.REACT_APP_PASSWORD}` || "",
    ...options.headers,
  });

  return fetchUtils.fetchJson(url, options);
};

const processFileUpload = async function (documents, paymentSchedules, statusChange) {
  if (statusChange.po?.file) {
    const fileData = await getFileData(statusChange.po.file);
    var doc = documents?.filter((document) => document.type === "purchase_order")?.[0];
    if (doc) {
      doc.asset?.push(fileData);
    } else {
      documents.push({
        type: "purchase_order",
        asset: [fileData],
      });
    }
  }

  if (statusChange.payment_receipt?.file) {
    const fileData = await getFileData(statusChange.payment_receipt.file);
    paymentSchedules[0].receipt = fileData;
    paymentSchedules[0].payment_received = true;
  }
};

const apiUrl = process.env.REACT_APP_ONWO_BACKEND_URL;

const dataProvider = jsonServerProvider(apiUrl, httpClient);
const onwoDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      limit: perPage,
      page: page,
      sort_field: params.sort?.field,
      sort_order: params.sort?.order,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data.results || [],
        total: parseInt(json.data.count || 0),
      };
    });
  },
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;

    var filter = {
      ...params.filter,
      [params.target]: params.id,
    };
    const query = {
      ...fetchUtils.flattenObject(filter),
      limit: perPage,
      page: page,
      sort_field: params.sort?.field,
      sort_order: params.sort?.order,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data.results || [],
        total: parseInt(json.data.count || 0),
      };
    });
  },
  getOne: (resource, params) => {
    if (resource === "items") {
      const {
        meta: { orderId },
      } = params;
      resource = `orders/${orderId}` + "/" + resource;
    }
    const query = { ...fetchUtils.flattenObject(params.filter) };
    return httpClient(`${apiUrl}/${resource}/${params.id}?${stringify(query)}`).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data.results || [],
    }));
  },
  // create: async (resource, params) => {
  //   const payload = {
  //     ...params,
  //     data: {
  //       ...params.data,
  //     },
  //   };
  //   console.log("000000000000000000000", payload);
  //   let res = await dataProvider.create(resource, payload);

  //   return res.data;
  // },
  update: async (resource, params) => {
    if (resource === "supplier-products") {
      let available_vas_ids = params?.data?.available_vas_ids || [];
      params.data.available_vas_ids = available_vas_ids.join(",");
    }

    if (resource === "rfq-response") {
      var pdf_upload = params.data.pdf_upload;
      if (pdf_upload?.file?.rawFile instanceof File) {
        pdf_upload.file = await getFileData(pdf_upload.file);
      }
    }

    if (resource === "items") {
      const {
        meta: { orderId },
      } = params;
      resource = `orders/${orderId}` + "/" + resource;
    }

    if (resource === "suppliers" || resource === "buyers") {
      var content = params.data.content;
      var bank_accounts = params.data.bank_accounts;
      var factories = params.data.factories;
      var certifications = params.data.content.certifications;
      if (content?.proof_of_business?.rawFile instanceof File) {
        content.proof_of_business = await getFileData(content.proof_of_business);
      }
      if (content?.letter_of_authorization?.rawFile instanceof File) {
        content.letter_of_authorization = await getFileData(content.letter_of_authorization);
      }
      if (content?.brn_certificate?.rawFile instanceof File) {
        content.brn_certificate = await getFileData(content.brn_certificate);
      }
      if (content?.business_registration_certificate?.rawFile instanceof File) {
        content.business_registration_certificate = await getFileData(content.business_registration_certificate);
      }
      if (params.data.vendor_gst_document?.rawFile instanceof File) {
        params.data.vendor_gst_document = await getFileData(params.data.vendor_gst_document);
      }
      if (params.data.vendor_pan_card?.rawFile instanceof File) {
        params.data.vendor_pan_card = await getFileData(params.data.vendor_pan_card);
      }
      if (params.data.logo?.rawFile instanceof File) {
        params.data.logo = await getFileData(params.data.logo);
      }
      if (params.data.signature?.rawFile instanceof File) {
        params.data.signature = await getFileData(params.data.signature);
      }
      for (let i = 0; i < bank_accounts?.length; i++) {
        if (bank_accounts[i].bank_proof?.rawFile instanceof File) {
          bank_accounts[i].bank_proof = await getFileData(bank_accounts[i].bank_proof);
        }
      }

      for (let i = 0; i < factories?.length; i++) {
        if (factories[i].fssai_document?.rawFile instanceof File) {
          factories[i].fssai_document = await getFileData(factories[i].fssai_document);
        }
      }
    }

    var documents = params.data.documents || [];

    var updatedDocuments = await Promise.all(
      documents.map(async (document) => {
        const assets = document["asset"] || [];
        for (let i = 0; i < assets.length; i++) {
          const asset = assets[i];
          if (asset?.rawFile instanceof File) {
            assets[i] = await getFileData(asset);
          }
        }
        document["asset"] = assets;
        return document;
      })
    );

    let payload = { ...params };

    if (resource === "suppliers/orders") {
      resource = "orders"
      console.log("override resource")
    }

    if (resource === "orders") {
      var documents = params.data.documents || [];
      // var statusChange = params.data.status_change;

      var updatedDocuments = await Promise.all(
        documents.map(async (document) => {
          const assets = document["asset"] || [];
          for (let i = 0; i < assets.length; i++) {
            const asset = assets[i];
            if (asset?.rawFile instanceof File) {
              assets[i] = await getFileData(asset);
            }
          }
          document["asset"] = assets;
          return document;
        })
      );

      var allowed_new = {}
      var allowed_previous = {}
      var process_change = (key) => {
        allowed_new[key] = params.data[key]
        allowed_previous[key] = params.previousData[key]
        
      }
      ["billing", "export_model", "shipping", "content", "items", "observers", "sales_contract"].forEach(k => { 
        process_change(k)
      })
      
      payload = {
        id: params.id,
        data: allowed_new,
        previousData : allowed_previous
      };
      console.log("PAYLOAD", payload)
    }

    let res = await dataProvider.update(resource, {
      ...payload,
    });
    
    return res.data;
  },

  inviteSupplier: (supplier_id) => {
    console.log("Inviting the supplier with id ", supplier_id);
    return httpClient(`${apiUrl}/suppliers/invite/${supplier_id}`, {
      method: "POST",
    });
  },

  inviteBuyer: (buyer_id) => {
    console.log("Inviting the supplier with id ", buyer_id);
    return httpClient(`${apiUrl}/buyers/${buyer_id}/invite`, {
      method: "POST",
    });
  },
};

export default onwoDataProvider;
