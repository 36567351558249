import {
  DateInput,
  Edit,
  Loading,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
} from "react-admin";

export const SampleEdit = () => {
  let option_mapper = (item) => ({
    id: item,
    name: item,
  });

  const { data, total, isLoading, error } = useGetList("models/sample");

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const state = data?.[0]?.fields?.state.enum.map(option_mapper);
  const packet_size = data?.[0]?.fields?.packet_size.enum.map(option_mapper);
  const sourcing_owner = data?.[0]?.fields?.sourcing_owner.enum.map(option_mapper);
  const requested_by = data?.[0]?.fields?.requested_by.enum.map(option_mapper);

  return (
    <Edit>
      <SimpleForm>
        <div className="grid grid-cols-3 gap-4">
          <ReferenceInput source="product_id" reference="products" />
          {/* <ReferenceInput source="variant_id" reference="products:variants" /> */}
          <SelectInput className="w-[100%] lg:w-[50%]" source="requested_by" choices={sourcing_owner} defaultValue="" />
          <SelectInput className="w-[100%] lg:w-[50%]" source="sourcing_owner" choices={requested_by} defaultValue="" />

          <TextInput source="buyer_address" />

          <SelectInput className="w-[100%] lg:w-[50%]" source="packet_size" choices={packet_size} defaultValue="" />
          <NumberInput source="count" />

          <TextInput source="internal_notes" />
        </div>
        <div className="grid grid-cols-2">
                  <SelectInput className="w-[100%] lg:w-[50%]" source="state" choices={state} defaultValue="" />
                  <TextInput source="awb" />
        </div>
      </SimpleForm>
    </Edit>
  );
};
