import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useRecordContext,
  DateInput,
  useGetOne,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useTime } from "../hooks/getTime";


export const SupplierProductPricingEdit = () => {
  const supplierFields = useGetList("models/supplier-product-pricing")?.data?.[0].fields;
  const ports = useGetList("ports")?.data?.map((item) => ({
    id: item.id,
    name: item.name + "-" + item.country,
  }));
  
  const status = supplierFields?.status?.enum?.map((status) => ({
    id: status,
    name: status,
  }));

  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    const { shortDateTimeFormatter } = useTime();
    const { data: supplierProducts } = useGetOne(`supplier-products`, {
      id: record?.supplier_products_id,
    });

    let links = [
      {
        name: "Supplier Products",
        type: "text",
      },
      {
        name: "Supplier Products pricing",
        type: "text",
      },
      {
        name: shortDateTimeFormatter(record.date_created),
        type: "text",
      },

      {
        name: "Edit",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          {status?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="status"
              label="Supplier Products pricing Status"
              choices={status}
            />
          )}
          
          <SelectInput className="w-[100%] lg:w-[50%]" source="port_id" label="Port" choices={ports} />
          
          <TextInput className="w-[100%] lg:w-[50%]" source="rate_per_unit" label="Rate Per unit (USD)" />

          <DateInput className="w-[100%] lg:w-[50%]" source="valid_from" label="Valid From" />
          <DateInput className="w-[100%] lg:w-[50%]" source="valid_till" label="Valid Till" />
        </SimpleForm>
      </Edit>
    </div>
  );
};
