import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FileField,
  ImageField,
  NumberField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  WrapperField,
  useRecordContext,
} from "react-admin";
import SupplierInviteButton from "../button/SupplierInviteButton";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { LabelArrWrapper, LabelWrapper } from "../component/Wrapper";

export const SupplierShow = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Suppliers",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">{record?.name}</p>,
        type: "link",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <Show title={<BreadcrumbContext />}>
      <SimpleShowLayout>
        <SupplierInviteButton />

        <div className="space-y-6">
          <div className="grid grid-cols-4 gap-4">
            <LabelWrapper source="name" label="Name">
              <TextField className="flex  " source="name" />
            </LabelWrapper>

            <LabelWrapper source="email" label="Email">
              <EmailField className="flex  " source="email" />
            </LabelWrapper>

            <LabelWrapper source="phone" label="Phone">
              <NumberField className="flex  " source="phone" />
            </LabelWrapper>

            <LabelWrapper source="content.is_approved" label="Is Approved">
              <BooleanField className="flex  " source="content.is_approved" label="Approved" />
            </LabelWrapper>

            <LabelWrapper source="is_registration_complete" label="Registration complete?">
              <BooleanField className="flex  " source="is_registration_complete" />
            </LabelWrapper>

            <LabelWrapper source="t_and_c_agreed" label="T&C Accepted?">
              <BooleanField className="flex  " source="t_and_c_agreed" label="Terms and Conditions Agreed" />
            </LabelWrapper>

            <LabelWrapper source="use_physical_signature" label="Use Physical Signature?">
              <BooleanField className="flex  " source="use_physical_signature" label="Use Physical Signature" />
            </LabelWrapper>


            <LabelWrapper source="gst" label="GST">
              <TextField className="flex  " source="gst" label="Tax ID" />
            </LabelWrapper>

            <LabelWrapper source="whats_app" label="Whats app">
              <TextField className="flex  " source="whats_app" />
            </LabelWrapper>

            <LabelWrapper source="currency" label="currency">
              <TextField className="flex  " source="currency" />
            </LabelWrapper>

            <LabelWrapper source="vat_number" label="Vat Number">
              <TextField className="flex  " source="vat_number" />
            </LabelWrapper>

            <LabelWrapper source="ie_code" label="IE Code">
              <TextField className="flex  " source="ie_code" />
            </LabelWrapper>

            <LabelWrapper source="pan" label="PAN">
              <TextField className="flex  " source="pan" />
            </LabelWrapper>

            <LabelWrapper source="fssai_license" label="FSSAI_LICENSE">
              <TextField className="flex  " source="fssai_license" />
            </LabelWrapper>

            <LabelWrapper source="lut_arn" label="LUT ARUN">
              <TextField className="flex  " source="lut_arn" />
            </LabelWrapper>
            <LabelWrapper source="lut_arn_date" label="LUT ARN Date">
              <DateField className="flex  " source="lut_arn_date" />
            </LabelWrapper>

            <LabelWrapper source="date_created" label="Date Created">
              <DateField className="flex  " source="date_created" />
            </LabelWrapper>

            

            <LabelWrapper source="spice_board_registration_no" label="Spice Board Registration">
              <TextField className="flex  " source="spice_board_registration_no" label="Spice Board Registration" />
            </LabelWrapper>

            <LabelWrapper source="spice_board_certificate_no" label="Spice Board Certification">
              <TextField className="flex  " source="spice_board_certificate_no" label="Spice Board Certification" />
            </LabelWrapper>

            
            <div className="grid grid-cols-1 gap-0 ">
              <span className="font-light text-sm">Billing Address</span>
              <TextField source="billing.name" label="Name" />
              <TextField source="billing.company" label="Company" />
              <TextField source="billing.address1" label="address1"/>
              <TextField source="billing.address2" label="address2"/>
              <TextField source="billing.city" label="City" />
              <TextField source="billing.state" label="State"/>
              <TextField source="billing.zip" label="Zip"/>
              <TextField source="billing.country" label="Country"/>
            </div>
            <div className="grid grid-cols-1 gap-0 ">
              <span className="font-light text-sm">Shipping Address</span>
              <TextField source="shipping.name" label="Name" />
              <TextField source="shipping.company" label="Company" />
              <TextField source="shipping.address1" label="address1"/>
              <TextField source="shipping.address2" label="address2"/>
              <TextField source="shipping.city" label="City" />
              <TextField source="shipping.state" label="State"/>
              <TextField source="shipping.zip" label="Zip"/>
              <TextField source="shipping.country" label="Country"/>
            </div>
            
          </div>
          
          <div className="width:full height:10px border border-color-600"></div>
          <div className="grid grid-cols-4 gap-4">
            <LabelWrapper source="logo.url" label="Logo">
              <ImageField className="flex  " title="logo.filename" source="logo.url" label="Logo" target="_blank" />
            </LabelWrapper>

            <LabelWrapper source="signature.url" label="Signature">
              <ImageField className="flex " title="signature.filename" source="signature.url" label="Signature" target="_blank" />
            </LabelWrapper>

            <LabelWrapper source="content.proof_of_business.url" label="Proof of Business">
              <FileField
                className="flex  "
                title="content.proof_of_business.filename"
                source="content.proof_of_business.url"
                label="Proof of Business"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="content.letter_of_authorization.url" label="Letter of Authorization">
              <FileField
                className="flex  "
                title="content.letter_of_authorization.filename"
                source="content.letter_of_authorization.url"
                label="Letter of Authorization"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="content.brn_certificate.url" label="VAT/GST/TIN/BRN Certificate">
              <FileField
                className="flex  "
                title="content.brn_certificate.filename"
                source="content.brn_certificate.url"
                label="VAT/GST/TIN/BRN Certificate"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper
              source="content.business_registration_certificate.url"
              label="Business Registration Certificate"
            >
              <FileField
                className="flex  "
                title="content.business_registration_certificate.filename"
                source="content.business_registration_certificate.url"
                label="Business Registration Certificate"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="vendor_gst_document.url" label="Vendor GST Document">
              <FileField
                className="flex  "
                title="vendor_gst_document.filename"
                source="vendor_gst_document.url"
                label="Vendor GST Document"
                target="_blank"
              />
            </LabelWrapper>

            <LabelWrapper source="vendor_pan_card.url" label="Vendor PAN Card">
              <FileField
                className="flex  "
                title="vendor_pan_card.filename"
                source="vendor_pan_card.url"
                label="Vendor PAN Card"
                target="_blank"
              />
            </LabelWrapper>
          </div>
        </div>

        <div>
          <LabelArrWrapper source="bank_accounts" label="Bank Accounts">
            <ArrayField source="bank_accounts">
              <Datagrid bulkActionButtons={false}>
                <TextField className="flex  " source="bank_name" />
                <TextField className="flex  " source="account_number" />
                <TextField className="flex  " source="isfc" />
                <FileField
                  className="flex  "
                  title="bank_proof.filename"
                  source="bank_proof.url"
                  target="_blank"
                  label="Bank proof document"
                />
              </Datagrid>
            </ArrayField>
          </LabelArrWrapper>

          <div className="flex flex-col mt-10 ml-10">
            <p className="  font-bold ">Orders</p>
            <ReferenceManyField label="Orders" reference="supplierorders" target="supplier_id">
              <Datagrid bulkActionButtons={false}>
                <BooleanField source="content.is_test" label="Test?" />
                <TextField source="number" label="ID" />
                <TextField source="supplier_order_status" label="Status" />
                <DateField source="date_created" />
                <TextField source="account.name" label="Buyer" />
                <NumberField source="grand_total" />
              </Datagrid>
            </ReferenceManyField>
          </div>

          <LabelArrWrapper source="factories" label="Factories">
            <ArrayField source="factories">
              <Datagrid bulkActionButtons={false}>
                <TextField className="flex  " source="name" />
                <TextField
                  className="flex  "
                  source="address_line_1"
                  multiline={true}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      event.stopPropagation();
                    }
                  }}
                />
                <TextField className="flex  " source="city" />
                <TextField className="flex  " source="pincode" />
                <TextField className="flex  " source="country" />
                <TextField className="flex  " source="fssai_id" />
                <FileField
                  className="flex  "
                  title="fssai_document.filename"
                  source="fssai_document.url"
                  target="_blank"
                  label="FSSAI Document"
                />
              </Datagrid>
            </ArrayField>
          </LabelArrWrapper>

          <LabelArrWrapper source="content.certifications" label="Certifications">
            <ArrayField source="content.certifications" label="Certifications">
              <Datagrid bulkActionButtons={false}>
                <TextField className="flex  " source="name" />
                <FileField className="flex  " title="filename" source="documents" src="url" target="_blank" />
              </Datagrid>
            </ArrayField>
          </LabelArrWrapper>
        </div>
      </SimpleShowLayout>
    </Show>
  );
};
