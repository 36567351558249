import { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useGetOne,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
  TextField,
  ReferenceField,
  useRedirect,
  ArrayField,
  Datagrid,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { Link, useRouter } from "react-router-dom";

const status = [
  { id: "new", name: "New" },
  { id: "expired", name: "Expired" },
  { id: "fulfilled", name: "Fulfilled" },
];

export const RequestForPriceEdit = () => {
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Request For Price",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">Edit</p>,
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  const RequestPriceComponent = () => {
    const record = useRecordContext();
    if (record) {
      return (
        <div className="w-full space-y-8 mt-4">
          <div>
            <table className="w-full order-collapse border border-slate-500 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-600">Product</th>
                  <th className="border border-slate-600">Inco Terms</th>
                  <th className="border border-slate-600">Requested Port</th>
                  <th className="border border-slate-600">FOB Rate</th>
                  <th className="border border-slate-600">FOB Rate Link</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-700">
                    {record?.supplier_product?.variant.parent.name}: {record?.supplier_product?.variant.name}
                  </td>
                  <td className="border border-slate-700">{record?.inco_terms}</td>
                  <td className="border border-slate-700">
                    <ReferenceField source="to_port_id" reference="ports" />
                  </td>
                  <td className="border border-slate-700">
                    <ArrayField source="fob_rates">
                      <Datagrid bulkActionButtons={false}>
                        <TextField source="port.name" />
                        <TextField source="rate_per_unit" />
                      </Datagrid>
                    </ArrayField>
                  </td>
                  <td className="border border-slate-700">
                    <Link
                      className="text-blue-500"
                      to="/supplier-product-pricing/create"
                      state={{
                        supplier_products_id: record?.supplier_product_id,
                      }}
                    >
                      click
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mt-4">
              <p>Available freights: </p>

              {record?.freight_fulfilled?.visible && <>{JSON.stringify(record?.freight)}</>}
            </div>
          </div>
        </div>
      );
    }
  };

  const EditComponent = () => {
    const record = useRecordContext();
    return (
      <>
        <BooleanInput source="is_supplier_fulfilled" label="Missing Price Added?" />
        <BooleanInput source="is_freight_fulfilled" label="Missing Freight Added?" />
        <RequestPriceComponent />
      </>
    );
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              let disabled = formData.is_supplier_fulfilled || formData.is_freight_fulfilled;
              return (
                <>
                  <p>
                    Buyer will be send a notification, when status is set as Fulfilled. Please ensure that missing
                    price, missing freight etc are specified, before saving status as Fulfilled.
                  </p>
                  <SelectInput
                    className="w-[100%] lg:w-[50%]"
                    source="status"
                    label="Status"
                    choices={status}
                    disabled={!disabled}
                  />
                  <EditComponent />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </div>
  );
};
