// in src/App.js
import * as React from "react";
import {
  Admin,
  Resource,
  defaultTheme,
  ListGuesser,
  ShowGuesser,
  CustomRoutes,
  Menu,
  useResourceDefinitions,
  Layout,
  useGetIdentity,
  EditGuesser,
} from "react-admin";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";
import onwoDataProvider from "./api/onwoDataProvider";
import OrderList from "./list/OrderList";
import { OrderShow } from "./show/OrderShow";
import OrderCreate from "./create/order_create";
import { SupplierCreate } from "./create/SupplierCreate";
import { SampleCreate } from "./create/SampleCreate";
import { OrderEdit } from "./edit/OrderEdit";
import { FirebaseAuthProvider } from "react-admin-firebase";
import CustomLoginPage from "./login/CustomLoginPage";
import { firebaseConfig } from "./login/firebaseConfig";
import SupplierList from "./list/SupplierList";
import BuyerList from "./list/BuyerList";
import { SupplierEdit } from "./edit/SupplierEdit";
import { SupplierShow } from "./show/SupplierShow";
import { BuyerCreate } from "./create/BuyerCreate";
import { BuyerEdit } from "./edit/BuyerEdit";
import { BuyerShow } from "./show/BuyerShow";

import { ShipmentShow } from "./show/ShipmentShow";
import { ShipmentEdit } from "./edit/ShipmentEdit";
import { ItemEdit } from "./edit/ItemEdit";
import { ItemShow } from "./show/ItemShow";
import CallbackReqList from "./list/CallbackReqList";
import { CallbackCreate } from "./create/CallbackCreate";
import SupplierProductList from "./list/SupplierProductList";
import { SupplierProductEdit } from "./edit/SupplierProductEdit";
import { SupplierProductShow } from "./show/SupplierProductShow";
import { SupplierProductCreate } from "./create/SupplierProductCreate";
import SupplierProductPricingList from "./list/SupplierProductPricingList";
import { SupplierProductPricingEdit } from "./edit/SupplierProductPricingEdit";
import { SupplierProductPricingCreate } from "./create/SupplierProductPricingCreate";
import SupplierVasList from "./list/SupplierVasList";
import { SupplierVasShow } from "./show/SupplierVasShow";
import { SupplierVasCreate } from "./create/SupplierVasCreate";
import { SupplierVasEdit } from "./edit/SupplierVasEdit";
import RequestForPrice from "./list/RequestForPrice";
import { RequestForPriceShow } from "./show/RequestForPriceShow";
import { RequestForPriceEdit } from "./edit/RequestForPriceEdit";
import RequestQuoteList from "./list/RequestQuote";
import { RequestForQuoteShow } from "./show/RequestForQuoteShow";
import { RequestForQuoteEdit } from "./edit/RequestForQuoteEdit";
import PaymentTermsList from "./list/PaymentTermsList";
import { PaymentTermsShow } from "./show/PaymentTermsShow";

import { PaymentTermsEdit } from "./edit/PaymentTermsEdit";
import Chat from "./chat";
import { Route } from "react-router-dom";
import { SupplierOrderShow } from "./show/SupplierOrderShow";
import { SupplierOrderEdit } from "./edit/SupplierOrderEdit";
import { PaymentTermsCreate } from "./create/PaymentTermsCreate";
import { ProductList } from "./list/ProductList";
import { check } from "./config_check";
import { SampleList } from "./list/SampleList";
import { SampleEdit } from "./edit/SampleEdit";
import { SampleShow } from "./show/SampleShow";
import { CallbackEdit } from "./edit/CallbackEdit";
import { PortEdit } from "./edit/PortEdit";
import { PortCreate } from "./create/PortCreate";
import { PortList } from "./list/PortList";

const authProvider = FirebaseAuthProvider(firebaseConfig);

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.RaLabeled-label": {
            fontSize: "16px",
            fontWeight: 600,
          },
        },
      },
    },
  },
};

const IconBadge = () => {
  return <ChatBubbleOutlinedIcon />;
};

const MyMenu = () => {
  return (
    <Menu>
      <Menu.ResourceItem name="suppliers" />
      <Menu.ResourceItem name="buyers" />
      <Menu.ResourceItem name="orders" />
      <Menu.ResourceItem name="supplier-products" />
      <Menu.ResourceItem name="supplier-vas-pricing" />
      <Menu.ResourceItem name="request-for-price" />
      <Menu.ResourceItem name="request-for-quote" />
      <Menu.ResourceItem name="products" />
      <Menu.ResourceItem name="ports" />
      <Menu.ResourceItem name="payment-terms" />
      <Menu.ResourceItem name="sample" />
      <Menu.ResourceItem name="callbacks" />
      <Menu.Item label="chat-support" to="/chat" primaryText="Chat Support" leftIcon={<IconBadge />} />
    </Menu>
  );
};

const CustomLayout = (props) => <Layout {...props} menu={MyMenu} />;
const name_renderer = (r) => {
  let name = ""
  if (r.name) {
    name += r.name + "\n"
  } else {
    name += r.first_name + " " + r.last_name + "\n"
  }
  if (r.email) {
    name += r.email + "\n"
  }
  if (r.phone) {
    name += r.phone + "\n"
  }
  console.log(name)
  return name
}

const App = () => {
  check();
  return (
    <Admin
      theme={theme}
      layout={CustomLayout}
      loginPage={CustomLoginPage}
      dataProvider={onwoDataProvider}
      authProvider={authProvider}
    >
      <Resource
        name="orders"
        recordRepresentation="number"
        list={OrderList}
        show={OrderShow}
        create={OrderCreate}
        edit={OrderEdit}
      />

      <Resource
        name="suppliers/orders"
        recordRepresentation="number"
        show={SupplierOrderShow}
        edit={SupplierOrderEdit}
      />

      <Resource
        name="suppliers"
        recordRepresentation={(r) =>  r.name && r.name != "" ? r.name : (r.billing?.company || r.billing?.first_name + " " + r.billing?.last_name) }
        list={SupplierList}
        create={SupplierCreate}
        edit={SupplierEdit}
        show={SupplierShow}
      />

      <Resource
        name="sample"
        recordRepresentation="id"
        list={SampleList}
        create={SampleCreate}
        edit={SampleEdit}
        show={SampleShow}
      />

      <Resource name="products" recordRepresentation="name" list={ProductList} />
      <Resource name="products/variants" recordRepresentation={(r) => `${r.parent?.name} / ${r.name}`} />

      
      <Resource
        name="buyers"
        recordRepresentation={name_renderer}
        list={BuyerList}
        create={BuyerCreate}
        edit={BuyerEdit}
        show={BuyerShow}
      />
      <Resource
        name="supplier-product-pricing"
        recordRepresentation={(r) => `${r.supplier_products?.variant?.parent?.name}-${r.supplier_products?.variant?.name} FOB:${r.inco_terms}`}
        list={SupplierProductPricingList}
        edit={SupplierProductPricingEdit}
        create={SupplierProductPricingCreate}
        // show={SupplierProductShow}
      />
      <Resource
        name="supplier-products"
        recordRepresentation="name"
        list={SupplierProductList}
        create={SupplierProductCreate}
        edit={SupplierProductEdit}
        show={SupplierProductShow}
        options={{ label: "Supplier Products" }}
      />
      <Resource
        name="supplier-vas-pricing"
        recordRepresentation="name"
        list={SupplierVasList}
        create={SupplierVasCreate}
        edit={SupplierVasEdit}
        show={SupplierVasShow}
        options={{ label: "Supplier VAS pricing" }}
      />

      <Resource name="ports" recordRepresentation={(record) => `${record.name}-${record.country}`}
        list={PortList}
        create={PortCreate}
        edit={PortEdit}
      />

      <Resource name="items" edit={ItemEdit} show={ItemShow} />

      <Resource
        name="request-for-price"
        recordRepresentation="name"
        list={RequestForPrice}
        edit={RequestForPriceEdit}
        show={RequestForPriceShow}
        options={{ label: "Request For Price" }}
      />

      <Resource
        name="request-for-quote"
        recordRepresentation="name"
        list={RequestQuoteList}
        show={RequestForQuoteShow}
        edit={RequestForQuoteEdit}
        options={{ label: "Request Quote" }}
      />

      <Resource
        name="payment-terms"
        recordRepresentation="name"
        list={PaymentTermsList}
        show={PaymentTermsShow}
        edit={PaymentTermsEdit}
        create={PaymentTermsCreate}
        options={{ label: "Payment Terms" }}
      />

      <Resource
        name="callbacks"
        create={CallbackCreate}
        edit={CallbackEdit}
        list={CallbackReqList}
        options={{ label: "Callback Requests" }}
      />

      <Resource name="product-experts" recordRepresentation={(record) => `${record.name} (${record.email})`} />

      <CustomRoutes>
        <Route path="/chat" element={<Chat />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
