import { useCallback, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useRecordContext,
  useGetOne,
  useGetRecordId,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

export const SupplierVasEdit = () => {
  const recordId = useGetRecordId();
  const record = useGetOne("supplier-vas-pricing", {
    id: recordId,
  })?.data;
  const [vasEdit, setVasEdit] = useState();
  useEffect(() => {
    setVasEdit(record?.vas_type);
  }, [record?.vas_type]);
  const fields = useGetList("models/supplier-vas-pricing")?.data?.[0]?.fields;
  
  const supplier = useGetList("suppliers", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'name', order: 'ASC' }
  })?.data?.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const vasTypeOptions = fields?.vas_type?.enum?.map((item) => ({
    id: item,
    name: item,
  }));
  const packagingTypeOptions = fields?.packaging_type?.enum?.map((item) => ({
    id: item,
    name: item,
  }));
  const packagingMaterialOptions = fields?.packaging_material?.enum?.map((item) => ({
    id: item,
    name: item,
  }));
  const labellingTypeOptions = fields?.labelling_type?.enum?.map((item) => ({
    id: item,
    name: item,
  }));
  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Supplier Vas",
        type: "text",
      },
      {
        name: `${record?.supplier?.name} - ${record?.vas_type}`,
        type: "link",
      },
      {
        name: "Edit",
        type: "link",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <div className="w-full">
      <Edit mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm>
          {supplier?.length > 0 && (
            <SelectInput className="w-[100%] lg:w-[50%]" source="supplier_id" label="Supplier" choices={supplier} />
          )}
          {vasTypeOptions?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="vas_type"
              label="VAS Type"
              onChange={(e) => setVasEdit(e.target.value)}
              choices={vasTypeOptions}
            />
          )}
          {vasEdit == "packaging" && (
            <>
              {packagingTypeOptions?.length > 0 && (
                <SelectInput
                  className="w-[100%] lg:w-[50%]"
                  source="packaging_type"
                  label="Packaging Type"
                  choices={packagingTypeOptions}
                />
              )}
            </>
          )}

          {vasEdit === "labelling" && (
            <>
              {labellingTypeOptions?.length > 0 && (
                <SelectInput
                  className="w-[100%] lg:w-[50%]"
                  source="labelling_type"
                  label="Labelling Type"
                  choices={labellingTypeOptions}
                />
              )}
            </>
          )}

          {vasEdit === "material" && (
            <>
              {packagingMaterialOptions?.length > 0 && (
                <SelectInput
                  className="w-[100%] lg:w-[50%]"
                  source="packaging_material"
                  label="Packaging Material"
                  choices={packagingMaterialOptions}
                />
              )}
            </>
          )}
          <TextInput className="w-[100%] lg:w-[50%]" source="rate_per_unit" label="Rate Per Unit" />
        </SimpleForm>
      </Edit>
    </div>
  );
};
