import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BasicBreadcrumbs({ links = [] }) {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" color="#fff">
        {links?.map((ele) => {
          return (
            <div key={ele.name}>
              {ele.type === "link" ? (
                <Link underline="hover" color="#fff" href={ele.href}>
                  {ele.name}
                </Link>
              ) : (
                <> {ele?.name}</>
              )}
            </div>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
