import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  BooleanField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  DateInput,
  DateField,
  useRecordContext,
  WrapperField,
  FunctionField,
  SelectInput,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import moment from "moment";

const Filters = [
  <ReferenceInput source="supplier_id" reference="suppliers" alwaysOn>
    <SelectInput className="w-[280px]" label="By Supplier" />
  </ReferenceInput>,
];

const RequestQuoteList = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Request Quote",
        type: "text",
      },
      {
        name: "Lists",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  return (
    <div className="w-full h-full">
      <List filters={Filters} title={<BreadcrumbContext />} sort={{ field: "date_created", order: "DESC" }}>
        <Datagrid>
          <DateField
            source="date_created"
            label="Created At"
            sortable={true}
            options={{
              year: "numeric",
              month: "long",
              day: "numeric",
            }}
          />
          <ReferenceField label="Product" reference="supplier-product-pricing" source="applied_supplier_product_pricing_id" />
          <FunctionField label="Days Open" render={(r) => moment(new Date()).to(moment(r.date_created))} />
          <ReferenceField source="buyer_id" reference="buyers" label="Buyer" sortable={false} />
          <WrapperField label="Request">
            <div>
              INCO:
              <TextField source="inco_terms" label="Inco terms" sortable={false} />
            </div>
            <div>
              target:
              <TextField source="negotiation_details.target_price" label="Target price" sortable={false} />
            </div>
            <div>
              Port:
              <ReferenceField source="port_id" reference="ports" label="Port" />
            </div>
          </WrapperField>
          <TextField source="applied_supplier_product_pricing.supplier_products.supplier.name" label="Supplier"/>
          <TextField source="negotiation_details.status" label="Status" sortable={false} />
          <WrapperField label="Offer">
            <div>
              Original Price:
              <TextField source="negotiation_details.original_price" label="Original price" sortable={false} />
            </div>
            <div>
              Supplier Final Price:
              <TextField
                source="negotiation_details.supplier_final_price"
                label="Supplier Final price"
                sortable={false}
              />
            </div>
          </WrapperField>

          <EditButton label="Edit" />
        </Datagrid>
      </List>
    </div>
  );
};

export default RequestQuoteList;
