import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectInput = (props) => {
  return (
    <FormControl variant="filled" className="w-[100%] lg:w-[250px]">
      <InputLabel id="demo-simple-select-filled-label">{props.label}</InputLabel>
      <Select
        value={props.value}
        onChange={props?.onChange}
        label={props.label}
        name={props.name}
        validate={props.validate}
        disabled={props.disabled}
        className="w-[100%] min-w-[250px]"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>

        {props?.choices?.map((ele) => (
          <MenuItem value={ele.id}>{ele.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
