import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { firestore } from "../login/firebaseConfig";
import { useTime } from "../hooks/getTime";
import { updateMessage } from "./useChat";

const ChatLeft = ({ message, chatroomId }) => {
  const { timeFormatter } = useTime();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const update = (message) => {
    const payload = {
      ...message,
      buyerVisible: message.sender.type === "supplier" ? !message.buyerVisible : message.buyerVisible,
      supplierVisible: message.sender.type === "buyer" ? !message.supplierVisible : message.supplierVisible,
    };

    updateMessage(firestore, payload, `chatroom/${chatroomId}/messages/${message.id}`);
    handleClose();
  };

  const checkVisible = (ele) => {
    let visible = false;
    if (ele.sender.type === "buyer") {
      visible = ele.supplierVisible;
    } else {
      visible = ele.buyerVisible;
    }
    return visible;
  };
  return (
    <div className="flex mb-2 max-w-[80%]">
      <div className="rounded-lg bg-white p-2 space-y-2">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex items-center space-x-2">
            <div className="w-5 h-5 border flex justify-center items-center rounded-full border-gray-300 text-[10px]">
              {message.sender?.type === "buyer" ? "B" : "S"}
            </div>

            <p className="text-teal font-bold text-[10px]">{message?.sender?.name}</p>
          </div>

          <button id={id} onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <button onClick={() => update(message)} className="p-1">
              {" "}
              {`${checkVisible(message) ? "Invisible" : "Visible"} to ${
                message.sender?.type === "buyer" ? "Supplier" : "Buyer"
              }`}
            </button>
          </Popover>
        </div>

        <div>
          <p className="text-sm pt-1">{message.msg}</p>
        </div>

        <div className="flex justify-between items-center">
          {checkVisible(message) ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          )}
          <p className="text-right text-[8px] text-grey-200 mt-1">{timeFormatter(message.createdAt)}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatLeft;
