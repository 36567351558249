import {
  ArrayField,
  Datagrid,
  DateField,
  FileField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { useLocation } from "react-router-dom";

export const ItemShow = () => {
  const {
    state: { orderId },
  } = useLocation();
  return (
    <Show queryOptions={{ meta: { orderId: orderId } }}>
      <SimpleShowLayout>
        <ReferenceField className="flex font-inter" source="product_id" reference="products" />
        <NumberField className="flex font-inter" source="quantity" />
        <NumberField className="flex font-inter" source="price" />
        <ArrayField className="flex font-inter" source="actions">
          <Datagrid bulkActionButtons={false}>
            <TextField className="flex font-inter" source="name" />
            <DateField className="flex font-inter" source="date" />
            <DateField className="flex font-inter" source="start_date" />
            <DateField className="flex font-inter" source="end_date" />
            <ArrayField source="documents">
              <Datagrid bulkActionButtons={false}>
                <TextField className="flex font-inter" source="name" />
                <TextField className="flex font-inter" source="number" />
                <FileField className="flex font-inter" title="asset.filename" source="asset.url" target="_blank" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
