import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Configure FirebaseUI.
const firebaseUIConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "#/",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        hd: "onwo.trade",
      },
    },
  ],
  // Optional callbacks in order to get Access Token from Google,Facebook,... etc
  callbacks: {
    signInSuccessWithAuthResult: (result) => {
      // const credential = result.credential;
      // The signed-in user info.
      // const user = result.user;
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      // const accessToken = credential.accessToken;
      //
      return true;
    },
  },
};

let firebaseConfig;
if (!process.env.REACT_APP_FIREBASE_CONFIG) {
  throw new Error("REACT_APP_FIREBASE_CONFIG not set");
}
try {
  firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
} catch (error) {
  console.error(
    "Error parsing (maybe quotes aren't escaped?): ",
    { REACT_APP_FIREBASE_CONFIG: process.env.REACT_APP_FIREBASE_CONFIG },
    error
  );
}

const firebaseAppInstance = initializeApp(firebaseConfig);
const firebaseAuth = getAuth();
const firestore = getFirestore(firebaseAppInstance);

export { firebaseAppInstance, firebaseAuth, firebaseConfig, firebaseUIConfig, firestore };
