import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EmailField,
  FileField,
  ImageField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  ShowButton,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useRecordContext,
  useRedirect,
  ReferenceManyField,
  EditButton,
} from "react-admin";
import { LabelArrWrapper, LabelWrapper } from "../component/Wrapper";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import { useNavigate } from "react-router-dom";
export const SupplierProductShow = () => {
  const redirect = useRedirect();
  const navigate = useNavigate();
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (record == null) return
    let links = [
      {
        name: "Supplier Products",
        type: "text",
      },
      {
        name: `${record?.supplier?.name} > ${record.variant.parent.name} > (${record?.variant?.name})`,
        type: "text",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };
  const options = [
    {
      buttonName: "Actions",
      key: "",
    },
    {
      buttonName: "Create Supplier Product pricing",
      key: "createSupplierProductPricing",
    },
  ];
  const ContextCmp = () => {
    const record = useRecordContext();
    const onChangeHandler = (key) => {
      switch (key) {
        case "viewSupplierProductPricing":
          navigate({
            pathname: "/supplier-product-pricing",
            search: `filter=${JSON.stringify({
              id: record && record.id,
            })}`,
          });
          break;

        case "createSupplierProductPricing":
          redirect("create", "supplier-product-pricing", undefined, undefined, {
            supplier_products_id: record && record.id,
          });
          break;

        default:
      }
    };
    return (
      <div className="w-full flex justify-end p-4">
        <select
          onChange={(e) => onChangeHandler(e.target.value)}
          className="py-1.5 w-[50%] lg:w-[25%] focus:outline-none rounded-lg border-2 bg-blue-100 border-gray-200"
        >
          {options.map((option) => (
            <option value={option.key} className="w-[150px] lg:w-full" onChange={option.action}>
              {option.buttonName}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <>
      <Show title={<BreadcrumbContext />}>
        <ContextCmp />
        <SimpleShowLayout>
          <div className="space-y-6">
            <div className="grid grid-cols-3 gap-4">
              <LabelWrapper source="status" label="Supplier Product Status">
                <TextField className="w-[100%] lg:w-[50%]" source="status" label="Supplier Product Status" />
              </LabelWrapper>

              <LabelWrapper source="margin_in_percent" label="Margin">
                <TextField className="w-[100%] lg:w-[50%]" source="margin_in_percent" label="Margin" />
              </LabelWrapper>

              <LabelWrapper source="payment_terms_id" label="Payment Terms">
                <ReferenceField
                  className="w-[100%] lg:w-[100%]"
                  source="payment_terms_id"
                  reference="payment-terms"
                  label="Payment Terms"
                />
              </LabelWrapper>

              <LabelWrapper source="price_validity_in_days" label="Price Validity in Days">
                <TextField
                  className="w-[100%] lg:w-[50%]"
                  source="price_validity_in_days"
                  label="Price Validity in Days"
                />
              </LabelWrapper>

              <LabelWrapper source="supplier.name" label="Supplier">
                <TextField className="w-[100%] lg:w-[50%]" source="supplier.name" label="Supplier" />
              </LabelWrapper>

              <LabelWrapper source="variant_id" label="Product">
                <TextField className="w-[100%] lg:w-[50%]" source="variant.parent.name" label="Product" />
              </LabelWrapper>

              <LabelWrapper source="variant.name" label="Variant">
                <TextField className="w-[100%] lg:w-[50%]" source="variant.name" label="Variant" />
              </LabelWrapper>
            </div>
          </div>

          <div className="mt-6">
            <p className="font font-bold text-black">Supplier Product Pricing</p>
            <ReferenceManyField label="Supplier Product Pricing" reference="supplier-product-pricing" target="supplier_products_id" filter={{status:"active"}}>
              <Datagrid>
                <TextField source="inco_terms" label="INCO terms" />
                <TextField source="port.name" label="Port" />
                <TextField source="status" label="Status" />
                <TextField source="rate_per_unit" label="Rate Per Unit" />
                <DateField source="valid_from" label="Valid From" />
                <DateField source="valid_till" label="Valid Till" />
                <EditButton label="Edit" />
              </Datagrid>
            </ReferenceManyField>
          </div>

          <div className="mt-4">
            <p className="font-inter font-bold">Available Vas</p>

            <div>
              <LabelArrWrapper source="available_vas_ids" label="Material">
                <ReferenceArrayField
                  source="available_vas_ids"
                  reference="supplier-vas-pricing"
                  filter={{ vas_type: "material" }}
                  link="show"
                >
                  <Datagrid>
                    <TextField source="packaging_material" />
                    <TextField source="rate_per_unit" />
                  </Datagrid>
                </ReferenceArrayField>
              </LabelArrWrapper>

              <LabelArrWrapper source="available_vas_ids" label="Packaging">
                <ReferenceArrayField
                  source="available_vas_ids"
                  reference="supplier-vas-pricing"
                  filter={{ vas_type: "packaging" }}
                  link="show"
                >
                  <Datagrid>
                    <TextField source="packaging_type" className="w-[200]" />
                    <TextField source="rate_per_unit" />
                  </Datagrid>
                </ReferenceArrayField>
              </LabelArrWrapper>

              <LabelArrWrapper source="available_vas_ids" label="Labelling">
                <ReferenceArrayField
                  source="available_vas_ids"
                  reference="supplier-vas-pricing"
                  filter={{ vas_type: "labelling" }}
                  link="show"
                >
                  <Datagrid>
                    <TextField source="labelling_type" />
                    <TextField source="rate_per_unit" />
                  </Datagrid>
                </ReferenceArrayField>
              </LabelArrWrapper>
            </div>
          </div>

          {/* <BooleanInput
            className="w-[100%] lg:w-[50%]"
            source="status"
            label="Status"
          /> */}
        </SimpleShowLayout>
      </Show>
    </>
  );
};
