import React, { useState } from "react";
import { firestore } from "../login/firebaseConfig";
import { useGetUnreadMessage } from "./useChat";
import Popover from "@mui/material/Popover";
import ChatMessagePage from "../chat/ChatMessagePage";
import { useGetIdentity, useRefresh } from "react-admin";
import moment from "moment";

const truncate = (str, max) => {
  return str.length > max ? str.substring(0, max) + "..." : str;
};

const ChatCard = ({ chatroom }) => {
  const { data: identity, isLoading: identityLoading } = useGetIdentity();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const readable_type = (type) => {
    switch (type) {
      case "product price match": {
        return "Request for Quote";
      }
      case "product price request": {
        return "Request for Price";
      }
      default: {
        return "General Enquiry";
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("CR", chatroom)
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { unreadMessages } = useGetUnreadMessage(firestore, chatroom?.chatroomId);

  return (
    <>
      <div
        onClick={handleClick}
        className="w-full bg-white flex border border-gray-200 rounded space-x-4 py-4 px-2 cursor-pointer"
      >
        <div className="w-full">
          <div className="w-full flex items-center  mb-2">
            <div className="flex space-x-5 items-center">
              <p className="bg-blue-800 capitalize text-white text-sm rounded px-2 w-40">
                {readable_type(chatroom.chatType.replaceAll("_", " "))}
              </p>
              <span className="text-sm text-slate-600 w-6">Buyer:</span>
              <span className="text-slate-700  text-base w-40">{chatroom?.buyer?.name}</span>
              <span className="text-sm text-slate-600 w-10">Supplier:</span>
              <span className="text-slate-700  text-base w-40">{chatroom?.supplier?.name}</span>
              <span className="text-slate-700  text-base w-40">{moment(chatroom?.createdAt.toDate()).format('LLLL')}</span>
            </div>

            {unreadMessages > 0 && (
              <div className="rounded-full w-4 h-4 flex justify-center items-center text-white bg-[#028866]">
                {unreadMessages}
              </div>
            )}
          </div>

          <p className="text-black font-normal text-m">{chatroom?.product?.name}</p>

          <p className="flex">
            {chatroom?.options_type
              ?.filter((x) => x.name != "DEFAULT")
              .map((option, index) => {
                return (
                  <span
                    key={index}
                    className="flex justify-start lg:justify-center items-center text-black opacity-70 font-normal text-xs mr-2"
                  >
                    <>{truncate(`${option?.name} - ${option?.value}`, 300)}</>
                    {chatroom?.options_type?.length > 1 && index < chatroom?.options_type?.length - 1 && (
                      <span style={{ backgroundColor: "#D9D9D9" }} className="block h-2 w-2 rounded-full ml-2"></span>
                    )}
                  </span>
                );
              })}
          </p>

          <div className="flex flex-wrap">
            {chatroom?.vas_type?.map((vas, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-start lg:justify-center items-center text-black whitespace-nowrap opacity-70 font-normal text-xs mr-2"
                >
                  <>{`${vas?.name} - ${vas?.value}`}</>
                  {chatroom?.vas_type?.length > 1 && index < chatroom?.vas_type?.length - 1 && (
                    <div style={{ backgroundColor: "#D9D9D9" }} className="block h-2 w-2 rounded-full ml-2"></div>
                  )}
                </div>
              );
            })}
          </div>
          <div></div>
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          marginRight: 20,
        }}
      >
        <ChatMessagePage
          chatroomId={chatroom?.id}
          sender={{
            id: identity?.id,
            name: identity?.fullName,
          }}
        />
      </Popover>
    </>
  );
};

export default ChatCard;
