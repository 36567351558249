import { useState } from "react";
import { useGetList, useNotify, useRecordContext, useRefresh } from "react-admin";
import fetch_with_auth from "../../utils/fetch";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from "@material-ui/core";
import moment from "moment";
import { finalizeInvoice } from "./functions";
import SplitItem from "./SplitItem";
import InvoiceDocumentPopup from "./InvoiceDocumentPopup";
import CustomsInvoiceEdit from "./CustomsInvoiceEdit";

const CustomInvoiceGrid = ({ invoice, data, loadState }) => {
  const [loading, setLoading] = loadState;
  const context = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState(false);
  const lotsModel = useGetList("models/lots").data?.[0]?.fields;
  const [document, setDocument] = useState({});
  let invoiceScheduleStatus = lotsModel?.schedule?.fields?.status?.enum.map((type, index) => ({
    id: index,
    name: type,
  }));
  const invoiceDocumentModalClose = () => {
    setDialog(false);
  };
  const invoiceDocumentModalOpen = (payload) => {
    setDocument(payload);
    setDialog(true);
  };
  const [schedule, setSchedule] = useState();

  const onHandleChange = (value, name) => {
    setSchedule({ ...schedule, [name]: value });
  };

  const save = async (payload) => {
    try {
      // setLoading(true);

      const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/customs_invoices/${payload?.lot_id}/schedule/${payload?.schedule_id}`;

      const response = await fetch_with_auth(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...schedule, stage: payload.stage }),
      });
      if (response.ok) {
        setLoading(false);
        notify(`Saved successfully`, { type: "success" });
        refresh();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const [payment, setPayment] = useState(true);

  const onHandlePaymentChange = (value, name) => {
    setPayment({ ...payment, [name]: value });
  };

  const paymentSave = async (payload) => {
    if (payment?.is_received === true) {
      if (payment && !!payment?.amount_paid) {
        setLoading(true);
        let url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/customs_invoices/${payload?.invoice_id}/payments/${payload?.payment_id}`;
        try {
          let response = await fetch_with_authh_auth(url, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ ...payment }),
          });

          if (response.ok) {
            notify(`Saved successfully`, { type: "success" });
            refresh();
          } else {
            throw new Error(
              JSON.stringify({
                code: response.status,
                message: response.statusText,
              })
            );
          }
        } catch (error) {
          notify(`Backend error. ${JSON.stringify(error.message)}`, {
            type: "error",
          });
        } finally {
          setLoading(false);
        }
      } else {
        notify("Enter the amount paid", { type: "error" });
      }
    } else {
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/customs_invoices/${payload?.invoice_id}/payments/${payload?.payment_id}`;

        const response = await fetch_with_auth(url, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...payment }),
        });
        if (response.ok) {
          notify(`Saved successfully`, { type: "success" });
          refresh();
        } else {
          throw new Error(
            JSON.stringify({
              code: response.status,
              message: response.statusText,
            })
          );
        }
      } catch (error) {
        notify(`Backend error. ${JSON.stringify(error.message)}`, {
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const createCustomPayment = async (invoice_id) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_ONWO_BACKEND_URL}/suppliers/orders/${context?.id}/customs_invoices/${invoice_id}/payments`;

      const response = await fetch_with_auth(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      });
      if (response.ok) {
        setLoading(false);
        notify("Saved Successfully", { type: "success" });
        refresh();
      } else {
        throw new Error(
          JSON.stringify({
            code: response.status,
            message: response.statusText,
          })
        );
      }
    } catch (error) {
      notify(`Backend error. ${JSON.stringify(error.message)}`, {
        type: "error",
      });
      setLoading(false);
    }
  };
  return (
    <div className="space-y-3 mt-3">
      <InvoiceDocumentPopup
        documentDetails={document}
        pop={dialog}
        notify={notify}
        refresh={refresh}
        invoiceDocumentModalClose={invoiceDocumentModalClose}
        setLoading={setLoading}
      />
      <TableContainer className="w-[100%] lg:w-[50%] mb-4 p-6" component={Paper}>
        <button
          disabled={context?.customs_invoices?.find((ele) => ele?.id == invoice?.id && ele.status === "finalized")}
          onClick={() => finalizeInvoice(invoice?.id, "", notify, refresh)}
          className={` ${
            context?.customs_invoices?.find((ele) => ele?.id == invoice?.id && ele.status === "finalized")
              ? "bg-gray-300"
              : "bg-blue-400"
          }  text-white px-3 py-1 rounded`}
        >
          Finalize Custom Invoice-{invoice?.invoice_number}
        </button>
        <div className="mt-4 border border-gray-500 rounded py-2">
          <p className="font-bold text-black ml-4">Items</p>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="font-light text-xs">
                <TableCell>
                  <span className="">Name</span>
                </TableCell>
                {/* <TableCell>
                        <span className="">Custom Invoice</span>
                      </TableCell> */}
                <TableCell>
                  <span className="">Quantity</span>
                </TableCell>
                <TableCell>
                  <span className="">Price</span>
                </TableCell>
                <TableCell>
                  <span className="">Payment Terms</span>
                </TableCell>
                <TableCell>
                  <span className="">Status</span>
                </TableCell>
                <TableCell>
                  <span className="">Variant Details</span>
                </TableCell>
                <TableCell>
                  <span className="">Action</span>
                </TableCell>
              </TableRow>
            </TableHead>

            {invoice?.items?.length < 1 ? (
              <div className="p-2 text-red-500">No items in this Invoice</div>
            ) : (
              invoice?.items?.map((item, index) => {
                return (
                  <TableBody key={index}>
                    <SplitItem
                      setLoading={setLoading}
                      item_id={item?.id}
                      current_lot={invoice?.id}
                      invoiceNumber={invoice?.invoice_number}
                      openDialog={openDialog}
                      quantity={item?.quantity}
                      closDialog={() => setOpenDialog(false)}
                      lots={context?.customs_invoices?.filter((ele) => ele?.id !== invoice?.id)}
                    />

                    <TableRow
                      key={invoice?.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>
                        {item?.applied_supplier_product_pricing?.supplier_products?.variant?.parent?.name}
                      </TableCell>
                      {/* <TableCell>
                            <select
                              value={selectedInvoice?.id}
                              onChange={(e) => handleChange(e, item.id)}
                              className="w-[90px] border border-gray-300 p-1"
                            >
                              {customs_invoices?.map((ele) => {
                                return (
                                  <option selected={ele?.id === selectedInvoice?.id} key={ele?.id} value={ele?.id}>
                                    {ele?.invoice_number}
                                  </option>
                                );
                              })}
                            </select>
                          </TableCell> */}

                      <TableCell>
                        <span className="capitalize">
                          {item?.quantity}
                          {item?.applied_supplier_product_pricing?.supplier_products?.variant?.parent?.pricing_unit}
                        </span>
                      </TableCell>
                      <TableCell>
                        {item?.applied_supplier_product_pricing?.supplier_products?.variant?.currency}{" "}
                        {item?.final_price}
                      </TableCell>
                      <TableCell>{item?.final_payment_terms?.name}</TableCell>
                      <TableCell>{item?.schedule?.find((x) => x?.status === "not_started")?.status}</TableCell>

                      <TableCell>
                        <div className="">
                          {item?.applied_supplier_product_pricing?.supplier_products?.variant?.name}
                        </div>
                      </TableCell>
                      <TableCell>
                        <button
                          className="border bg-blue-500 active:bg-blue-700 rounded text-white py-1 px-2"
                          onClick={() => setOpenDialog(true)}
                        >
                          Split / Move
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            )}
          </Table>
        </div>
        <CustomsInvoiceEdit id={context?.id} lot={invoice} key={invoice?.id} />
        <div className="space-y-3 my-2">
          <div className="my-6">
            <div className="mt-4 border border-gray-500 rounded py-2">
              <div className="flex justify-between items-center px-4">
                <p className="font-bold text-black">Manage Invoice Schedule</p>
                <p>
                  <span className="text-sm">Status :</span> <span className="uppercase font-semibold">{invoice?.status}</span>
                </p>
              </div>

              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="">Stage</span>
                    </TableCell>
                    <TableCell>
                      <span className="">Started on</span>
                    </TableCell>
                    <TableCell>
                      <span className="">Completed on</span>
                    </TableCell>
                    <TableCell>
                      <span className="">Expected on</span>
                    </TableCell>
                    <TableCell>
                      <span className="">Status</span>
                    </TableCell>
                    <TableCell>
                      <span className="">Document</span>
                    </TableCell>
                    <TableCell>
                      <span className=""></span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice?.schedule?.map((item) => (
                    <TableRow
                      key={item?.id}
                      className={item?.is_internal ? "" : "bg-red-100"}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell className="capitalize">{item.stage.replaceAll("_", " ")}</TableCell>

                      <TableCell>
                        <input
                          type="date"
                          defaultValue={
                            item?.started_on !== undefined ? moment(item?.started_on).format("YYYY-MM-DD") : ""
                          }
                          //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                          onChange={(e) => onHandleChange(e.target.value, "started_on")}
                        />
                      </TableCell>

                      <TableCell>
                        <input
                          type="date"
                          defaultValue={
                            item?.completed_on !== undefined ? moment(item?.completed_on).format("YYYY-MM-DD") : ""
                          }
                          onChange={(e) => onHandleChange(e.target.value, "completed_on")}
                        />
                      </TableCell>

                      <TableCell>
                        <input
                          type="date"
                          defaultValue={
                            item?.expected_on !== undefined ? moment(item?.expected_on).format("YYYY-MM-DD") : ""
                          }
                          onChange={(e) => onHandleChange(e.target.value, "expected_on")}
                        />
                      </TableCell>

                      <TableCell>
                        <select
                          onChange={(e) => onHandleChange(e.target.value, "status")}
                          className="w-[110px] border border-gray-300 p-1"
                        >
                          {invoiceScheduleStatus?.map((ele, index) => (
                            <option selected={ele.name == item?.status} key={index} value={ele.name}>
                              {ele?.name}
                            </option>
                          ))}
                        </select>
                      </TableCell>

                      <TableCell>
                        <button
                          className="text-blue-500 w-full"
                          onClick={() =>
                            invoiceDocumentModalOpen({
                              lot_id: invoice?.id,
                              schedule_id: item?.id,
                            })
                          }
                        >
                          Add
                        </button>
                      </TableCell>

                      <TableCell>
                        <button
                          className="border bg-blue-500 active:bg-blue-700 rounded text-white py-1 px-2"
                          onClick={() => {
                            save({
                              lot_id: invoice?.id,
                              schedule_id: item?.id,
                              stage: item?.stage,
                            });
                          }}
                        >
                          Save
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {invoice?.payment_schedule?.length > 0 && (
              <div className="mt-4 border border-gray-500 rounded py-2">
                <div className="flex justify-between px-4">
                  <p className="font-bold text-black ">Payment Schedule</p>
                  <button
                    className={`${
                      invoice.is_supplier_order_accepted ? "bg-gray-300" : "bg-blue-500 active:bg-blue-700"
                    } border  rounded text-white py-1 px-2`}
                    disabled={invoice.is_supplier_order_accepted}
                    onClick={() => createCustomPayment(invoice?.id)}
                  >
                    Create Payment
                  </button>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className="">Amount Due</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Due by</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Is Due</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Is Customer Paid</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Date Paid</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Is Received</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Amount Received</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Date Received</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Receipt</span>
                      </TableCell>
                      <TableCell>
                        <span className="">Evidence</span>
                      </TableCell>
                      <TableCell>
                        <span className=""></span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {invoice?.payment_schedule?.map((schedule, index) => {
                    return (
                      <TableBody key={index}>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>
                            <input
                              type="number"
                              defaultValue={schedule?.amount}
                              className="focus:outline-none border border-gray-200 p-1 w-[100px]"
                              onChange={(e) => onHandlePaymentChange(e.target.value, "amount")}
                            />
                          </TableCell>

                          <TableCell>
                            <input
                              type="date"
                              defaultValue={
                                schedule?.due_by !== undefined ? moment(schedule?.due_by).format("YYYY-MM-DD") : ""
                              }
                              //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                              onChange={(e) => onHandlePaymentChange(e.target.value, "due_by")}
                            />
                          </TableCell>

                          <TableCell>
                            <Checkbox
                              {...label}
                              defaultChecked={schedule?.is_payable}
                              onChange={(e) => onHandlePaymentChange(e.target.checked, "is_payable")}
                            />
                          </TableCell>

                          <TableCell>
                            <Checkbox
                              {...label}
                              defaultChecked={schedule?.is_customer_paid}
                              onChange={(e) => onHandlePaymentChange(e.target.checked, "is_customer_paid")}
                            />
                          </TableCell>

                          <TableCell>
                            <input
                              type="date"
                              defaultValue={
                                schedule?.payment_date !== undefined
                                  ? moment(schedule?.payment_date).format("YYYY-MM-DD")
                                  : ""
                              }
                              //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                              onChange={(e) => onHandlePaymentChange(e.target.value, "payment_date")}
                            />
                          </TableCell>

                          <TableCell>
                            <Checkbox
                              {...label}
                              defaultChecked={schedule?.is_received}
                              onChange={(e) => onHandlePaymentChange(e.target.checked, "is_received")}
                            />
                          </TableCell>

                          <TableCell>
                            <input
                              type="number"
                              disabled={!schedule?.is_received}
                              defaultValue={schedule?.amount_paid}
                              className="focus:outline-none border border-gray-200 p-1 w-[100px]"
                              onChange={(e) => onHandlePaymentChange(e.target.value, "amount_paid")}
                            />
                          </TableCell>

                          <TableCell>
                            <input
                              type="date"
                              defaultValue={
                                schedule?.customer_payment_date !== undefined
                                  ? moment(schedule?.customer_payment_date).format("YYYY-MM-DD")
                                  : ""
                              }
                              //value={moment(schedule?.started_on).format("YYYY-MM-DD")}
                              onChange={(e) => onHandlePaymentChange(e.target.value, "customer_payment_date")}
                            />
                          </TableCell>

                          <TableCell>
                            <button
                              className="text-blue-500 w-full"
                              onClick={() =>
                                lotDocumentModalOpen({
                                  payment_id: schedule?.id,
                                  invoice_id: invoice?.id,
                                  name: "receipt",
                                })
                              }
                            >
                              Add
                            </button>
                          </TableCell>

                          <TableCell>
                            <button
                              className="text-blue-500 w-full"
                              onClick={() =>
                                lotDocumentModalOpen({
                                  payment_id: schedule?.id,
                                  invoice_id: invoice?.id,
                                  name: "transaction_evidence",
                                })
                              }
                            >
                              Add
                            </button>
                          </TableCell>

                          <TableCell>
                            <button
                              className="border bg-blue-500 active:bg-blue-700 rounded text-white py-1 px-2"
                              onClick={() =>
                                paymentSave({
                                  payment_id: schedule?.id,
                                  invoice_id: invoice?.id,
                                })
                              }
                            >
                              Save
                            </button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </div>
            )}
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default CustomInvoiceGrid;
