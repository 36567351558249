import { Show, SimpleShowLayout, TextField, useRecordContext } from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
export const SupplierVasShow = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    
    let links = [
      {
        name: "Supplier Vas Pricing",
        type: "text",
      },
      {
        name: `${record.supplier.name} - ${record.vas_type}`,
        type: "link",
      },
      {
        name: "Show",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  return (
    <>
      <Show title={<BreadcrumbContext />}>
        <SimpleShowLayout>
          <TextField className="w-[100%] lg:w-[50%]" source="supplier.name" label="Supplier" />
          <TextField className="w-[100%] lg:w-[50%]" source="vas_type" label="VAS Type" />
          <TextField className="w-[100%] lg:w-[50%]" source="packaging_type" label="Packaging Type" />
          <TextField className="w-[100%] lg:w-[50%]" source="packaging_material" label="Packaging Material" />
          <TextField className="w-[100%] lg:w-[50%]" source="labelling_type" label="Labelling Type" />
          <TextField className="w-[100%] lg:w-[50%]" source="rate_per_unit" label="Rate Per Unit" />

          {/* <BooleanInput
              className="w-[100%] lg:w-[50%]"
              source="status"
              label="Status"
            /> */}
        </SimpleShowLayout>
      </Show>
    </>
  );
};
