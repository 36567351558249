import React, { useState } from "react";
import RowField from "./RowField";

const DynamicFields = ({ formData, buyer_id }) => {
  const [formValues, setFormValues] = formData;

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        supplier: "",
        freight_rate: "",
        margin: "",
        product: "",
        variant: "",
        packaging: "",
        material: "",
        labelling: "",
        inco_terms: "",
        bag_item_id: "",
        price: "",
        total_price: "",
        qty: "",
        unit: "",
        currency: "",
      },
    ]);
  };

  return (
    <div className="w-full space-y-4">
      {formValues.map((element, index) => (
        <RowField element={{ ...element, buyer_id: buyer_id }} index={index} formData={formData} />
      ))}

      <div className="w-full flex justify-start items-center mt-4">
        <button className="bg-blue-500 text-white rounded px-4 py-1" type="button" onClick={() => addFormFields()}>
          Add one more item
        </button>
      </div>
    </div>
  );
};

export default DynamicFields;
