import React from "react";
import { Login } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseAuth, firebaseUIConfig } from "./firebaseConfig";

const SignInScreen = () => <StyledFirebaseAuth firebaseAuth={firebaseAuth} uiConfig={firebaseUIConfig} />;

const CustomLoginForm = (props) => (
  <div>
    <SignInScreen />
  </div>
);

const CustomLoginPage = (props) => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
);

export default CustomLoginPage;
