import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  ShowButton,
  EditButton,
  BooleanField,
  ReferenceField,
  TextInput,
  FunctionField,
  ReferenceInput,
  DateInput,
  DateField,
  useRecordContext,
} from "react-admin";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";

const filters = [
  <ReferenceInput source="buyer_id" reference="buyers" alwaysOn>
    <SelectInput className="w-[280px]" label="Buyer Search" />
  </ReferenceInput>,
  <ReferenceInput source="supplier_id" reference="suppliers" alwaysOn>
    <SelectInput className="w-[280px]" label="Supplier Search" />
  </ReferenceInput>,
];

const RequestForPrice = () => {
  const BreadcrumbContext = () => {
    const record = useRecordContext();
    if (!record) return null;
    let links = [
      {
        name: "Request For Price",
        type: "text",
      },
      {
        name: "All",
        type: "text",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  const renderer = (r) => {
    const as_name = (variant) => {return variant?.parent?.name + " : " + variant?.name}
    if (r.variant) {
      return as_name(r.variant)
    }
    if (r.supplier_product) {
      return as_name(r.supplier_product?.variant)
    } 
  }
  

  return (
    <div className="w-full h-full">
      <List filters={filters} title={<BreadcrumbContext />}>
        <Datagrid>
          <DateField source="date_created" showTime />
          <ReferenceField source="buyer_id" reference="buyers" label="Buyer" sortable={false} />
          <FunctionField render={renderer} label="Product" />
          <TextField source="inco_terms" label="INCO" />
          <TextField source="status" label="Status" />
          <BooleanField source="need_category_price_list" label="Needs Price list?" />
          <BooleanField source="is_supplier_fulfilled" label="Price Entered?" />
          <FunctionField
            label="Freight Entered?"
            render={(record) => {
              if (record.inco_terms == "FOB") {
                return "NA";
              }
              return record.is_freight_fulfilled ? "Yes" : "No";
            }}
          />

          <EditButton label="Edit" />
        </Datagrid>
      </List>
    </div>
  );
};

export default RequestForPrice;
