import {
  Create,
  SimpleForm,
  TextInput,
  useGetList,
  SelectInput,
  useRecordContext,
  DateInput,
  SimpleFormIterator,
  ReferenceInput,
  FormDataConsumer,
  ArrayInput,
  SelectArrayInput,
  Button,
  NumberInput,
  required,
  useRedirect,
  useNotify,
  useCreate,
} from "react-admin";
import { useState, useCallback } from "react";
import BasicBreadcrumbs from "../component/BasicBreadcrumbs";
import fetch_with_auth from "../utils/fetch";

export const SupplierProductCreate = () => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const suppStatus = useGetList("models/supplier-products")?.data?.[0]?.fields?.status?.enum?.map((item) => ({
    id: item,
    name: item,
  }));

  const supplier = useGetList("suppliers", {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'name', order: 'ASC' }
  })?.data?.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const onItemChangeHandler = function (value) {
    if (value) {
      fetch_with_auth(`${process.env.REACT_APP_ONWO_BACKEND_URL}/products/${value}?limit=1000`)
        .then((res) => res.json())
        .then((json) => {
          setOptionChoice([
            ...optionChoice,
            {
              id: value,
              options: json?.data?.variants?.results?.map((item) => ({
                id: item?.id,
                name: item?.name,
              })),
            },
          ]);
        });
      setItemSelected(value);
    }
  };
  const [optionChoice, setOptionChoice] = useState([]);
  const [itemSelected, setItemSelected] = useState("");

  const BreadcrumbContext = () => {
    let links = [
      {
        name: "Supplier Products",
        type: "text",
      },
      {
        name: <p className="line-clamp-1 w-[200px] lg:w-[100%]">new</p>,
        type: "link",
      },
    ];
    return <BasicBreadcrumbs links={links} />;
  };

  const getOptionsArray = (id) => {
    if (id === "") return [];
    return optionChoice.find((ele) => ele?.id === id)?.options;
  };

  const submit = useCallback(
    async (values) => {
      const payload = {
        ...values,
        days_commitment: [
          {
            days_to_production: values.days_to_production,
            days_to_packaging: values.days_to_packaging,
            days_to_reach_port: values.days_to_reach_port,
            slack_days: values.slack_days,
          },
        ],
      };

      delete payload.days_to_production;
      delete payload.days_to_packaging;
      delete payload.days_to_reach_port;
      delete payload.slack_days;

      try {
        await create("supplier-products", { data: payload }, { returnPromise: true });

        notify("ra.notification.created", {
          type: "info",
          messageArgs: { smart_count: 1 },
        });
        redirect("list", "supplier-products");
      } catch ({ message }) {
        if (message) {
          return message;
        }
      }
    },
    [create, notify, redirect]
  );

  const SupplierVasMultiSelect = ({ supplier_id }) => {
    const getName = (item) => {
      if (item.vas_type === "packaging") return "Packaging: " + item.packaging_type;
      if (item.vas_type === "labelling") return "Labelling: " + item.labelling_type;
      if (item.vas_type === "material") return "Material: " + item.packaging_material;
    };
    const vas = useGetList(`supplier-vas-pricing`, {
      filter: { supplier_id: supplier_id },
    })?.data?.map((item) => ({ id: item.id, name: getName(item) }));

    return <SelectArrayInput source="available_vas_ids" choices={vas} />;
  };

  return (
    <div className="w-full">
      <Create mutationMode="pessimistic" title={<BreadcrumbContext />}>
        <SimpleForm onSubmit={submit}>
          {suppStatus?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="status"
              label="Supplier Product Status"
              validate={[required()]}
              defaultValue={"active"}
              choices={suppStatus}
            />
          )}
          {supplier?.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="supplier_id"
              label="Supplier"
              validate={required()}
              choices={supplier}
            />
          )}

          <ReferenceInput
            source="variant.parent_id"
            reference="products"
            perPage={100}
            validate={required()}
            label="product"
          >
            <SelectInput label="product" onChange={(e) => onItemChangeHandler(e.target.value)} />
          </ReferenceInput>

          {itemSelected.length > 0 && optionChoice.length > 0 && (
            <SelectInput
              className="w-[100%] lg:w-[50%]"
              source="variant_id"
              label="Variant"
              validate={required()}
              choices={getOptionsArray(itemSelected)}
            />
          )}

          <ReferenceInput
            source="payment_terms"
            reference="payment-terms"
            perPage={1000}
            validate={[required()]}
            label="Payment Terms"
          >
            <SelectInput className="w-[100%] lg:w-[50%]" />
          </ReferenceInput>

          <p>Days commitment</p>
          <div className="flex space-x-6 mb-5">
            <NumberInput source="days_to_production" helperText={false} required />
            <NumberInput source="days_to_packaging" helperText={false} required />
            <NumberInput source="days_to_reach_port" helperText={false} required />
            <NumberInput source="slack_days" helperText={false} required />
          </div>

          <NumberInput
            className="w-[100%] lg:w-[50%]"
            source="margin_in_percent"
            label="Margin in Percent"
            validate={[required()]}
          />
          <NumberInput
            className="w-[100%] lg:w-[50%]"
            source="price_validity_in_days"
            label="Price Validity in Days"
            validate={[required()]}
          />

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              let supplier_id = formData.supplier_id;
              return <>{formData.supplier_id && <SupplierVasMultiSelect supplier_id={supplier_id} />}</>;
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};
