function fetch_with_auth(url, options) {
  const defaultHeaders = {
    user: process.env.REACT_APP_USER || "",
    "user-access": `${process.env.REACT_APP_PASSWORD}` || "",
  };

  const headers = {
    ...defaultHeaders,
    ...options?.headers,
  };
  const updatedOptions = {
    ...options,
    headers,
  };

  return fetch(url, updatedOptions);
}

export default fetch_with_auth;
